import { Bars } from "react-loader-spinner";
function Loader() {
    return (
        < div className="d-flex flex-row align-items-center">
            <div className="bars-loading-loader mx-auto">
                <Bars
                    height="60"
                    width="60"
                    color="#0052FF"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    visible={true}
                />
            </div>
        </div>
    )
};
export default Loader;
import React, { useEffect, useState } from "react";
import { showNumber } from "../../core/helper/date-format";

const EoptionTradingNewAllTable = (props) => {
  const [allSocketData, setallSocketData] = useState([]);
  const [callData, setCallData] = useState([]);
  const [putData, setPutData] = useState([]);

  useEffect(() => {
    if (props.allSocketDatas) {
      setallSocketData(props.allSocketDatas);
    }
  }, [props.allSocketDatas]);

  useEffect(() => {
    const callSyb = [];
    const putSyb = [];

    allSocketData.forEach((data) => {
      const call = data.callData;
      const put = data.putsData;
      if (Array.isArray(call) && Array.isArray(put)) {
        callSyb.push(...call);
        putSyb.push(...put);
      }
    });

    if (callSyb.length > 0 && putSyb.length > 0) {
      setCallData(callSyb);
      setPutData(putSyb);
    }
  }, [allSocketData]);

  function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
      return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
  }
  return (
    <div>
      <div className="new-after-table-2-eoption">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Symbol</th>
              <th scope="col">Last Price</th>
              <th scope="col">24h%</th>
              <th scope="col">Vol</th>
            </tr>
          </thead>
          <tbody>
            {callData &&
            putData &&
            callData.length > 0 &&
            putData.length > 0 ? (
              <>
                {callData.map((callDatas, index) => {
                  const putDatas = putData[index];
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <div className="d-flex flex-column text-start">
                            <span className="">
                              {callDatas.optionalTradeSymbol}
                            </span>
                            <span className="new-eption-text-2-2">13.83x</span>
                          </div>
                        </td>
                        <td>{callDatas.ltsPrice}</td>
                        <td>
                          <span style={{ color: "red" }}>
                            {decimalValue(
                              callDatas.priceChangePercent * 100,
                              callDatas.decimalValue
                            )}
                            %
                          </span>
                        </td>
                        <td>{callDatas.tradeVolContracts}</td>
                      </tr>
                      <tr key={index}>
                        <td>
                          <div className="d-flex flex-column text-start">
                            <span className="">
                              {putDatas.optionalTradeSymbol}
                            </span>
                            <span className="new-eption-text-2-2">13.83x</span>
                          </div>
                        </td>
                        <td>{putDatas.ltsPrice}</td>
                        <td>
                          <span style={{ color: "red" }}>
                            {decimalValue(
                              putDatas.priceChangePercent * 100,
                              putDatas.decimalValue
                            )}
                            %
                          </span>
                        </td>
                        <td>{putDatas.tradeVolContracts}</td>
                      </tr>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EoptionTradingNewAllTable;

import React, { useEffect, useState } from "react";

import "../../assets/style.css";
import "../../assets/style-eoption.css";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import Eoptionleft from "./eoption-home-left";
import Eoptionright from "./eoption-home-right";
import socketIOClient from "socket.io-client"; // socket imported
import Config from "../../core/config";
import { FindOrderBookSymbol } from "./eoption-home-left";
import { useContextData } from "../../core/context";
import { useParams } from "react-router-dom";
import { dateFormat } from "./seperate/option-helpers";
import moment from "moment";

// console.log("=-=-=-=-=-=-", findOrderBookSymbol);

export default function Eoption(props) {
  const { myProfile } = useContextData();
  let { pairName = "" } = useParams();
  //==============Socket Io====
  const [socketConnection, setSocketConnection] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [coinDatas, setCoinDatas] = useState([]);
  const [orderBookDatas, setOrderBookDatas] = useState({});
  const [bvolIndexDatas, setBvolIndexDatas] = useState({});
  const [todayOptionSocketData, setTodayOptionSocketData] = useState([]);
  const [tommorowOptionSocketData, setTommorowOptionSocketData] = useState([]);
  const [theNextDayOptionSocketData, setTheNextDayOptionSocketData] = useState([]);
  // const [isEoptionrightClicked , setIsEoptionrightClicked] = useState(false);

  // const handleClick = ()=>{
  //     setIsEoptionrightClicked(!isEoptionrightClicked)
  // }

  async function initSocketCall() {
    let socket = socketIOClient(Config.OPTIONAL_TRADING_SOCKET_URL, {
      transports: ["websocket"],
    });
    let socketUnsubscribe;
    if (socket) {
      socket.on("connect", () => {
        console.log("socket connection");
        setSocketConnection(socket);
        socketUnsubscribe = socket;
      });

      socket.on("connect_error", (err) => {
        console.log("socket connect_error", err);
      });
      socket.on("disconnect", function () {
        console.log("socket disconnected");
      });
    }
    return () => {
      if (socketUnsubscribe) {
        socketUnsubscribe.disconnect();
      }
    };
  }
  async function getPairDetails() {
    try {
    } catch (error) {
      console.log("err", error);
    }
  }
  async function initUseEffect() {
    getPairDetails(); //** fetch the optional pair details */
    initSocketCall(); //** initialize socket connection */
  }

  useEffect(() => {
    //** initalize using this useEffect functions */
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") initUseEffect();
  }, []);

  useEffect(() => {
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
      if (pairName != undefined || pairName != "") {
        // if (Object.keys(pairDetails).length > 0) {
        //   getPairDetails();
        // }
      }
    }
  }, [pairName]);

  const tradeSocket = async () => {
    const toDay = moment().format("YYMMDD");
    const tommorow = moment().add(1, "days").format("YYMMDD");
    const nextDay = moment().add(2, "days").format("YYMMDD");

    socketConnection.on("OptionalTradePairResp", (socketDatas) => {
      console.log("=========>socketDatas<======",socketDatas);
      const streamDate = socketDatas.stream.split("@")[2];
      if(socketDatas && socketDatas.data) {
        if (streamDate === toDay) setTodayOptionSocketData(socketDatas.data);
        if (streamDate === tommorow) setTommorowOptionSocketData(socketDatas.data);
        if (streamDate === nextDay) setTheNextDayOptionSocketData(socketDatas.data);
      }

    });
  };
  useEffect(() => {
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
      if (socketConnection != null) {
        tradeSocket();
        if (typeof userId == "string" && userId) {
          getPairDetails();
        }
      }
    }
  }, [socketConnection]);

  useEffect(() => {
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
      if (myProfile && myProfile._id) {
        setUserId(myProfile._id);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
      if (typeof userId == "string" && userId) {
        getPairDetails();
      }
    }
  }, [userId]);

  // const orderBookSymbol = async (symbol) => {
  //   socketConnection.emit("orderBookSymbol", symbol);
  // };

  // useEffect(() => {
  //   if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
  //     if (socketConnection != null) {
  //       orderBookSymbol();
  //     }
  //   }
  // }, [socketConnection]);

  // const orderBookData = async () => {
  //   socketConnection.on("orderBookDatas", (orderBookDatas) => {
  //     console.log("orderBookDatas====>", orderBookDatas);
  //     if (orderBookDatas) {
  //       setOrderBookDatas(orderBookDatas);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
  //     if (socketConnection != null) {
  //       orderBookData();
  //     }
  //   }
  // }, [socketConnection]);

  const bvolIndex = async () => {
    socketConnection.on("klineDatas", (bvolDatas) => {
      if (bvolDatas.symbol === "iBTCBVOLUSDT") {
        setBvolIndexDatas(bvolDatas);
      }
    });
  };
  useEffect(() => {
    if (Config.OPTIONAL_TRADING_STATUS == "Enable") {
      if (socketConnection != null) {
        bvolIndex();
        if (typeof userId == "string" && userId) {
          getPairDetails();
        }
      }
    }
  }, [socketConnection]);

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="deposit-page-top-banner trade-active-nav-class">
        <div className="eoption-total-home-section">
          <div className="container-fluid d-flex flex-lg-row flex-column p-0">
            <div className="eoption-left-side-section">
              <Eoptionleft
                todayOptionSocketData={todayOptionSocketData}
                tommorowOptionSocketData={tommorowOptionSocketData}
                theNextDayOptionSocketData={theNextDayOptionSocketData}
                bvolIndexDatas={bvolIndexDatas}
                // findOrderBookSymbols={orderBookSymbol}
              />
            </div>
            {/* <div className="side-button-container">
                            <button onClick={handleClick} className="side-button">
                                click
                            </button>
                        </div> */}
            {/* {isEoptionrightClicked && (
                            <div id="eoption-right-side-section-id" className="eoption-right-side-section">
                                <Eoptionright />
                            </div>
                        )} */}

            <div
              id="eoption-right-side-section-id"
              className="eoption-right-side-section"
            >
              <Eoptionright coinDatas={coinDatas} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useNavigate
} from "react-router-dom";
import { useContextData } from '../core/context/index';
import { dateFormat, dateAddFormat, showEmail } from '../core/helper/date-format';
import { getVoucher_API, claimVoucher_API } from "../core/services/all.api";
import ReactTooltip from "react-tooltip";
import { toast } from "../core/lib/toastAlert";
import { FaInfoCircle } from "react-icons/fa";
import notFound from '../assets/images/file.png'
import DataTable from 'react-data-table-component';

export default function Voucher() {
  const { myProfile, setUserProfile, siteSettings } = useContextData();
  const navigate = useNavigate();

  const [voucherData, setVoucherData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState({});
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  const loadingChange = (data = {}) => {
    const dataCopy = { ...isLoadingData };
    dataCopy[data.key] = data.value;
    setIsLoadingData(dataCopy);
  };

  const getVoucherCall = async () => {
    loadingChange({ key: "getVoucher_API", value: true });

    const data = {
      payload: {
        page: currentPage,
        limit: recordsPerPage
      },
    };

    const resp = await getVoucher_API(data);
    loadingChange({ key: "getVoucher_API", value: false });
    if (resp.status === true) {
      setVoucherData(resp.list);
      setTotalRows(resp.totalCount);
    } else {
      setVoucherData([]);
      setTotalRows(0);
    }
  };

  const claimVoucherCall = async (data = {}) => {
    if (myProfile) {
      if (myProfile.kycstatus !== 1) {
        toast({ type: "error", message: "Please complete your KYC to continue" });
      } else if (data && data._id) {
        loadingChange({ key: "claimVoucher_API", value: data._id });
        const apiData = {
          payload: data
        };
        const resp = await claimVoucher_API(apiData);
        loadingChange({ key: "claimVoucher_API", value: "" });
        if (resp.status === true) {
          getVoucherCall();
        }
      }
    }
  };

  useEffect(() => {
    getVoucherCall();
  }, [currentPage, recordsPerPage]);

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => ((currentPage - 1) * recordsPerPage) + index + 1,
      width: "70px",
    },
    {
      name: "Date",
      selector: row => dateFormat(row.givenDate),
      width: "220px",
      sortable: true,
    },
    {
      name: "Expires on",
      selector: row => dateAddFormat(row.givenDate, {
        addCount: row.expirePeriod,
        addType: row.expirePeriodType
      }),
      width: "220px",
      sortable: true,
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
    },  
    {
      name: "Sbl/Ntw",
      selector: row => `${row.currencyId.currencySymbol} / ${row.currencyId.basecoin}`,
    },
    {
      name: "Amount",
      selector: row => `${row.currencyId.currencySymbolCode} ${row.amount}`,
      sortable: true,
    },
    {
      name: "Balance",
      selector: row => `${row.currencyId.currencySymbolCode} ${row.balance}`,
      sortable: true,
    },
    {
      name: (
        <>
          Claim&nbsp;
          <span data-tip data-for={"tooltip-claim"} className="cursor-poniter"><FaInfoCircle /></span>
          <ReactTooltip
            id="tooltip-claim"
            place="top"
            effect="solid"
            className="tooltip-text-Theme text-center"
          >
            <span className="tooltip-text-1">Once a voucher is claimed, fees are deducted from it; If not, Fees are deducted from the Wallet balance.
            </span>
          </ReactTooltip>
        </>
      ),
      cell: row => (
        row.claim === 2 || new Date() > new Date(row.expireDate)
          ? "Expired"
          : row.claim === 0
            ? <button
              type="button"
              className="unset btnfont mb-2"
              disabled={(isLoadingData && isLoadingData.claimVoucher_API && isLoadingData.claimVoucher_API !== "")}
              onClick={() => claimVoucherCall({ _id: row._id })}
            >
              {(isLoadingData && isLoadingData.claimVoucher_API === row._id) ? "Loading..." : "Claim"}
            </button>
            : "Claimed"
      ),
    }
  ];

  return (
    <div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 deposit-fourth-section ">
            <h3 className="head-profile mt-5">Voucher&nbsp;<span data-tip data-for={"tooltip-commision"} className="cursor-poniter" style={{fontSize:"20px"}}><FaInfoCircle /></span></h3>
            <ReactTooltip
              id="tooltip-commision"
              place="right"
              effect="solid"
              className="tooltip-text-Theme text-center"
            >
              <span className="tooltip-text-1">Referral commission won't be received from the Referred users, if the Referee has a voucher bonus.
              </span>
            </ReactTooltip>
            <div className="dashboard-spot-trade-voucher-section-scroll">
              <DataTable
                columns={columns}
                data={voucherData}
                persistTableHead
                pagination
                paginationServer
                paginationPerPage={recordsPerPage}
                paginationRowsPerPageOptions={[5, 10, 15]}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={newPerPage => {
                  setRecordsPerPage(newPerPage);
                  setCurrentPage(1); // Reset to first page when rows per page changes
                }}
                onChangePage={page => setCurrentPage(page)}
                noDataComponent={
                  <div className="text-center py-2">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }}/>
                    <br />
                    <span className="text-center">No Records Found</span>
                  </div>
                }
              />
              <ReactTooltip
                id="tooltip-voucher"
                place="top"
                effect="solid"
                className="tooltip-text-Theme text-center"
              >
                <span className="tooltip-text-1">
                  Once a voucher is claimed, fees are deducted from it. If not, fees are deducted from the Wallet balance.
                </span>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

import Config from "../core/config/";
import { toast } from "../core/lib/toastAlert";
import { makeRequest } from "../core/services/v1/request";

import { dateFormat, showEmail, showPhone } from '../core/helper/date-format';

import KycStatusComp from "./separate/kycStatusComp";
import { FaWhatsapp, FaTwitter, FaTelegramPlane, FaFacebookF } from 'react-icons/fa';
import { } from "react-icons/fa";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab';
import DataTable from 'react-data-table-component';
import { FaXTwitter } from "react-icons/fa6";
import notFound from '../assets/images/file.png';
import Loader from "../core/helper/Loader";


export default function ReferralIn(props) {

  const [referralData, setreferralData] = useState({});
  const [referId, setreferId] = useState('');
  const [referCode, setReferCode] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterRefer, setFilterRefer] = useState('');
  const [referPercentage, setReferPercentage] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentPageCommission, setCurrentPageCommission] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPageRefer, setCurrentPageRefer] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (props.myProfile && props.myProfile.referCommission) {
      setReferPercentage(props.myProfile.referCommission);
    }
  }, [props.type]);

  useEffect(() => {
    getReferral();
  }, [currentPage, rowsPerPage])

  async function getReferral() {
    setLoading(true);
    const params = {
      url: `${Config.V1_API_URL}user/getReferralData?userType=${props.type}&page=${currentPage}&limit=${rowsPerPage}`,
      method: 'GET'
    };
    try {
      const response = await makeRequest(params);
      setLoading(false);
      if (response.status) {

        if (response.data && response.data.profile?._id) {
          const profileResp = response.data.profile;
          if (props.type === "user") {
            setreferId(window.location.origin + '/register?refer=' + profileResp._id);
            setReferCode(profileResp._id);
            setReferPercentage((response.data && response.data.referPercentage) ? response.data.referPercentage : 0);
            setCurrentPageCommission(response.data.userCount);
            setTotalCount(response.data.userCount);
          } else if (props.type === "promoter") {
            setreferId(window.location.origin + '/register?refer=' + profileResp.referCode);
            setReferCode(profileResp.referCode);
            setReferPercentage((props.myProfile && props.myProfile.referCommission) ? props.myProfile.referCommission : 0);
            setCurrentPageCommission(response.data.promoterCount);
            setTotalCount(response.data.promoterCount);
          };
          setreferralData(prevData => ({
            ...prevData,
            commissionHistory: response.data.commissionHistory,
            referUsers: response.data.referUsers,
            totalReferUsers: response.data.totalReferUsers
          }));
        };
      };
    } catch (error) {
      console.error("Error fetching referral data:", error);
      setLoading(false);
    };
  };

  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if (data.type == "referCode") {
      message = "Referral code copied successfully!";
    }
    else if (data.type == "referId") {
      message = "Referral link copied successfully!";
    }
    toast({ type: 'success', message });
  }
  const comissionColumns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: 'Date',
      selector: row => dateFormat(row.dateTime),
      width: "220px",
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.refUser.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.refUser.phoneno ? row.refUser.phoneno : "--",
      sortable: true,
    },
    {
      name: 'Commission',
      selector: row => `${row.commissionAmount.toFixed(8)} ${row.currencyName}`,
      sortable: true,
    },
    {
      name: 'Remark',
      selector: row => row.description,
      sortable: true,
    },
  ]
  const referColumns = [
    {
      name: "Sl.No",
      selector: (row, index) => (currentPageRefer - 1) * rowsPerPage + index + 1,
      width: "70px",
    },
    {
      name: 'Date',
      selector: row => dateFormat(row.registerOn),
      width: "220px",
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => (row.email),
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => (row.phoneno ? row.phoneno : "--"),
      sortable: true,
    },
    {
      name: 'Kyc',
      selector: row => <KycStatusComp status={row.kycstatus} />,
      sortable: true,
    },
  ]
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      }
    });
  };
  return (
    <>
      <div className="card bg-card-inner p-lg-3">
        <div className="card-body">
          <div className="card bg-Dark-New">
            <div className="card-body d-lg-flex justify-content-between">
              <div className="text-referral">
                <p className="password-text-44">Referral Code</p>
              </div>
              <div className="text-referral">
                <p className="password-text-33 fw-bold" style={{ cursor: "pointer" }}>
                  {referCode ? (
                    <>
                      {referCode}{" "}
                      <FileCopyIcon
                        className="color-yellow cursor-pointer"
                        onClick={() => copyText({ text: referCode, type: "referCode" })}
                      />
                    </>
                  ) : (
                    "--"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="card bg-Dark-New mt-3">
            <div className="card-body d-lg-flex justify-content-between">
              <div className="text-referral">
                <p className="password-text-44">Referral Link</p>
              </div>
              <div className="text-referral">
                <p className="password-text-33 fw-bold">
                  {referId} {" "}
                  <FileCopyIcon
                    className="color-yellow cursor-pointer" style={{ cursor: "pointer" }}
                    onClick={() => copyText({ text: referId, type: "referId" })}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div class="social-menu-new-footer mt-5 d-flex justify-content-center">
              <ul className='p-0'>
                <li onClick={() => window.open('https://wa.me/?text=' + referId)}><a style={{ backgroundColor: "rgb(37 163 84)" }} href="https://wa.me/?text= + referId" target="blank"><FaWhatsapp className='fab text-white' /></a></li>
                <li onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=' + referId)}><a style={{ backgroundColor: "#1877F2" }} href="" target="blank"><FaFacebookF className='fab text-white' /></a></li>
                <li onClick={() => window.open('https://telegram.me/share/url?url=' + referId + '&text=')}><a style={{ backgroundColor: "#24A1DE" }} href="" target="blank"><FaTelegramPlane className='fab text-white' /></a></li>
                <li onClick={() => window.open('https://twitter.com/intent/tweet?url=' + referId + '&text=')}><a style={{ backgroundColor: "#000000" }} href="" target="blank"><FaXTwitter className='fab text-white' /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {referPercentage ? <p className="mt-4 para-h password-text-55">
        <strong >Refer your Friends and earn {referPercentage}% of the trading fee</strong>
      </p> : ""}
      <Tabs defaultActiveKey="active" id="uncontrolled-tab-example" className="mb-3 mt-1">
        <Tab eventKey="active" title="Commission History">
          <div className='mb-4 referYourFriendsTable dashboard-spot-trade-history-section'>
            <DataTable
              columns={comissionColumns}
              data={referralData.commissionHistory || []}
              // progressPending={loading}
              pagination
              persistTableHead
              paginationServer
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 15]}
              paginationTotalRows={currentPageCommission}
              onChangeRowsPerPage={newRowsPerPage => {
                setRowsPerPage(newRowsPerPage);
                setCurrentPage(1);
              }}
              onChangePage={page => setCurrentPage(page)}
              sortFunction={customSort}
              noDataComponent={
                loading ? <Loader /> : (
                  <div className="text-center">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                    <br />
                    <span>No Records Found</span>
                  </div>
                )
              }
            />
          </div>
        </Tab>
        <Tab eventKey="referredfriend" title="Referred Friends">
          <div className='mb-4 referYourFriendsTable dashboard-spot-trade-history-section'>
            <DataTable
              columns={referColumns}
              data={referralData.referUsers || []}
              pagination
              paginationServer
              // progressPending={loading && <Loader />}
              paginationPerPage={rowsPerPage}
              paginationTotalRows={referralData.totalReferUsers}
              paginationDefaultPage={1}
              paginationRowsPerPageOptions={[5, 10, 15]}
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              onChangePage={page => setCurrentPage(page)}
              onChangeRowsPerPage={newRowsPerPage => {
                setRowsPerPage(newRowsPerPage);
                setCurrentPage(1)
              }}
              sortFunction={customSort}
              noDataComponent={
                loading ? <Loader /> : (
                  <div className="text-center">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                    <br />
                    <span>No Records Found</span>
                  </div>
                )
              }
            />
          </div>
        </Tab>
      </Tabs>
    </>
  )
}
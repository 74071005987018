import React, { useState, useEffect } from 'react'
import NavbarOne from "../siteTheme/NavbarOne";
import { FaArrowCircleLeft } from "react-icons/fa";
import { GoChevronLeft } from "react-icons/go";
import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import { toast } from "../../core/lib/toastAlert";
import { dateFormat } from '../../core/helper/date-format';
import DataTable from "react-data-table-component"
import noreplay from "../../assets/images/deposit/no-re.png";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import Pagination from 'react-responsive-pagination';
import '../../pagination.css';
import notFound from "../../assets/images/file.png";


export default function DeviceManagement(props) {

  const navigate = useNavigate();
  const [loginActivities, setloginActivities] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesTotal, setactivitiesTotal] = useState(0);



  useEffect(() => {
    getLoginActivities();
  }, [currentPage, rowsPerPage]);

  const column = [
    {
      id: 0,
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      id: 1,
      name: "Date",
      selector: (row) => dateFormat(row.dateTime),
      width: "200px",
    },
    {
      id: 2,
      name: "Browser",
      selector: (row) => row.browser,
    },
    {
      id: 3,
      name: "Device Name",
      selector: (row) => row.ipDetails.device,
    },
    {
      id: 4,
      name: "Device OS",
      selector: (row) => row.ipDetails.os,
    },
    {
      id: 5,
      name: "IP Address",
      selector: (row) => row?.ip != "" ? row.ip : "-",
    },
    {
      id: 6,
      name: "Location",
      selector: (row) => `${row.ipDetails.city}, ${row.ipDetails.region}, ${row.ipDetails.country_name}`,
    },
  ];

  async function getLoginActivities() {
    try {
      const data = {
        limit: rowsPerPage,
        offset: rowsPerPage * (currentPage - 1)
      }
      const params = {
        url: `${Config.V2_API_URL}user/loginHistory`,
        method: "POST",
        body: data
      };
      const response = await makeRequest(params);
      if (response.status && response.history) {
        setloginActivities(response.history);
        setactivitiesTotal(response.activitiesTotal)
      }
    } catch (err) { }
  }

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <GoChevronLeft className="deposit-back-button-icon" onClick={() => navigate("/my/profile")} />
                <span className="deposit-text-1">Security</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container device-management-table'>

        <div className='row'>
          <div className='col-lg-12'>
            <h1 className='mt-5'>Login Activites</h1>
            <p className='my-3'>Your login activites details listed</p>
            <div className="container deposit-fourth-section">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <DataTable
                    columns={column}
                    data={loginActivities}
                    persistTableHead
                    pagination
                    paginationServer
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    paginationTotalRows={activitiesTotal}
                    onChangeRowsPerPage={newRowsPerPage => {
                      setRowsPerPage(newRowsPerPage);
                      setCurrentPage(1); // Reset to first page when rows per page changes
                    }}
                    onChangePage={page => setCurrentPage(page)}
                    noDataComponent={
                      <div className="text-center">
                        <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                        <br />
                        <span>No Records Found</span>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

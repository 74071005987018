import { useLocation } from "react-router-dom";
import { AiOutlineSearch } from 'react-icons/ai';
import NavbarOne from "../../siteTheme/NavbarOne";
import Footer from "../../siteTheme/Footer";
import React, { useEffect, useState } from "react";
import { useContextData } from "../../../core/context";
import "../assets/styles/ido-style.css";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import moment from "moment";

function IdoProjectInfo(props) {
    const { state } = useLocation();
    const { myProfile } = useContextData();
    const [userData, setUserData] = useState([]);
    const [tokenCount, setTokenCount] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        if (state && state.userDetails) {
            let Count = [];
            let newArray = [];
            state.userDetails.map((data) => {
                newArray.push({
                    userId: data.userId.uid.toString(),
                    amountDeducted: data.amountDeducted,
                    purchaseDate: data.purchaseDate,
                    numberOfToken: data.numberOfToken
                })
            })
            setUserData(newArray);
            let totalCount = state.userDetails;
            for (let i = 0; i < totalCount.length; i++) {
                let cnt = totalCount[i].numberOfToken;
                Count.push(Number(cnt));
            }
            let total = sumArray(Count);
            setTokenCount(total);
        }
    }, [myProfile]);
    function sumArray(array) {
        let sum = 0;

        /*loop over array and add each item to sum
         */
        array.forEach(item => {
            sum += item;
        });
        // return the result
        return sum;
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/ido-project-info") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    let columns = [];
    let filteredItems = []

    if (userData) {
        filteredItems = userData.filter(item => item.userId && item.userId.includes(filterText))
        console.log("filteredItems", filteredItems);
        columns = [
            {
                name: 'Date',
                selector: row => `${moment(row.purchaseDate).format("DD/MM/YYYY hh:mm A")} UTC`,
                sortable: false,
            },
            {
                name: `No Of Token (${state.symbol})`,
                selector: row => row.numberOfToken,
                sortable: false,
            },
            {
                name: 'Amount Deducted (USDT)',
                selector: row => Number(row.amountDeducted).toFixed(2),
                sortable: false,
            },
            {
                name: 'User Id',
                selector: row => row.userId,
                sortable: false,
            }
        ]
    }
    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            // use the selector to resolve your field names by passing the sort comparators
            // console.log("a-->", selector(a));
            if (typeof selector(a) == "string") {
                const aField = selector(a).toLowerCase();
                const bField = selector(b).toLowerCase();

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            } else {
                const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
                const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
                return direction === 'desc' ? comparison * -1 : comparison;
            }
        });
    };
    return (
        <div className="top-alt-new-ido-section">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div>
                <span className="alt-new-from-ctn-001 position-relative">PROJECT INFO</span>
            </div>
            <div className="hero-section-ido-launchpad-tabs-banner py-5 mt-5">
                <div className="container">
                    <div className="ms-auto me-lg-3 me-3 mb-3">
                        <div className="input-group markets-search-group-section" style={{ "width": "37%" }}>
                            <button type="submit" className="input-group-text"><AiOutlineSearch /></button>
                            <input type="text" className="form-control" placeholder="Search User Id" autoComplete='off' name="search"
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                    <p className="text-center">Tokens Sold - {tokenCount} / {state.supply} {state.symbol}</p>
                    <div className="row g-4 justify-content-center">
                        <div id="launchpadprojecttable" className="col-lg-12">
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                persistTableHead
                                sortFunction={customSort}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IdoProjectInfo
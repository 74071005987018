import React, { useEffect, useState } from "react";
import { averagePrice, crossInitalMarginReduce, crossUnrealizedPnLVal, positionMargin, unrealizedPnLVal } from "../../../core/helper/bybit/usdtPerpetual";
const CrossMarginBalance = (props) => {
    const { pairDetails, userTradeDetails, pairDetailsArr } = props;
    const [pnl, setPnl] = useState(0);
    const [marginBln, setMarginBlc] = useState(0);
    const [marginVal, setMarginVal] = useState(0)
    const [pair, setPair] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [pairData, setPairData] = useState({
        currencySymbol: '',
        currencyName: '',
        pairDecimal: 2,
        priceDecimal: 0
    });

    useEffect(() => {
        if (pairDetails && pairDetails.toCurrency) setPairData({
            currencySymbol: pairDetails.toCurrency.currencySymbol,
            currencyName: pairDetails.toCurrency.currencyName,
            pairDecimal: pairDetails.decimalValue ? pairDetails.decimalValue : 2,
            priceDecimal: pairDetails.amountDecimal ? pairDetails.amountDecimal : 5,
        });
       
    }, [pairDetails]);

    useEffect(() => {
        let marginReduce = [];
        if (userTradeDetails && userTradeDetails.crossPositionOrders) {
            marginReduce = userTradeDetails.crossPositionOrders.map((postionOrder) => { return postionOrder.filled });
            setOrderData(userTradeDetails.crossPositionOrders);
            setMarginBlc(crossInitalMarginReduce(marginReduce));
        }
    }, [userTradeDetails]);

    useEffect(() => {
        let marginValu = 0, pnlVal = 0, pnlVr = [], reducePNL = 0, marginArr = [], reduceMargin = 0;
        if (orderData && orderData?.length >= 0) {
            orderData.map((order) => {
                (pairDetailsArr) && (pairDetailsArr?.length >= 0) && pairDetailsArr.map((pair) => {
                    var price = averagePrice(order.filled);
                    if (pair.pair == order.pairName) {
                        // console.log(price, "-=================price,,,,,,,,order===========", order);
                        marginValu = positionMargin(price, order.totalAmount, order.leverage, pair.takerFee, order.type, order.filled);
                        pnlVal = crossUnrealizedPnLVal(
                            price,
                            order.totalAmount,
                            pair.marketPrice,
                            order.leverage,
                            pair.takerFee,
                            order.type,
                            order.filled
                        );
                        marginArr.push(marginValu);
                        pnlVr.push(marginValu * (props.decimalValue(pnlVal, pair.amountDecimal) / 100));
                    }
                    // console.log("marginValu========", marginValu);
                    reducePNL = pnlVr && (pnlVr?.length > 0) && pnlVr.reduce((arr, occ) => arr + occ);
                    reduceMargin = marginArr && (reduceMargin?.length > 0) && reduceMargin.reduce((acc, occ) => acc + occ);
                    // console.log("marginArr===========", marginArr);
                });
            });
            setPnl(reducePNL);
            // setMarginBlc(reduceMargin);
        }
    }, [pairDetailsArr, orderData]);

    // useEffect(() => {
    //     let data = props.decimalValue(marginVal * (props.decimalValue(pnl, 5) / 100), 2);
    // }, [pnl, marginVal])
    return (
        <div className="trading-page-top-section-3">
            <div className="d-flex flex-row trading-page-top-section-2 align-items-center ">
                <div className="ps-2">
                    <p className="trade-text-5 mb-0">Cross Orders</p>
                </div>
            </div>
            <div className="col-lg-12">
                {/* <h6 className="mt-3 trade-text-5 mb-2">USDT-CROSS</h6> */}
                {/* <div className="d-flex flex-row">
                    <div className="d-flex">
                        <div className="ms-2">
                            <span className="d-block trade-text-7">Margin Ratio</span>
                            <span className="d-block text-spot"></span>
                        </div>
                    </div>
                    <div className="ms-auto ">
                        <span className="d-block trade-text-7">0.00%</span>
                    </div>
                </div> */}
                <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex">
                        <div className="ms-2">
                            <span className="d-block trade-text-7">Maintenance Margin</span>
                            <span className="d-block text-spot"></span>
                        </div>
                    </div>
                    <div className="ms-auto ">
                        <span className="d-block trade-text-7">
                            <span className="ms-2">
                                {
                                    // marginFilled &&
                                    // marginFilled?.length >= 0 &&
                                    // props.decimalValue(crossInitalMarginReduce(marginFilled), pairData.pairDecimal)
                                    props.decimalValue(marginBln, pairData.pairDecimal)

                                }</span>

                            <span> {pairData && pairData.currencySymbol} </span>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <div className="d-flex">
                        <div className="ms-2">
                            <span className="d-block trade-text-7">Margin Balance</span>
                            <span className="d-block text-spot"></span>
                        </div>
                    </div>
                    <div className="ms-auto ">
                        <span className="d-block trade-text-7">
                            <small
                                // className="ms-2 "  
                                className={(pnl >= 0) ? "positiveVal" : "negativeVal"}>
                                {props.decimalValue(pnl, pairData.priceDecimal)}
                            </small>
                            <span> {pairData && pairData.currencySymbol} </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default CrossMarginBalance;
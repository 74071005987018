import React, { useEffect, useState } from "react";

import "../../assets/style.css";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowRight } from 'react-icons/bs';
import { AiFillCalendar } from 'react-icons/ai';
import DataTable from "react-data-table-component";
import { BiCaretDown } from 'react-icons/bi';
import { useContextData } from "../../core/context/index";
import axios from 'axios';
import Config from "../../core/config/index";
import moment from "moment";
import { format, parse } from 'date-fns';


export default function Simpleearn(props) {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userId, setUserId] = useState('');
  const [userearnhistory, setuserearnhistory] = useState([]);
  const [userearnhistory1, setuserearnhistory1] = useState([]);
  const [dropdownvalues, setdropdownvalues] = useState([]);
  const [currencyearn, setcurrencyearn] = useState('ALL');
  const [selectedOption, setSelectedOption] = useState('Interest');
  const [selectedOption1, setSelectedOption1] = useState('Interest');
  const handleReload = () => {
    window.location.reload();
  };
  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
  };




  const { myProfile } = useContextData();
  const columns = [
    {
      id: 1,
      name: "Subscription Date",
      selector: (row) => {
        if (selectedOption1 == 'Interest') {
          return <span>{moment(row.dateTime).format("YYYY-MM-DD HH:mm")}</span>;
        } if (selectedOption1 == 'Redemption') {
          return <span>{moment(row.startdate).format("YYYY-MM-DD HH:mm")}</span>;
        }
      },

      sortable: false,
      reorder: true,
    },
    {
      id: 2,
      name: "Coin",
      selector: (row) => row.currencysymbol,
      sortable: false,
      reorder: true,
    },
    {
      id: 3,
      name: "Interest",
      selector: (row) => {
        if (selectedOption1 == 'Interest') {
          return <span>{Number(row.distributeamount).toFixed(8)}</span>;
        } if (selectedOption1 == 'Redemption') {
          return <span>{Number(row.totalaprrewards).toFixed(8)}</span>;
        }
      },
      sortable: false,
      reorder: true,
    },
    {
      id: 4,
      name: "Locked Days",
      selector: (row) => {
        if (selectedOption1 == 'Interest') {
          return <span>{Number(row.lockedperioddays)} Days</span>;
        } if (selectedOption1 == 'Redemption') {
          return <span>{Number(row.lockedDays)} Days</span>;
        }
      },
      sortable: false,
      reorder: false,
    }
  ];

  useEffect(() => {
    if (Config.SIMPLEEARN_STATUS == "Enable") {
      if (myProfile && myProfile._id) {
        setUserId(myProfile._id);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);
    setStartDate(oneDayBefore);
  }, []);

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const oneDayBefore = new Date(date);
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);
    setStartDate(oneDayBefore);
  };

  const earncurrset = async (event) => {
    //console.log("evevttttt---",event.target.value);
    if (event.target.value == "") {
      setcurrencyearn("ALL")
    }
    else {
      setcurrencyearn(event.target.value)
    }
  }

  const searchfilterbutton = async () => {
    if (userearnhistory.length == 0 || currencyearn == "ALL") {

      const userids = await myProfile?._id;

      const userdetails = {
        userId: userids,
        // dateTime:startDateUnixTimestamp,	
        // endtime:endDateUnixTimestamp	
      }
      const vals = (currencyearn).toUpperCase();
      if (selectedOption === 'Interest') {
        const getuserbal = await axios.post(`${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getusereearnhistory`, userdetails);
        const userearnhistory = getuserbal.data.data;
        const startDateString = startDate.toString();
        const endDateString = endDate.toString();
        const startcleanedDateString = startDateString.replace(" GMT+0530 (India Standard Time)", '');
        const endcleanedDateString = endDateString.replace(" GMT+0530 (India Standard Time)", '');
        const startparsedDate = parse(startcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
        const endparsedDate = parse(endcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
        const startformattedDate = format(startparsedDate, "yyyy-MM-dd'T'00:00:00.SSSxxx");
        const endformattedDate = format(endparsedDate, "yyyy-MM-dd'T'23:59:59.SSSxxx");
        const filteredearnCurrency = userearnhistory.filter((currency) => currency.dateTime >= startformattedDate && currency.dateTime <= endformattedDate);
        setuserearnhistory(filteredearnCurrency)
        //   setuserearnhistory(userearnhistory)	
        console.log("userearnhistory----+$+----", userearnhistory);
        setSelectedOption1('Interest');
      }
      if (selectedOption === 'Redemption') {
        const getuserbal1 = await axios.post(`${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getuserstakehistory`, userdetails);
        const userearnhistory1 = getuserbal1.data.data;
        const startDateString = startDate.toString();
        const endDateString = endDate.toString();
        const startcleanedDateString = startDateString.replace(" GMT+0530 (India Standard Time)", '');
        const endcleanedDateString = endDateString.replace(" GMT+0530 (India Standard Time)", '');
        const startparsedDate = parse(startcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
        const endparsedDate = parse(endcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
        const startformattedDate = format(startparsedDate, "yyyy-MM-dd'T'00:00:00.SSSxxx");
        const endformattedDate = format(endparsedDate, "yyyy-MM-dd'T'23:59:59.SSSxxx");
        const filteredearnCurrency1 = userearnhistory1.filter((currency) => currency.startdate >= startformattedDate && currency.startdate <= endformattedDate && currency.simpleEarnstatus == '1');
        setuserearnhistory1(filteredearnCurrency1)
        //   setuserearnhistory1(userearnhistory1)	
        console.log("userearnhistory1----+$+----", userearnhistory1);
        setSelectedOption1('Redemption');
      }
      // console.log("userearnhistory-----+______",userearnhistory);	
      // const filteredearnCurrency = userearnhistory.filter((currency) => currency.currencysymbol.includes(vals));	
      // console.log("filteredearnCurrency-----",filteredearnCurrency);	

    } if (userearnhistory.length == 0 || currencyearn != "ALL") {
      const userids = await myProfile?._id;
      const userdetails = {
        userId: userids,
        //  starttime:startDateUnixTimestamp,	
        //  endtime:endDateUnixTimestamp	
      }

      const startDateString = startDate.toString();
      const endDateString = endDate.toString();
      const startcleanedDateString = startDateString.replace(" GMT+0530 (India Standard Time)", '');
      const endcleanedDateString = endDateString.replace(" GMT+0530 (India Standard Time)", '');
      const startparsedDate = parse(startcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
      const endparsedDate = parse(endcleanedDateString, "EEE MMM dd yyyy HH:mm:ss", new Date());
      const startformattedDate = format(startparsedDate, "yyyy-MM-dd'T'00:00:00.SSSxxx");
      const endformattedDate = format(endparsedDate, "yyyy-MM-dd'T'23:59:59.SSSxxx");



      if (selectedOption === 'Interest') {
        const vals = (currencyearn).toUpperCase();
        console.log("vals----++----", vals);
        const filteredearnCurrency = userearnhistory.filter((currency) => currency.currencysymbol.includes(vals, userdetails) && currency.dateTime >= startformattedDate && currency.dateTime <= endformattedDate);
        setuserearnhistory(filteredearnCurrency)
        console.log("filteredearnCurrency----+$+----", filteredearnCurrency);
      }
      if (selectedOption === 'Redemption') {
        const vals1 = (currencyearn).toUpperCase();
        console.log("vals1----++----", vals1);
        const filteredearnCurrency1 = userearnhistory1.filter((currency) => currency.currencysymbol.includes(vals1, userdetails) && currency.startdate >= startformattedDate && currency.startdate <= endformattedDate && currency.simpleEarnstatus == '1');
        setuserearnhistory1(filteredearnCurrency1)
        console.log("filteredearnCurrency1----+$+----", filteredearnCurrency1);
      }
    }
  }

  const getmyearndetails = async () => {
    const userids = myProfile?._id;
    const userdetails = {
      userId: userids
    }
    const getuserbal = await axios.post(`${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getusereearnhistory`, userdetails);
    setuserearnhistory(getuserbal.data.data)
  }

  const getfrontcurr = async () => {
    const getdropdowncurr = await axios.post(`${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getfrontcurrency`);
    setdropdownvalues(getdropdowncurr.data.data)
  }

  useEffect(() => {
    getmyearndetails()
    getfrontcurr()
  }, [])

  const gettimess = async () => {

  }

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-class')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    })
  }, []);


  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="simpleearn-top-banner-section">
        <div className="simpleearn-top-value-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <p className="fs-14 mb-1">Earn</p>
                <p className="simpleearn-text-1">Simple Earn History</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-12 accountearn-staking-tabs-section">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-earn-staking-1-tab" data-bs-toggle="pill" data-bs-target="#pills-earn-staking-1" type="button" role="tab" aria-controls="pills-earn-staking-1" aria-selected="true">Locked</button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container my-3">
          <div className="row">
            <div className="col-lg-2">
              <span className="enter-amount-heading">Coin</span>
              <div className="f-group">
                <select className="f-control f-dropdown" placeholder="Select" onChange={earncurrset}>
                  <option value="" selected="selected" className="bg-selection">
                    All
                  </option>
                  {dropdownvalues && dropdownvalues.map(item => (
                    <option key={item.value} value={item.currencysymbol} className="bg-selection">
                      {item.currencysymbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>


            <div className="col-lg-2">
              <span className="enter-amount-heading">Type</span>
              <div className="f-group">
                <select className="f-control f-dropdown" placeholder="Select" value={selectedOption} onChange={handleOptionChange}>
                  <option selected="selected" className="bg-selection" value="Interest">
                    Interest
                  </option>
                  <option value="Redemption" className="bg-selection">
                    Redemption
                  </option>
                  <option value="Subscription" className="bg-selection">
                    Subscription
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <span className="enter-amount-heading">Date</span>
              <div className="d-flex flex-row p-2 border justify-content-center align-items-center">
                <DatePicker className="datepicker-css-styling"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
                <BsArrowRight className="mx-3" />
                <DatePicker className="datepicker-css-styling"
                  selected={endDate}
                  // onChange={(date) => setEndDate(date)}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                <AiFillCalendar className="fs-20" />
              </div>
            </div>
            <div className="col-lg-1">
              <div class="d-grid mt-lg-4">
                <button class="btn simpleearn-history-filter-1 mt-1" type="button" onClick={() => searchfilterbutton()}>Search</button>
              </div>
            </div>
            <div className="col-lg-1">
              <div class="d-grid mt-lg-4">
                <button class="btn simpleearn-history-filter-2 mt-1" type="button" onClick={handleReload}>Reset</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-3">
          <div className="row">
            <div className="col">
              {selectedOption1 == 'Interest' ? (
                <DataTable
                  columns={columns}
                  data={userearnhistory}
                  defaultSortFieldId
                  sortIcon={<BiCaretDown />}
                  pagination
                />
              ) : (
                <span></span>
              )}
              {selectedOption1 == 'Redemption' ? (
                <DataTable
                  columns={columns}
                  data={userearnhistory1}
                  defaultSortFieldId
                  sortIcon={<BiCaretDown />}
                  pagination
                />
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

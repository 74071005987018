import React from 'react';
import NavbarOne from "./siteTheme/NavbarOne";
import soon from "../assets/images/comingsoon.png";
import "../assets/styledev.css";



export default function commingsoon(props) {
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <center>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mt-5 pt-5">
              <img src={soon} alt="comming soon" style={{ width: "16%" }} className='h-auto' />
              <h1 className='cs-soon-text-1 my-5'>coming soon</h1>
              <a href='/' className='banner-button-2 banner-button-2-hover-eff '>Back to Home</a>
            </div>
          </div>
        </div>
      </center>
    </div>
  )
}

import { AESDecrypt } from "../../helper/AESDecrypt";
import { JWTDecode } from "../../helper/JWTDecode";

export const responseChanges = (data) => {
    console.log('data', data)
    if (typeof data === "object") {
        const {
            message,
            error,
            status = false
        } = data;
        if (message && message === 'Data retrived successfully') {
            delete data.message;
        }
        if (status) {
            data.toastType = "success";
        }
        else {
            data.toastType = "error";
        }
        if (typeof error === 'undefined') { data.error = {}; }
        return data;
    } else {
        let decodedData = AESDecrypt(data);
        const resData = JWTDecode(decodedData)
        console.log('resData', resData);

        const {
            message,
            error,
            status = false
        } = resData;
        if (message && message === 'Data retrived successfully') {
            delete resData.message;
        }
        if (status) {
            resData.toastType = "success";
        }
        else {
            resData.toastType = "error";
        }
        if (typeof error === 'undefined') { resData.error = {}; }
        return resData;
    };
};
import React, { useState, useEffect } from "react";
import NavbarOne from "../../../siteTheme/NavbarOne";
import Footer from "../../../siteTheme/Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/ido-style.css';
import { toast } from '../../../../core/lib/toastAlert';
import Config from '../../../../core/config/index';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { makeRequest } from "../../../../core/services/v1/request";
import { useContextData } from "../../../../core/context/index";
import * as Yup from "yup";
import $ from 'jquery';
import { getCookie } from "../../../../core/helper/cookie";

const BlockChain = [
    { value: 0, label: 'Ethereum', name: 'Eth', token: "ERC-20" },
    { value: 1, label: 'Binance Smart Chain', name: 'BSC', token: "BEP-20" },
    { value: 2, label: 'TRON', name: 'TRON', token: "TRC-20" },
    { value: 3, label: 'D-Ecosystem', name: 'DCX', token: "DCX-20" },
    { value: 4, label: 'Cronos', name: 'CRO', token: "CRO-20" },
    { value: 5, label: 'Fantom', name: 'FTM', token: "FTM-20" },
    { value: 6, label: 'Avalanche', name: 'AVAX', token: "AVAX-20" },
    { value: 7, label: 'Arbitrum', name: 'ARBI', token: "ARBI-20" },
    { value: 8, label: 'Optimism', name: 'OPTM', token: "OPTM-20" },
    { value: 9, label: 'Polygon', name: 'MATIC', token: "MATIC-20" },
];

const Terms = [
    { value: 0, label: 'Public', name: 'public' },
    { value: 1, label: 'Anonymous', name: 'anonymous' },
    { value: 2, label: 'Mixed', name: 'mixed' },
];
const Options = [
    { value: 0, label: "Yes", name: "yes" },
    { value: 1, label: "No", name: "no" }
]
function Home(props) {
    const navigate = useNavigate();
    const { launchPadForm } = useContextData()
    const [userData, setUserData] = useState({
        blockChainSelected: "",
        userTermSelected: "",
    });
    const [imagePreview, setPreviewImage] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageErr, setImageErr] = useState('');
    const [loader, setLoader] = useState(false);
    const [coverLoader, setCoverLoader] = useState(false);
    const [coverPicUrl, setCoverPicUrl] = useState(null);
    const [coverPicErr, setCoverPicErr] = useState('');
    const [buttonDisable, setButtonDisable] = useState(true);

    const imageUpload = async (values, type) => {
        if (touched.image == true) {
            touched.image = false;
        }
        let uploadFile = values
        if (uploadFile) {
            // Reset the previously chosen image
            if (type == "logo") setImageUrl('');
            // Check the file size
            const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
            if (uploadFile.size > maxSizeInBytes) {
                if (type == "logo") {
                    setImageErr("Image size must be less than 5 MB.");
                    return;
                } else {
                    setCoverPicErr("Image size must be less than 5 MB.");
                    return;
                }
            } else {
                if (type == "logo") {
                    setImageErr(""); // Clear any previous error message
                } else {
                    setCoverPicErr("");
                }
            }

            const fileExt = uploadFile.name.split('.').pop().toLowerCase();

            if (!['jpg', 'jpeg', 'png', 'webp'].includes(fileExt)) {
                if (type == "logo") {
                    setImageErr('Please select a .jpg, .jpeg, .png, or .webp image.');
                    return;
                } else {
                    setCoverPicErr('Please select a .jpg, .jpeg, .png, or .webp image.');
                    return;
                }
            } else {
                if (type == "logo") {
                    setImageErr(''); // Clear any previous error message
                } else {
                    setCoverPicErr('');
                }
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result)
            };
            reader.readAsDataURL(uploadFile);
            //APi integration
            const formData = new FormData();
            let sizeFile = 1;
            let fileToUpload = uploadFile;
            let fileName = 'attachment';
            let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
            formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            const params = {
                url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type=attachment`,
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            if (type == "logo") {
                setLoader(true);
                const response = (await makeRequest(params));
                setImageUrl(response.message[0].location);
                setLoader(false);
            } else {
                setCoverLoader(true);
                const response = (await makeRequest(params));
                setCoverLoader(false);
                setCoverPicUrl(response.message[0].location);
            }
        }
    }
    const { handleChange, handleSubmit, handleBlur, touched, errors, values } = useFormik({
        initialValues: {
            email: "",
            userName: "",
            projectName: "",
            projectInfo: "",
            investors: "",
            smartContractAudited: "",
            paper_link: "",
            websiteLink: "",
            gitLink: "",
            telegramGrpLink: "",
            telegramUserName: "",
            blockChainSelected: userData.blockChainSelected,
            userTermSelected: userData.userTermSelected,
            twitterLink: "",
        },
        //** form validation functions */
        validationSchema: Yup.object().shape({
            userName: Yup.string().required("User Name must be between 1 and 32 characters!"),
            email: Yup.string().email('Please enter valid email').required("Email field is required!"),
            projectName: Yup.string().required("Project Name field is required!"),
            projectInfo: Yup.string().required("Project information field is required!"),
            investors: Yup.string().required("Investors field is required!"),
            smartContractAudited: Yup.string().required("smart contract audited field is required!"),
            paper_link: Yup.string()
                .matches(/\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?![^\/])/g, "Invalid Whitepaper Link Ex.https://www.example.com")
                .test('is-unique', 'paper link must be unique', function(values) {
                    const { websiteLink, gitLink, telegramGrpLink, twitterLink } = this.parent;
                    return values !== websiteLink && values !== gitLink && values !== telegramGrpLink && values !== twitterLink
                }).required("Whitepaper field is required! Ex. https://www.example.com"),
            websiteLink: Yup.string()
                .matches(/\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?![^\/])/g, "Invalid Website Link Ex. https://www.example.com")
                .test('is-unique', 'websiteLink must be unique', function(values) {
                    const { paper_link, gitLink, telegramGrpLink, twitterLink } = this.parent;
                    return values !== paper_link && values !== gitLink && values !== telegramGrpLink && values !== twitterLink
                })
                .required("Website link field is required! Ex. https://www.example.com"),
            gitLink: Yup.string()
                .matches(/\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?![^\/])/g, "Invalid Github Link Ex. https://www.github.com")
                .test('is-unique', 'gitLink must be unique', function(values) {
                    const { paper_link, websiteLink, telegramGrpLink, twitterLink } = this.parent;
                    return values !== paper_link && values !== websiteLink && values !== telegramGrpLink && values !== twitterLink
                })
                .required("GitHub link field is required! Ex. https://www.github.com"),
            telegramGrpLink: Yup.string()
                .matches(/(telegram\.me|t\.me)\/[a-zA-Z0-9_]+$/g, "Invalid Telegram Group Link Ex. https://www.t.me/groupname")
                .test('is-unique', 'telegramGrpLink must be unique', function(values) {
                    const { paper_link, websiteLink, gitLink, twitterLink } = this.parent;
                    return values !== paper_link && values !== websiteLink && values !== gitLink && values !== twitterLink
                })
                .required("Telegram group link field is required! Ex. https://www.t.me/groupname"),
            telegramUserName: Yup.string().matches(/.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/g, "Invalid Telegram username Ex. @username")
            .test('is-unique', 'telegramGrpLink must be unique', function(values) {
                const { paper_link, websiteLink, gitLink, twitterLink } = this.parent;
                return values !== paper_link && values !== websiteLink && values !== gitLink && values !== twitterLink
            })
            .required("Telegram name field is required! Ex. @username"),
            blockChainSelected: Yup.string().required("Select any one block chain"),
            userTermSelected: Yup.string().required("Select any one user team"),
            twitterLink: Yup.string()
                .matches(/(?:https:\/\/)?(?:www\.)?x\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/g, "Invalid Twitter Link Ex:https://x.com/")
                .test('is-unique', 'X Link must be unique', function(values) {
                    const { paper_link, websiteLink, gitLink, telegramGrpLink } = this.parent;
                    return values !== paper_link && values !== websiteLink && values !== gitLink && values !== telegramGrpLink
                })
                .required("X link field is required! Ex:https://x.com/"),
        }),
        //** submit functionality */
        onSubmit: async (values) => {
            let cookies = getCookie("userToken");
            if (cookies != null) {
                try {
                    var chain = await BlockChain.filter((ele) => ele.value == Number(values.blockChainSelected));
                    var term = await Terms.filter((val) => val.value == Number(values.userTermSelected));
                    if (imageUrl == null) {
                        return setImageErr("Project logo field in required");
                    }
                    const payload = {
                        userName: values.userName,
                        email: values.email,
                        projectName: values.projectName,
                        projectInfo: values.projectInfo,
                        blockChainSelect: chain[0].name,
                        tokenName: chain[0].token,
                        userTeam: term[0].name,
                        investors: values.investors,
                        smartContractAudited: values.smartContractAudited,
                        paper_link: values.paper_link,
                        websiteLink: values.websiteLink,
                        gitLink: values.gitLink,
                        telegramGrpLink: values.telegramGrpLink,
                        telegramUserName: values.telegramUserName,
                        twitterLink: values.twitterLink,
                        image: imageUrl,
                    };
                    setImageUrl(payload.image);
                    navigate("/idoformtoken", { state: { data: payload } })
                } catch (error) {
                    toast({ type: "error", message: "Your form field is required!" });
                }
            } else {
                toast({ type: "error", message: "Please login to create project" });
            }
        }
    });
    useEffect(() => {
        if (values.userName !== "" &&
            values.email !== "" &&
            values.projectName !== "" &&
            values.projectInfo !== "" &&
            values.blockChainSelected !== "" &&
            values.userTermSelected !== "" &&
            values.investors !== "" &&
            values.smartContractAudited !== "" &&
            values.paper_link !== "" &&
            values.websiteLink !== "" &&
            values.gitLink !== "" &&
            values.telegramGrpLink !== "" &&
            values.telegramUserName !== "" &&
            values.twitterLink !== "" &&
            imageUrl !== null
        ) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }, [values, imageUrl, coverPicUrl]);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/ido-form") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    return (
        <div className="Ido-App-lanchpad top-alt-new-ido-section">
             <ToastContainer limit={1} />
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
           
            <div className="hero-section-ido-launchpad-banner py-5 launchpad-active-nav-class">
           
                <div className="container">
                
                    <span className="alt-new-from-ctn-01 position-relative">Form</span>
                    <div className="row g-4 justify-content-around">
                    
                        <div className="col-lg-8 application-form-ido-section">
                            <p className="top-alt-new-ido-text-1-1 mb-2">GetX Application Form</p>
                            <p className="ido-text-3 mb-4" style={{ textTransform: "capitalize" }}>
                            Welcome! Please apply to launch your token on GetX so we can start reviewing your project application.
                            </p>
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Your Name <span className="text-danger">*</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="userName"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="userName"
                                        value={values.userName || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.userName && errors.userName ? true : false}
                                    />
                                    {
                                        touched.userName && errors.userName && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.userName}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Email Address <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="email"
                                        placeholder="example@example.com"
                                        value={values.email || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.email && errors.email ? true : false}
                                    />
                                    {
                                        touched.email && errors.email && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.email}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Project Name <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="projectName"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="projectName"
                                        value={values.projectName || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.projectName && errors.projectName ? true : false}
                                    />
                                    {
                                        touched.projectName && errors.projectName && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.projectName}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">
                                        Tell us more about your project, the more info you give us the more likely we will consider your application. <span className="text-danger">*</span>
                                    </p>

                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="projectInfo"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="projectInfo"
                                        rows="3"
                                        value={values.projectInfo || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.projectInfo && errors.projectInfo ? true : false}
                                    ></textarea>
                                    {
                                        touched.projectInfo && errors.projectInfo && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.projectInfo}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4 " >
                                    <p className="ido-active-text-2 mb-1">Blockchain <span className="text-danger">*</span></p>
                                    <div className="form-group ">
                                        <select
                                            className="form-select"
                                            placeholder="Select"
                                            name="blockChainSelected"
                                            onChange={handleChange}
                                            option={BlockChain}
                                            onBlur={handleBlur}
                                            value={values.blockChainSelected}
                                        >
                                          
                                            <option className="form-select new-select-options">Select Option</option>
                                           
                                           {/* <span className="text-white"></span> */}
                                                
                                            {
                                                BlockChain.map((chain) => {
                                                    return (
                                                        <>
                                                            <option
                                                                className="form-select new-select-options"
                                                                value={chain.value} >
                                                                {chain.label}
                                                            </option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        touched.blockChainSelected && errors.blockChainSelected && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.blockChainSelected}
                                            </small>
                                        )
                                    }
                                </div>

                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Is your team Public or Anonymous? <span className="text-danger">*</span></p>

                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            placeholder="Select"
                                            name="userTermSelected"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            option={Terms}
                                            value={values.userTermSelected}
                                        >
                                            <option className="form-select new-select-options">Select Option</option>
                                            {
                                                Terms.map((term) => {
                                                    return (
                                                        <>
                                                            <option
                                                                className="form-select new-select-options"
                                                                value={term.value}>
                                                                {term.label}
                                                            </option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        touched.userTermSelected && errors.userTermSelected && (
                                            <small className="invalid-emailnew-select-options error password-text-33">
                                                {errors.userTermSelected}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Advisors/Backers/Investors <span className="text-danger">*</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="investors"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="investors"
                                        value={values.investors || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.investors && errors.investors ? true : false}
                                    />
                                    {
                                        touched.investors && errors.investors && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.investors}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Your smart contract have been audited? <span className="text-danger">*</span></p>
                                    <select
                                        className="form-select form-group"
                                        placeholder="Select"
                                        name="smartContractAudited"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        option={Options}
                                        value={values.smartContractAudited}
                                    >
                                        <option className="form-select new-select-options">Select Option</option>
                                        {
                                            Options.map((term) => {
                                                return (
                                                    <>
                                                        <option
                                                            className="form-select new-select-options"
                                                            value={term.value}>
                                                            {term.label}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        touched.smartContractAudited && errors.smartContractAudited && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.smartContractAudited}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Link to Whitepaper or Lite paper? <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="paper_link"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="paper_link"
                                        value={values.paper_link || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.paper_link && errors.paper_link ? true : false}
                                    />
                                    {
                                        touched.paper_link && errors.paper_link && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.paper_link}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Website Link <span className="text-danger">*</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="websiteLink"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="websiteLink"
                                        value={values.websiteLink || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.websiteLink && errors.websiteLink ? true : false}
                                    />
                                    {
                                        touched.websiteLink && errors.websiteLink && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.websiteLink}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">GitHub Link? <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="gitLink"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="gitLink"
                                        value={values.gitLink || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.gitLink && errors.gitLink ? true : false}
                                    />
                                    {
                                        touched.gitLink && errors.gitLink && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.gitLink}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Twitter Link? <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="twitterLink"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="twitterLink"
                                        value={values.twitterLink || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.twitterLink && errors.twitterLink ? true : false}
                                    />
                                    {
                                        touched.twitterLink && errors.twitterLink && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.twitterLink}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Telegram Group Link? <span className="text-danger">*</span></p>

                                    <input
                                        type="text"
                                        className="form-control"
                                        id="telegramGrpLink"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="telegramGrpLink"
                                        value={values.telegramGrpLink || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.telegramGrpLink && errors.telegramGrpLink ? true : false}
                                    />
                                    {
                                        touched.telegramGrpLink && errors.telegramGrpLink && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.telegramGrpLink}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Your Telegram handle (@username) <span className="text-danger">*</span></p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="telegramUserName"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="telegramUserName"
                                        value={values.telegramUserName || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={touched.telegramUserName && errors.telegramUserName ? true : false}
                                    />
                                    {
                                        touched.telegramUserName && errors.telegramUserName && (
                                            <small className="invalid-email error password-text-33">
                                                {errors.telegramUserName}
                                            </small>
                                        )
                                    }
                                </div>
                                <div className="mb-4">
                                    <p className="ido-active-text-2 mb-1">Project logo <span className="text-danger">*</span></p>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        autoComplete="off"
                                        aria-label="Sizing example input"
                                        aria-describedby="emailHelp"
                                        name="image"
                                        accept=".jpg, .jpeg, .png, .webp"  // Allow jpg, jpeg, png, and webp
                                        onChange={(event) => imageUpload(event.currentTarget.files[0], "logo")}
                                    />
                                    <small>Note: Accepted types(jpg, jpeg, png, webp). Max image size 5MB</small>
                                    {
                                        imageErr && (
                                            <small className="invalid-email error password-text-33">
                                                {imageErr}
                                            </small>
                                        )
                                    }
                                </div>
                                {
                                    loader == true ?
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        :
                                        ''
                                }
                                {imageUrl && (
                                    <img src={imageUrl ? imageUrl : launchPadForm && launchPadForm.image} alt="Image Preview" style={{ maxWidth: '200px' }} />
                                )}
                                <div className="text-center">
                                    <button type="submit" disabled={buttonDisable} className="btn ido-launchpad-button alt-new-btn-ido"> NEXT </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;

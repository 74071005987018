import Btclogo from "../../assets/images/coin-logos/btc.png";
import Ethlogo from "../../assets/images/coin-logos/eth.png";
import Bnblogo from "../../assets/images/coin-logos/bnb.png";
import Xrplogo from "../../assets/images/coin-logos/xrp.png";
import { AiFillCloseCircle, AiFillCaretDown } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { HiOutlineAdjustments } from "react-icons/hi";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import moment from "moment";
import { showNumber } from "../../core/helper/date-format";
import "./eoption.module.css";
import DataTable from "react-data-table-component";
import Barcharteoption from "./chart/Barchart-eoption";
import TradingView from "./tradeChart/trading-view";
import { useParams } from "react-router-dom";
import { Key } from "@mui/icons-material";
import EoptionTradingNewAllTable from "./eoption-trading-new-allTable";

export default function Eoptionleft(props) {
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      $(".new-filter-dropdown-button").mouseenter(function () {
        $(".dropdown-menu-static").addClass("show");
      });
      // $(".new-filter-dropdown-button").click(function(){
      //   $(".dropdown-menu-static").removeClass("show");
      // });
      $(".dropdown-menu-static").click(function () {
        $(".dropdown-menu-static").removeClass("show");
      });
    } else {
      $(".new-filter-dropdown-button").mouseenter(function () {
        $(".dropdown-menu-static").addClass("show");
      });
      $(".new-filter-dropdown-button").click(function () {
        $(".dropdown-menu-static").removeClass("show");
      });
      $(".dropdown-menu-static").mouseleave(function () {
        $(".dropdown-menu-static").removeClass("show");
      });
    }

    $("ul.nav.navbar-nav")
      .on("click.bs.dropdown", function (e) {
        var $a = $(e.target),
          is_a = $a.is(".is_a");
        if ($a.hasClass("dropdown-toggle")) {
          $("ul.dropdown-menu", this).toggle(!is_a);
          $a.toggleClass("is_a", !is_a);
        }
      })
      .on("mouseleave", function () {
        $("ul.dropdown-menu", this).hide();
        $(".is_a", this).removeClass("is_a");
      });

    if (window.matchMedia("(max-width: 767px)").matches) {
      $(".eoption-left-table-puts").removeClass("show active");
      $(".eoption-left-table-puts-2").removeClass("show active");
    } else {
    }
    $(".eiption-table-row-data").click(function () {
      $(this).addClass("eiption-table-row-active");
    });
    $(".eiption-table-row-active-close").click(function () {
      $(".eiption-table-row-data").removeClass("eiption-table-row-active");
    });
  }, []);

  const { id } = useParams();
  const toDay = moment().format("YYYY-MM-DD");
  const tommorow = moment().add(1, "days").format("YYYY-MM-DD");
  const nextDay = moment().add(2, "days").format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState("All");

  const [bvolIndexData, setBvolIndexData] = useState({});
  const [todayOption, setTodayOption] = useState([]);
  const [tomorrowOption, setTomorrow] = useState([]);
  const [nextOption, setNextOption] = useState([]);

  const [allSocketDatas, setAllSocketdatas] = useState([]);
  const [paramsData, setParamsData] = useState({});
  const [optionData, setOptionData] = useState({
    callData: [],
    putData: [],
  });

  useEffect(() => {
    const todayOptionSocketData = props.todayOptionSocketData;
    const tommorowOptionSocketData = props.tommorowOptionSocketData;
    const theNextDayOptionSocketData = props.theNextDayOptionSocketData;

    if (todayOptionSocketData) {
      setTodayOption(todayOptionSocketData);
    }
    if (tommorowOptionSocketData) {
      setTomorrow(tommorowOptionSocketData);
    }
    if (theNextDayOptionSocketData) {
      setNextOption(theNextDayOptionSocketData);
    }
    if (props.bvolIndexDatas) {
      setBvolIndexData(props.bvolIndexDatas);
    }
  }, [
    props.todayOptionSocketData,
    props.tommorowOptionSocketData,
    props.theNextDayOptionSocketData,
    props.bvolIndexDatas,
  ]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    getDataForSelectedDate(selectedDate);
  }, [selectedDate, todayOption, tomorrowOption, nextOption]);

  const getDataForSelectedDate = (date) => {
    if (date === "All") {
      if (todayOption || (tomorrowOption && nextOption)) {
        const allDateDataArray = [todayOption, tomorrowOption, nextOption];
        setAllSocketdatas(allDateDataArray);
      }
    }
    if (date === toDay) {
      setOptionData((prevData) => ({
        ...prevData,
        callData: todayOption.callData,
        putData: todayOption.putsData,
      }));
    } else if (date === tommorow) {
      setOptionData((prevData) => ({
        ...prevData,
        callData: tomorrowOption.callData,
        putData: tomorrowOption.putsData,
      }));
    } else if (date === nextDay) {
      setOptionData((prevData) => ({
        ...prevData,
        callData: nextOption.callData,
        putData: nextOption.putsData,
      }));
    } else {
      setOptionData([]);
    }
  };

  useEffect(() => {
    allSocketDatas.map((datas) => {
      const call = datas.callData;
      const put = datas.putsData;
      if (call && Array.isArray(call)) {
        call.map((callDatas) => {
          if (id === callDatas.optionalTradeSymbol) {
            setParamsData(callDatas);
          }
        });
      }
      if (put && Array.isArray(put)) {
        put.map((putDatas) => {
          if (id === putDatas.optionalTradeSymbol) {
            setParamsData(putDatas);
          }
        });
      }
    });
  });

  function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
      return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
  }

  const buyImpliedVolatility = paramsData.buyImpliedVolatility * 100;
  const selImpliedVlotility = paramsData.sellImpliedVolatility * 100;
  const impliedVolatility = paramsData.impliedVolatility * 100;
  const priceChangePercentage = paramsData.priceChangePercent * 100;

  return (
    <div>
      <div className="d-flex flex-row gap-3 align-items-center eoption-left-tabs-1">
        <div className="d-flex gap-2">
          <a href="/eoption" type="button" class="eoption-new-grid-btn">
            <BsFillGrid1X2Fill />
          </a>
          <a
            href="/eoptionnew"
            type="button"
            class="eoption-new-grid-btn eoption-new-grid-btn-active"
          >
            <HiOutlineAdjustments />
          </a>
        </div>
        <div className="">
          <ul class="nav nav-pills gap-2" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-BTC-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-BTC"
                type="button"
                role="tab"
                aria-controls="pills-BTC"
                aria-selected="true"
              >
                <div className="d-flex flex-row gap-2">
                  <div>
                    <img
                      className="eoption-left-image-1"
                      src={Btclogo}
                      alt="Btclogo"
                    />
                  </div>
                  <div>
                    <p className="eoption-left-text-1 mb-0 text-start">
                      BTCUSDT
                    </p>
                    <div className="d-flex flex-row eoption-left-flex-1">
                      <div>
                        <span className="eoption-left-text-2">30,299</span>
                      </div>
                      <div className="ms-aupriceDecimalto">
                        <span className="eoption-left-text-2">
                          BVOL: {decimalValue(bvolIndexData.high, 2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </li>
            {/* <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-ETH-tab" data-bs-toggle="pill" data-bs-target="#pills-ETH" type="button" role="tab" aria-controls="pills-ETH" aria-selected="false">
                            <div className="d-flex flex-row gap-2">
                                <div>
                                    <img className="eoption-left-image-1" src={Ethlogo} alt="Ethlogo" />
                                </div>
                                <div>
                                    <p className="eoption-left-text-1 mb-0 text-start">ETHUSDT</p>
                                    <div className="d-flex flex-row eoption-left-flex-1">
                                        <div>
                                            <span className="eoption-left-text-2">30,299</span>
                                        </div>
                                        <div className="ms-auto">
                                            <span className="eoption-left-text-2">BVOL: 43.75</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-BNB-tab" data-bs-toggle="pill" data-bs-target="#pills-BNB" type="button" role="tab" aria-controls="pills-BNB" aria-selected="false">
                            <div className="d-flex flex-row gap-2">
                                <div>
                                    <img className="eoption-left-image-1" src={Bnblogo} alt="Bnblogo" />
                                </div>
                                <div>
                                    <p className="eoption-left-text-1 mb-0 text-start">BNBUSDT</p>
                                    <div className="d-flex flex-row eoption-left-flex-1">
                                        <div>
                                            <span className="eoption-left-text-2">30,299</span>
                                        </div>
                                        <div className="ms-auto">
                                            <span className="eoption-left-text-2">BVOL: 43.75</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-XRP-tab" data-bs-toggle="pill" data-bs-target="#pills-XRP" type="button" role="tab" aria-controls="pills-XRP" aria-selected="false">
                            <div className="d-flex flex-row gap-2">
                                <div>
                                    <img className="eoption-left-image-1" src={Xrplogo} alt="Xrplogo" />
                                </div>
                                <div>
                                    <p className="eoption-left-text-1 mb-0 text-start">XRPUSDT</p>
                                    <div className="d-flex flex-row eoption-left-flex-1">
                                        <div>
                                            <span className="eoption-left-text-2">30,299</span>
                                        </div>
                                        <div className="ms-auto">
                                            <span className="eoption-left-text-2">BVOL: 43.75</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li> */}
          </ul>
        </div>
      </div>

      <div className="eoption-left-tabs-2 eoption-left-new-tabs-2">
        <div className="d-flex flex-lg-row gap-lg-4 gap-3 align-items-center flex-wrap">
          <div>
            <div class="dropdown">
              <button
                class="bg-transparent border-0 d-flex align-items-center gap-2 new-filter-dropdown-button"
                type="button"
              >
                <div className="d-flex flex-column text-start">
                  <span className="new-eption-text-1">
                    {paramsData.optionalTradeSymbol}
                  </span>
                  <span className="new-eption-text-2">13.83x</span>
                </div>
                <AiFillCaretDown className="text-muted fs-12" />
              </button>
              <ul class="dropdown-menu dropdown-menu-static">
                <div className="new-eption-text-6">
                  <ul class="nav nav-pills gap-2" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-drop-1-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-drop-1"
                        type="button"
                        role="tab"
                        aria-controls="pills-drop-1"
                        aria-selected="true"
                      >
                        BTC
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-drop-2-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-drop-2"
                        type="button"
                        role="tab"
                        aria-controls="pills-drop-2"
                        aria-selected="false"
                      >
                        ETH
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-drop-3-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-drop-3"
                        type="button"
                        role="tab"
                        aria-controls="pills-drop-3"
                        aria-selected="false"
                      >
                        BNB
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-drop-4-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-drop-4"
                        type="button"
                        role="tab"
                        aria-controls="pills-drop-4"
                        aria-selected="false"
                      >
                        XRP
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-drop-5-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-drop-5"
                        type="button"
                        role="tab"
                        aria-controls="pills-drop-5"
                        aria-selected="false"
                      >
                        DOGE
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="mt-1 new-eption-text-7">
                  <ul class="nav nav-pills gap-1" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-date1-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-date1"
                        type="button"
                        role="tab"
                        aria-controls="pills-date1"
                        aria-selected="true"
                        onClick={() => handleDateClick("All")}
                      >
                        All
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-date2-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-date2"
                        type="button"
                        role="tab"
                        aria-controls="pills-date2"
                        aria-selected="false"
                        onClick={() => handleDateClick(toDay)}
                      >
                        {toDay}
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-date3-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-date3"
                        type="button"
                        role="tab"
                        aria-controls="pills-date3"
                        aria-selected="false"
                        onClick={() => handleDateClick(tommorow)}
                      >
                        {tommorow}
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-date4-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-date4"
                        type="button"
                        role="tab"
                        aria-controls="pills-date4"
                        aria-selected="false"
                        onClick={() => handleDateClick(nextDay)}
                      >
                        {nextDay}
                      </button>
                    </li>
                  </ul>
                </div>

                {selectedDate === "All" ? (
                  <>
                    <EoptionTradingNewAllTable
                      allSocketDatas={allSocketDatas}
                    />
                  </>
                ) : (
                  <>
                    <div className="mt-2">
                      <div className="new-after-table-2-eoption">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Symbol</th>
                              <th scope="col">Last Price</th>
                              <th scope="col">24h%</th>
                              <th scope="col">Vol</th>
                            </tr>
                          </thead>
                          <tbody>
                            {optionData &&
                            optionData.callData &&
                            optionData.callData.length > 0 ? (
                              <>
                                {optionData.callData.map((callData, index) => {
                                  const putData = optionData.putData[index];
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div className="d-flex flex-column text-start">
                                            <span className="">
                                              {callData.optionalTradeSymbol}
                                            </span>
                                            <span className="new-eption-text-2-2">
                                              13.83x
                                            </span>
                                          </div>
                                        </td>
                                        <td>{callData.ltsPrice}</td>
                                        <td>
                                          <span style={{ color: "red" }}>
                                            {decimalValue(
                                              callData.priceChangePercent * 100,
                                              callData.decimalValue
                                            )}
                                            %
                                          </span>
                                        </td>
                                        <td>{callData.tradeVolContracts}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="d-flex flex-column text-start">
                                            <span className="">
                                              {putData.optionalTradeSymbol}
                                            </span>
                                            <span className="new-eption-text-2-2">
                                              13.83x
                                            </span>
                                          </div>
                                        </td>
                                        <td>{putData.ltsPrice}</td>
                                        <td>
                                          <span style={{ color: "red" }}>
                                            {decimalValue(
                                              putData.priceChangePercent * 100,
                                              putData.decimalValue
                                            )}
                                            %
                                          </span>
                                        </td>
                                        <td>{putData.tradeVolContracts}</td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div>
            <span className="new-eption-text-3" style={{ color: "red" }}>
              {paramsData.ltsPrice}
            </span>
          </div>
          <div className="d-flex flex-lg-row gap-lg-3 gap-3 align-items-center flex-wrap">
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Index</span>
              <span className="new-eption-text-5">
                {decimalValue(paramsData.indexPrice, paramsData.decimalValue)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Mark</span>
              <span className="new-eption-text-5">{paramsData.markPrice}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Strike</span>
              <span className="new-eption-text-5">
                {paramsData.strikePrice}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">24h Change</span>
              <span className="new-eption-text-5" style={{ color: "red" }}>
                {paramsData.priceChange} (
                {decimalValue(priceChangePercentage, paramsData.decimalValue)}%)
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">24h Vol (Cont)</span>
              <span className="new-eption-text-5">
                {paramsData.tradeVolContracts}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Time to Expiry</span>
              <span className="new-eption-text-5">02:55:35</span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">IV (Bid/Mark/Ask)</span>
              <span className="new-eption-text-5">
                {decimalValue(buyImpliedVolatility, paramsData.decimalValue)}% /
                {decimalValue(impliedVolatility, paramsData.decimalValue)}% /
                {decimalValue(selImpliedVlotility, paramsData.decimalValue)}%
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Delta</span>
              <span className="new-eption-text-5">
                {decimalValue(paramsData.delta, paramsData.decimalValue)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Gamma</span>
              <span className="new-eption-text-5">
                {decimalValue(paramsData.gamma, paramsData.decimalValue)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Theta</span>
              <span className="new-eption-text-5">
                {decimalValue(paramsData.theta, paramsData.decimalValue)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="new-eption-text-4">Vega</span>
              <span className="new-eption-text-5">
                {decimalValue(paramsData.vega, paramsData.decimalValue)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="eoption-left-tabs-2 eoption-left-middle-section-1 py-0 pe-0">
        <div className="d-flex flex-lg-row flex-column align-items-start">
          <div className="eoption-left-new-inner-1">
            <div className="mb-1 mt-2 new-eption-text-8">
              <ul class="nav nav-pills gap-2" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-date1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-date1"
                    type="button"
                    role="tab"
                    aria-controls="pills-date1"
                    aria-selected="true"
                    onClick={() => handleDateClick("All")}
                  >
                    All
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-date2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-date2"
                    type="button"
                    role="tab"
                    aria-controls="pills-date2"
                    aria-selected="false"
                    onClick={() => handleDateClick(toDay)}
                  >
                    {toDay}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-date3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-date3"
                    type="button"
                    role="tab"
                    aria-controls="pills-date3"
                    aria-selected="false"
                    onClick={() => handleDateClick(tommorow)}
                  >
                    {tommorow}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-date4-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-date4"
                    type="button"
                    role="tab"
                    aria-controls="pills-date4"
                    aria-selected="false"
                    onClick={() => handleDateClick(nextDay)}
                  >
                    {nextDay}
                  </button>
                </li>
              </ul>
            </div>
            {selectedDate === "All" ? (
              <>
               
                        <EoptionTradingNewAllTable
                          allSocketDatas={allSocketDatas}
                        />
                    
              </>
            ) : (
              <>
                <div className="new-after-table-2-eoption">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">24h%</th>
                        <th scope="col">Vol</th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionData &&
                      optionData.callData &&
                      optionData.callData.length > 0 ? (
                        <>
                          {optionData.callData.map((callData, index) => {
                            const putData = optionData.putData[index];
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div className="d-flex flex-column text-start">
                                      <span className="">
                                        {callData.optionalTradeSymbol}
                                      </span>
                                      <span className="new-eption-text-2-2">
                                        13.83x
                                      </span>
                                    </div>
                                  </td>
                                  <td>{callData.ltsPrice}</td>
                                  <td>
                                    <span style={{ color: "red" }}>
                                      {decimalValue(
                                        callData.priceChangePercent * 100,
                                        callData.decimalValue
                                      )}
                                      %
                                    </span>
                                  </td>
                                  <td>{callData.tradeVolContracts}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex flex-column text-start">
                                      <span className="">
                                        {putData.optionalTradeSymbol}
                                      </span>
                                      <span className="new-eption-text-2-2">
                                        13.83x
                                      </span>
                                    </div>
                                  </td>
                                  <td>{putData.ltsPrice}</td>
                                  <td>
                                    <span style={{ color: "red" }}>
                                      {decimalValue(
                                        putData.priceChangePercent * 100,
                                        putData.decimalValue
                                      )}
                                      %
                                    </span>
                                  </td>
                                  <td>{putData.tradeVolContracts}</td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
          <div className="eoption-left-new-inner-2">
            <TradingView theme={props.theme} />
          </div>
          <div className="eoption-left-new-inner-3">
            <div className="p-0 trading-table-total-height-eoption">
              <div className="d-flex flex-row trading-page-top-section-2 align-items-center top-heading-total-height">
                <div className="ps-2">
                  <p className="trade-text-5 mb-0">Orderbook</p>
                </div>
              </div>
              <div className="trading-page-top-section-3  eoption-left-new-inner-3-1">
                <div className="trading-table-left-section-eoption progress-bar-bg-color">
                  <div className="d-flex flex-row mb-1 justify-content-between orderbook-table-heading-section border-top-0">
                    <div className="progress-table-1">
                      <span className="orderbook-text-1">Price(USDT)</span>
                    </div>
                    <div className="progress-table-2">
                      <span className="orderbook-text-1">Size(Cont)</span>
                    </div>
                    <div className="progress-table-3">
                      <span className="orderbook-text-1">Sum</span>
                    </div>
                  </div>
                  <ul className="px-0">
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "50%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "30%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "80%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "20%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "90%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar ask-bar"
                          style={{ width: "10%" }}
                        ></div>
                        <div class="progress-table-1 color-red">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-0 trading-table-total-height-eoption ">
              <div className="d-flex flex-row trading-page-top-section-2 align-items-center top-heading-total-height">
                <div className="ps-2">
                  <p className="trade-text-5 mb-0">Trade</p>
                </div>
              </div>
              <div className="trading-page-top-section-3 eoption-left-new-inner-3-1">
                <div className="trading-table-left-section-eoption progress-bar-bg-color">
                  <div className="d-flex flex-row mb-1 justify-content-between orderbook-table-heading-section border-top-0">
                    <div className="progress-table-1">
                      <span className="orderbook-text-1">Price(USDT)</span>
                    </div>
                    <div className="progress-table-2">
                      <span className="orderbook-text-1">Amount(Cont)</span>
                    </div>
                    <div className="progress-table-3">
                      <span className="orderbook-text-1">Time</span>
                    </div>
                  </div>
                  <ul className="px-0">
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "50%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "30%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "80%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "20%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "90%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="d-flex flex-row mobile-justify-content">
                        <div
                          class="progress-bar bid_bar_imp"
                          style={{ width: "10%" }}
                        ></div>
                        <div class="progress-table-1 color-green">
                          <span class="orderbook-text-2">10.0</span>
                        </div>
                        <div class="progress-table-2">
                          <span class="orderbook-text-2">2.50</span>
                        </div>
                        <div class="progress-table-3">
                          <span class="orderbook-text-2">3.96</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="eoption-left-tabs-5 mt-4">
        <ul class="nav nav-pills mb-3 gap-2" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-option-tab-5-1-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-1"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-1"
              aria-selected="true"
            >
              Position (0)
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-option-tab-5-2-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-2"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-2"
              aria-selected="false"
            >
              Open Orders (0)
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-option-tab-5-3-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-3"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-3"
              aria-selected="false"
            >
              Order History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-option-tab-5-4-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-4"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-4"
              aria-selected="true"
            >
              Trade History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-option-tab-5-5-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-5"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-5"
              aria-selected="false"
            >
              Exercise History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-option-tab-5-6-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-option-tab-5-6"
              type="button"
              role="tab"
              aria-controls="pills-option-tab-5-6"
              aria-selected="false"
            >
              Transaction History
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-option-tab-5-1"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-1-tab"
          >
            <div className="px-2 options-open-orders-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Pair</th>
                    <th>Order Type</th>
                    <th>Side</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Filled</th>
                    <th>Remaining</th>
                    <th>Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  <tr>
                    <th>324.9</th>
                    <th>4</th>
                    <th>sell</th>
                    <th>323</th>
                    <th>1</th>
                    <th>23.657</th>
                    <th>34</th>
                    <th>3423</th>
                    <th>24</th>
                    <th>
                      <button type="button" className="market-trade-button">
                        Cancel
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th>324.9</th>
                    <th>4</th>
                    <th>sell</th>
                    <th>323</th>
                    <th>1</th>
                    <th>23.657</th>
                    <th>34</th>
                    <th>3423</th>
                    <th>24</th>
                    <th>
                      <button type="button" className="market-trade-button">
                        Cancel
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th>324.9</th>
                    <th>4</th>
                    <th>sell</th>
                    <th>323</th>
                    <th>1</th>
                    <th>23.657</th>
                    <th>34</th>
                    <th>3423</th>
                    <th>24</th>
                    <th>
                      <button type="button" className="market-trade-button">
                        Cancel
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-option-tab-5-2"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-2-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="pills-option-tab-5-3"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-3-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="pills-option-tab-5-4"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-4-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="pills-option-tab-5-5"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-5-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="pills-option-tab-5-6"
            role="tabpanel"
            aria-labelledby="pills-option-tab-5-6-tab"
          >
            ...
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import {
    BrowserRouter as Router, useNavigate,
} from "react-router-dom";
import { GoChevronLeft } from 'react-icons/go';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Grid } from '@mui/material';
import $ from "jquery";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { toast } from "../core/lib/toastAlert";
import { dateFormat, shortAdrress } from '../core/helper/date-format';
import { useContextData } from '../core/context/index'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { pageAllowCheck, clickNavigate } from "../core/helper/common";
import "../assets/style.css";
import axios from "axios";
import { Form, FormGroup, Label, Input } from "reactstrap"
import { FaExternalLinkAlt } from "react-icons/fa";
import notFound from "../assets/images/file.png";


const validationSchema = yup.object({
    address: yup
        .string()
        .typeError('Please enter the valid Address')
        .required('Wallet Address Is Required!'),
    amount: yup
        .string()
        .typeError('Please enter the valid amount')
        .required('Amount is required')
    // .test(
    //     "maxDigitsAfterDecimal",
    //     "Please enter the valid amount",
    //     (number) => /^\d+(\.\d{1,2})?$/.test(number)
    // ),
});

export default function WithdrawPi(props) {
    const navigate = useNavigate();
    const { myProfile, siteSettings, ipAddress } = useContextData();
    const [userbalance, setUserBalance] = useState(0);
    const [particularCurrencyList, setparticularCurrencyList] = useState({});
    const [piNetwork, setPiNetwork] = useState('');
    const [currencies, setCurrencies] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [userWithdrawHistory, setUserWithdrawHistory] = useState([]);
    const [withdrawshow, setWithdrawshow] = useState(0);
    const [timer_resendOtp, setTimer_resendOtp] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [showCalculateFee, setShowCalculateFee] = useState(false);
    const [enteredFee, setEnterdFee] = useState(0);
    const [currencyId, setCurrencyId] = useState(null);
    const [levelBasedLimit, setLevelBasedLimit] = useState({});
    const [withdrawLevDet, setWithdrawLevDet] = useState({});
    const [withdrawDet, setWithdrawDet] = useState({});
    const [particularcurrency, set_particularCurrency] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalorders, settotalorders] = useState(0);

    function shortenString(str, maxLength) {
        if (str.length > maxLength) {
            var shortened = str.substring(0, maxLength - 3) + '...';
            var reversed = str.split('').reverse().join('');
            var shortenedReversed = reversed.substring(0, maxLength - 3);
            return shortened + shortenedReversed.split('').reverse().join('');
        } else {
            return str;
        }
    }

    useEffect(() => {
        getHistory(currencyId);
    }, [currentPage, rowsPerPage]);

    const column = [
        {
            id: 0,
            name: "SlNo",
            selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
            width: "70px"
        },
        {
            id: 1,
            name: "Date/Time",
            selector: (row) => dateFormat(row.createdDate)
        },
        {
            id: 2,
            name: "Type",
            selector: (row) => row.type
        },
        {
            id: 3,
            name: "Asset",
            selector: (row) => (<>
                <img src={row.currencyId.image} style={{ height: '23px', width: '23px' }} />&nbsp;<span>PI</span>
            </>)
        },
        {
            id: 4,
            name: "Address",
            selector: (row) => shortenString(row.address, 10)
        },
        {
            id: 5,
            name: "Tnx Id",
            selector: (row) => (
                <>
                    {row.txnId} <span><FaExternalLinkAlt style={{ cursor: "pointer" }} onClick={() => window.open(Config.PI_EXPLORER_URL + row.txnId, "_blank")} /></span>
                </>
            )
        },
        {
            id: 6,
            name: "Received",
            selector: (row) => Number(row.amount).toFixed(row?.currencyId?.decimal)
        },
        {
            id: 7,
            name: "Fees",
            selector: (row) => row.fees
        },
        {
            id: 8,
            name: "Status",
            selector: (row) => row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing'
        },
    ];
    useEffect(() => {
        $(".crypto-workflow-section-close-button").click(function () {
            $(".deposit-second-section").hide();
        });
    }, []);

    useEffect(() => {
        timer_resendOtp > 0 && setTimeout(() => setTimer_resendOtp(timer_resendOtp - 1), 1000);
    }, [timer_resendOtp]);

    useEffect(() => {
        getPiCurrency();
        getparticularCurrency_func();
        initCall();
    }, []);
    useEffect(() => {
        getPiCurrency();
    }, [myProfile]);
    async function initCall() {
        try {
            const params = {
                url: `${Config.V2_API_URL}transaction/withdrawLevelDetail`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status && response.data) {
                setWithdrawLevDet(response.data);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function getPiCurrency() {
        try {
            if (myProfile !== undefined && myProfile !== null && myProfile !== "") {
                const value = { currencySymbol: "PI" }
                const params = {
                    url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                    method: 'POST',
                    body: value
                }
                const response = (await makeRequest(params));
                if (response.status) {
                    setPiNetwork(response.data?._id);
                    setCurrencies(response.data);
                    getPiBalance(response?.data?.currencyId);
                    setCurrencyId(response?.data?.currencyId);
                    set_particularCurrency(response.data);
                    getHistory(response?.data?.currencyId);
                    if (typeof response.data.withdrawLevel == 'object' && typeof response.data.withdrawLevel["level" + (myProfile && myProfile?.level)] == 'object') {
                        const data = response.data.withdrawLevel["level" + (myProfile && myProfile.level)];
                        setWithdrawDet(data);
                    }
                }
            }
        } catch (err) {
            console.log("Something Wrong!", err);
        }
    }
    async function getPiBalance(currencyId) {
        try {
            const value = { currencyId: currencyId }
            const params = {
                url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                method: 'POST',
                body: value
            }
            const result = (await makeRequest(params));
            if (result.status) {
                var amount = result.data.amount;
                setUserBalance(amount)
            }
        } catch (error) {
            console.log("something Went Wrong!");
        }
    }

    async function getparticularCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                body: { currencySymbol: "BNB" }
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                setparticularCurrencyList(response.data);
            }
        } catch (err) { }
    }

    const getHistory = async (piCurrencyId) => {
        try {
            if (piCurrencyId !== null) {
                const value = {
                    type: "Withdraw",
                    currencySymbol: "PI",
                    currencyType: "Pi-Network",
                    limit: rowsPerPage,
                    offset: rowsPerPage * (currentPage - 1)
                }
                const params = {
                    url: `${Config.V1_API_URL}wallet/getHistory`,
                    method: 'POST',
                    body: value
                }
                const response = (await makeRequest(params));
                if (response.status) {
                    const getAllDatas = response.data;
                    if (getAllDatas.length > 0) {
                        const historyData = getAllDatas.filter(data => data.walletCurrencyId == piCurrencyId);
                        setUserWithdrawHistory(historyData);
                        settotalorders(response.total);
                    }
                };
            }
        } catch (err) {
            console.log("something wrong!");
        }
    };

    async function submitWithdraw(values = {}, target = "submit") {
        let type = 'error';
        if (values.amount <= userbalance) {
            let data = {
                currencyId: piNetwork,
                amount: values.amount,
                address: values.address
            }
            if (withdrawshow != 0 && target !== "resendOTP") {
                data.withdrawOTP = values.withdrawOTP ? values.withdrawOTP : "-";
                data.tfaCode = values.tfaCode;
            }
            data.ipDetails = ipAddress;
            if (values?.address.length !== 56) {
                toast({ type: "error", message: "Please Enter Valid Pi Address!" })
                return
            }
            const params = {
                url: `${Config.V1_API_URL}wallet/submitWithdraw`,
                method: 'POST',
                body: data
            }
            setisLoading(true);
            const response = (await makeRequest(params));
            setisLoading(false);
            if (response.status) {
                setWithdrawshow(response.type)
                type = 'success';
                if (response.type == 3) {
                    formik.resetForm();
                    setWithdrawshow(0);
                    getPiCurrency()
                    initCall();
                };
                if (withdrawshow == 0 || target === "resendOTP") {
                    setTimer_resendOtp(Config.timer.resendOtp);
                }
            } else {
                const errmsg = response.message.split(' ');
                if (response.type == 4 && (errmsg[2] == 'TFA' || errmsg[2] == '2FA')) {
                    navigate('/google-authenticator')
                } else if (response.type == 4 && errmsg[2] == 'KYC') {
                    navigate('/my/identification')
                } else if (response.type == 4 && errmsg[2] == 'BANK') {
                    navigate('/my/payment')
                }
            }
            toast({ type, message: response.message });
        }
        else {
            toast({ type, message: 'Insufficient Balance on ' + currencies.currencySymbol });
        }
    };


    const formik = useFormik({
        initialValues: {
            address: "",
            amount: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            submitWithdraw(values);
        },
    });
    useEffect(() => {
        let levelBasedLimit_copy = Object.assign({}, levelBasedLimit);
        if (myProfile && myProfile.level) {
            levelBasedLimit_copy.level = myProfile.level;
            if (withdrawLevDet && withdrawLevDet.totalDailyVolume != undefined) {
                levelBasedLimit_copy.totalDailyVolume = withdrawLevDet.totalDailyVolume;
                levelBasedLimit_copy.totalMonthlyVolume = withdrawLevDet.totalMonthlyVolume;
            }

            if (siteSettings && siteSettings.withdrawLevel) {
                levelBasedLimit_copy.limit = siteSettings.withdrawLevel['level' + myProfile.level];
            }
            setLevelBasedLimit(levelBasedLimit_copy);
        }
    }, [myProfile, siteSettings, withdrawLevDet]);

    useEffect(() => {
        const filteredData = userWithdrawHistory.filter((filterDatas) => {
            if (filterText != "") {
                const tnxId = filterDatas.txnId?.toLowerCase();
                return tnxId.includes(filterText.toLowerCase());
            } else {
                return true;  // This ensures all items are included when filterText is empty
            }
        });
        setFilteredData(filteredData);
    }, [filterText, userWithdrawHistory]);

    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="deposit-page-top-banner">
                <div className="deposit-hero-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6" onClick={() => navigate("/my/dashboard")}>
                                <GoChevronLeft className="deposit-back-button-icon" />
                                <span className="deposit-text-1">Withdraw PI</span>
                            </div>
                            <div className="col-6 text-end" onClick={() => navigate("/withdraw/crypto/" + particularCurrencyList?.currencySymbol)}>
                                <button type="button" className="deposit-fiat-button">
                                    Withdraw Crypto <HiArrowNarrowRight />
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            {withdrawshow == 0 ?
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12">
                                        <div className=" mt-5">
                                            <div className="">
                                                <div className="withdraw-nav-tabs">
                                                    <div className="deposit-third-right-section">
                                                        <span className="deposit-text-33 password-text-44  color-blue">
                                                            Wallet Address
                                                        </span>

                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Address"
                                                            id="address"
                                                            name='address'
                                                            autoComplete='off'
                                                            value={formik.values.address}
                                                            onChange={(e) => {
                                                                const allowedRegex = /^[a-zA-Z0-9]*$/;
                                                                if (allowedRegex.test(e.target.value)) {
                                                                    formik.handleChange(e)
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                                            helperText={formik.touched.address && formik.errors.address}
                                                        />
                                                        {formik.errors.address ? <small className="invalid-amount error">{formik.errors.address}</small> : null}

                                                    </div>
                                                    <div className="deposit-third-right-section my-4">
                                                        <span className="deposit-text-33 password-text-44  color-blue">Enter Amount</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Amount"
                                                            id="amount"
                                                            name='amount'
                                                            autoComplete='off'
                                                            value={formik.values.amount}
                                                            onChange={(e) => {
                                                                const piFee = withdrawDet?.fees.toFixed(currencies?.siteDecimal);
                                                                const regex = /^[0-9.]*$/;
                                                                const amount = e.target.value;
                                                                const isTrue = regex.test(amount);
                                                                const hasMultipleDots = (amount.match(/\./g) || []).length > 1;
                                                                if (isTrue && !hasMultipleDots) {
                                                                    formik.handleChange(e);
                                                                    setShowCalculateFee(true);
                                                                    const totalFee = (piFee / 100) * amount;
                                                                    setEnterdFee(totalFee)
                                                                }
                                                                if (isTrue && amount == 0) {
                                                                    setShowCalculateFee(false);
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                            helperText={formik.touched.amount && formik.errors.amount}
                                                        />
                                                        {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                                                    </div>
                                                    <button type="submit" className="btn text-white btn-col w-100 my-4 alt-submit-txt-btn" disabled={isLoading}>
                                                        Submit Withdraw Request
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                :
                                <form onSubmit={formik.handleSubmit} className="">
                                    <div className="col-lg-12">
                                        <div className="d-flex flex-lg-row flex-column mt-5">
                                            <div className="">
                                                {/* <span className="deposit-text-3">Send to</span> */}
                                            </div>
                                            <div className="">
                                                <div className="withdraw-nav-tabs">
                                                    <div className="deposit-third-right-section mb-4">
                                                        <div className="row">
                                                            <span className="deposit-text-33">Enter E-mail OTP</span>
                                                            <div className="input-group">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="withdrawOTP"
                                                                    name='withdrawOTP'
                                                                    autoComplete='off'
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        value = value.replace(/[^0-9]/g, '');
                                                                        e.target.value = value;
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.withdrawOTP}
                                                                    error={formik.touched.withdrawOTP && Boolean(formik.errors.withdrawOTP)}
                                                                    helperText={formik.touched.withdrawOTP && formik.errors.withdrawOTP}
                                                                    required
                                                                />
                                                                <button
                                                                    id="button-addon2"
                                                                    className="btn btn-phone-number-verification-code"
                                                                    type="button"
                                                                    disabled={(isLoading || timer_resendOtp > 0) ? true : false}
                                                                    onClick={() => {
                                                                        submitWithdraw(formik.values, "resendOTP");
                                                                    }}
                                                                >
                                                                    Resend OTP{timer_resendOtp > 0 ? <span className="timeLeft">({timer_resendOtp}s)</span> : ""}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="deposit-third-right-section mb-4">
                                                        <span className="deposit-text-33">Enter 2FA Code</span>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="tfaCode"
                                                            name='tfaCode'
                                                            autoComplete='off'
                                                            onChange={(e) => {
                                                                let value = e.target.value;
                                                                value = value.replace(/[^0-9]/g, '');
                                                                e.target.value = value;
                                                                formik.handleChange(e);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.tfaCode}
                                                            error={formik.touched.tfaCode && Boolean(formik.errors.tfaCode)}
                                                            helperText={formik.touched.tfaCode && formik.errors.tfaCode}
                                                            required
                                                        />
                                                    </div>
                                                    <button type="submit" disabled={isLoading} className="btn text-white btn-col w-100 my-4 alt-submit-txt-btn">
                                                        Withdraw amount
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                            <div className="row mt-4">
                                <div className="col-6">
                                    <span className="deposit-text-5">{currencies && currencies.currencySymbol} balance</span>
                                    <br />
                                    <span className="deposit-text-66">{userbalance?.toFixed(currencies?.siteDecimal)} {currencies?.currencySymbol}</span>
                                </div>
                                <div className="col-6">
                                    <span className="deposit-text-5">
                                        Minimum withdrawal
                                    </span>
                                    <br />
                                    <span className="deposit-text-66">{withdrawDet.minwithamt ? parseFloat(withdrawDet.minwithamt?.toFixed(currencies.siteDecimal)) : "--"} {currencies && currencies.currencySymbol}</span>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {
                                    levelBasedLimit && levelBasedLimit.totalDailyVolume != undefined && levelBasedLimit.limit && levelBasedLimit.limit?.dailyVolume != undefined ?
                                        <div className="col-6">
                                            <span className="deposit-text-5">
                                                24h remaining limit
                                            </span>
                                            <br />
                                            <span className="deposit-text-66">
                                                {
                                                    levelBasedLimit.limit.dailyVolume == 0
                                                        ?
                                                        "No limit"
                                                        :
                                                        (levelBasedLimit.limit.dailyVolume - levelBasedLimit.totalDailyVolume)?.toFixed(2) + " USD/" + levelBasedLimit.limit.dailyVolume + " USD"
                                                }
                                            </span>
                                        </div> : ""
                                }

                                {
                                    levelBasedLimit && levelBasedLimit.totalMonthlyVolume != undefined && levelBasedLimit.limit && levelBasedLimit.limit.monthlyVolume != undefined
                                        ?
                                        <div className="col-6">
                                            <span className="deposit-text-5">
                                                Monthly remaining limit
                                            </span>
                                            <br />
                                            <span className="deposit-text-66">
                                                {
                                                    levelBasedLimit.limit.monthlyVolume == 0
                                                        ?
                                                        "No limit"
                                                        :
                                                        (levelBasedLimit.limit.monthlyVolume - levelBasedLimit.totalMonthlyVolume)?.toFixed(2) + " USD/" + levelBasedLimit.limit.monthlyVolume + " USD"
                                                }
                                            </span>
                                        </div> : ""
                                }
                            </div>

                            <div className="row mt-3">
                                <div className="col-6">
                                    <span className="deposit-text-5">Network fee</span>
                                    <br />
                                    <span className="deposit-text-66">
                                        {
                                            (withdrawDet && withdrawDet.fees) ?
                                                withdrawDet.feetype === "0" ?
                                                    parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                                                    :
                                                    parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                                                :
                                                0

                                        }
                                        {
                                            withdrawDet.feetype === "0" ?
                                                "INR"
                                                :
                                                withdrawDet.feetype === "1" ?
                                                    "%" : ""
                                        }
                                    </span>
                                </div>

                                <div className="col-6">
                                    <span className="deposit-text-5">Network fee (-) from amount</span>
                                    <br />
                                    <span className="deposit-text-66">
                                        {!showCalculateFee ?
                                            (withdrawDet && withdrawDet.fees) ?
                                                withdrawDet.feetype === "0" ?
                                                    parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                                                    :
                                                    parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                                                :
                                                0
                                            : enteredFee?.toFixed(currencies?.siteDecimal) + "PI"
                                        }
                                        {!showCalculateFee ?
                                            withdrawDet.feetype === "0" ?
                                                "INR"
                                                :
                                                withdrawDet.feetype === "1" ?
                                                    "%" : ""
                                            : null
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {
                                particularcurrency.withdrawNotes && particularcurrency.withdrawNotes != "NA" ?
                                    <div className="row mt-3">
                                        <div className="col-lg-5">
                                            <span className="deposit-text-5">
                                                Notes
                                            </span>
                                            <br />
                                            <span className="deposit-text-66">
                                                {particularcurrency.withdrawNotes}
                                            </span>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="col-lg-5 mt-5">
                                <Grid item xs={12} sm={6} className="text-left-move">
                                    <div>
                                        <p className='color-blue password-text-44'>The amount would be transferred to
                                        </p>
                                        <p className='password-text-33'>
                                            Enter the right wallet address to withdraw the funds to which the Pi amount will be transferred.                                    </p>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className=" deposit-fourth-section mb-5">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <h1 className="deposit-text-11 color-blue">Recent Withdraw</h1>
                                <div className="col-lg-5 mt-3">
                                    <Form>
                                        <FormGroup>
                                            <Label for="exampleSelect">Search</Label>
                                            <Input
                                                id="search"
                                                type="text"
                                                placeholder="Filter By Transaction id"
                                                aria-label="Search Input"
                                                value={filterText}
                                                onChange={(e) => setFilterText(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Form>
                                </div>
                                <DataTable
                                    columns={column}
                                    data={filteredData}
                                    persistTableHead
                                    pagination
                                    paginationServer
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 15]}
                                    paginationTotalRows={totalorders}
                                    onChangeRowsPerPage={newRowsPerPage => {
                                        setRowsPerPage(newRowsPerPage);
                                        setCurrentPage(1); // Reset to first page when rows per page changes
                                    }}
                                    onChangePage={page => setCurrentPage(page)}
                                    noDataComponent={
                                        <div className="text-center">
                                            <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                                            <br />
                                            <span className="text-center">No Records Found</span>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

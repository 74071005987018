import React, { useState, useEffect } from "react";
import "../assets/style.css";
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import Kycverification from "../assets/images/icons/icon2/kyc-verification.png";
import Nohiddenfees from "../assets/images/icons/icon2/no-hidden-fees.png";
import Nomonthlycharges from "../assets/images/icons/icon2/no-monthly-charges.png";
import Zerosetupcosts from "../assets/images/icons/icon2/zero-setup-costs.png";
import Digitalasset from "../assets/images/icons/icon2/2/blockchain.png";
import Getxwallets from "../assets/images/icons/icon2/2/wallet.png";
import Withdrawalanddeposit from "../assets/images/icons/icon2/2/finance.png";
import {
    useNavigate
} from "react-router-dom";
import { TbHexagonNumber1, TbHexagonNumber2, TbHexagonNumber3 } from "react-icons/tb";




export default function Fees(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    async function backButton() {
        try {
            const length = window.history.length;
            if (length > 1) {
                window.history.back();
            } else {
                navigate("/");
            }
        } catch (err) { }
    }

    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="getx-new-fees-csssabv">
                <div className="deposit-page-top-banner">
                    <div className="deposit-hero-section">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col">
                                    <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} />
                                    <span className="deposit-text-1">Fees</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="getx-new-fees-section-1">
                    <div className="container py-5 ">
                        <div className="row align-items-center justify-content-between pb-5 g-4">
                            <div className="col-lg-12">
                                <h1 className="mb-3" style={{ fontWeight: "100" }}>Fees</h1>
                                <h3 className="banner-text-7 mb-0 w-75">GetX is your #1 choice for buying and selling cryptocurrencies at low fees</h3>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between g-4 getx-new-fees-section-1-1">
                            <div className="col-lg-3 col-md-6 col-sec-fee1">
                                <img src={Zerosetupcosts} className="mb-3" alt="Zero-Setup-Costs" style={{ width: "90px", height: "auto" }} />
                                <h4 className="banner-text-7 fw-bold">Zero Setup Costs</h4>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sec-fee1">
                                <img src={Nomonthlycharges} className="mb-3" alt="Zero-Setup-Costs" style={{ width: "90px", height: "auto" }} />
                                <h4 className="banner-text-7 fw-bold">No Monthly Charges</h4>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sec-fee1">
                                <img src={Kycverification} className="mb-3" alt="Zero-Setup-Costs" style={{ width: "90px", height: "auto" }} />
                                <h4 className="banner-text-7 fw-bold">No KYC Fees</h4>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sec-fee1">
                                <img src={Nohiddenfees} className="mb-3" alt="Zero-Setup-Costs" style={{ width: "90px", height: "auto" }} />
                                <h4 className="banner-text-7 fw-bold">No Hidden Fees</h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="getx-new-fees-section-2 py-5">
                    <div className="container ">
                        <div className="row align-items-center justify-content-between g-4">
                            <div className="col-lg-12">
                                <TbHexagonNumber1 className="banner-text-3 mb-2" style={{color:"var(--btnClr)"}} />
                                <h3>Tier - 1 Fees</h3>
                                <p className="mb-4 col-lg-8">For sub-$100K volumes, GetX exchange offers low fees: 0.3% maker, 0.1% taker. Paying with GetX coins? Fees drop to 0.15% for the maker, and 0.05% for a taker.</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Tier</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Maker Fee</th>
                                            <th scope="col">Taker Fee</th>
                                            <th scope="col">Maker Fee (Fee in GETX)</th>
                                            <th scope="col">Taker Fee (Fee in GETX)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Tier">Tier 1</td>
                                            <td data-label="Volume">&lt; 1,00,000 USD</td>
                                            <td data-label="Maker Fee">0.3%</td>
                                            <td data-label="Taker Fee">0.1%</td>
                                            <td data-label="Maker Fee (Fee in GETX)">0.15%</td>
                                            <td data-label="Taker Fee (Fee in GETX)">0.05%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <TbHexagonNumber2 className="banner-text-3 mb-2" style={{color:"var(--btnClr)"}} />
                                <h3>Tier - 2 Fees</h3>
                                <p className="mb-4 col-lg-8">Trade $100K-$500K on GetX: 0.2% maker, 0.08% taker fees. Pay with GetX coins for 0.1% maker, and 0.04% taker fees.</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Tier</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Maker Fee</th>
                                            <th scope="col">Taker Fee</th>
                                            <th scope="col">Maker Fee (Fee in GETX)</th>
                                            <th scope="col">Taker Fee (Fee in GETX)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Tier">Tier 2</td>
                                            <td data-label="Volume">1,00,000 -5,00,000 USD</td>
                                            <td data-label="Maker Fee">0.2%</td>
                                            <td data-label="Taker Fee">0.08%</td>
                                            <td data-label="Maker Fee (Fee in GETX)">0.1%</td>
                                            <td data-label="Taker Fee (Fee in GETX)">0.04%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <TbHexagonNumber3 className="banner-text-3 mb-2" style={{color:"var(--btnClr)"}} />
                                <h3>Tier - 3 Fees</h3>
                                <p className="mb-4 col-lg-8">For volumes over $500K, GetX charges 0.1% maker and 0.05% taker. Paying with GetX coins charges are only 0.05% maker and 0.03% taker.</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Tier</th>
                                            <th scope="col">Volume</th>
                                            <th scope="col">Maker Fee</th>
                                            <th scope="col">Taker Fee</th>
                                            <th scope="col">Maker Fee (Fee in GETX)</th>
                                            <th scope="col">Taker Fee (Fee in GETX)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Tier">Tier 3</td>
                                            <td data-label="Volume">&gt;= 5,00,000 USD</td>
                                            <td data-label="Maker Fee">0.1%</td>
                                            <td data-label="Taker Fee">0.05%</td>
                                            <td data-label="Maker Fee (Fee in GETX)">0.05%</td>
                                            <td data-label="Taker Fee (Fee in GETX)">0.03%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="getx-new-fees-section-3 py-5">
                    <div className="container">
                        <h2 className="mb-5">Essential Guidelines</h2>
                        <div class="row row-cols-1 row-cols-md-3">
                            <div class="col getx-new-fees-section-3-card-1">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img src={Digitalasset} alt="Guidelines" />
                                        <p class="card-text">Starting July 1, 2022, 1% TDS (5% in exceptions) on trade value is mandatory for Virtual Digital Asset transfers. Claimable in ITR. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col getx-new-fees-section-3-card-2">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img src={Withdrawalanddeposit} alt="Guidelines" />
                                        <p class="card-text">Withdrawal and deposit fees are conveniently detailed on a separate page for your ease.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col getx-new-fees-section-3-card-3">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <img src={Getxwallets} alt="Guidelines" />
                                        <p class="card-text">No fees for adding money to GetX Wallets, only minimal transaction fees from your banking service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <Footer />
        </div>
    );
}

// import { userLoginChk } from "../../../core/helper/cookie";
import { makeRequest } from "../../../core/services/v1/request";
import Config from '../../../core/config/index';
const getUsdtBalance = async () => {
        const params = {
            method: "GET",
            url: `${Config.LAUNCHPAD_V2_API_URL}launchPad/currency/getWalletCurrency`
        }
        const response = await makeRequest(params);
        if (response.data) {
            let userBalance = response.data.msg
            return userBalance;
        }
}

export default {
    getUsdtBalance
}
import React, { useState, useEffect } from "react";
import "../../assets/style.css";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { BiCaretDown } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useContextData } from "../../core/context/index";
import axios from "axios";
import Config from "../../core/config/index";
import moment from "moment";
import { toast } from "../../core/lib/toastAlert";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { makeRequest } from "../../core/services/v1/request";

export default function Simpleearn(props) {
  const navigate = useNavigate();
  const { myProfile } = useContextData();
  const [userstakehistory, setuserstakehistory] = useState([]);
  const [redeemearlyimage, setredeemearlyimage] = useState("");
  const [redeemearlycurrency, setredeemearlycurrency] = useState("");
  const [totalearnprofit, settotalearnprofit] = useState("0");
  const [userenterearn, setuserenterearn] = useState("0");
  const [userdailyprofit, setuserdailyprofit] = useState("0");
  const [redeemamtuser, setredeemamtuser] = useState("0");
  const [tableRowData, setTableRowData] = useState({})
  const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
  const [earnType, setEarnType] = useState("Fixed");
  const [flexibleTotalReward, setFlexibleTotalReward] = useState(0);
  const [fixedTotalReward, setFixedTotalReward] = useState(0)
  const [usdValues, setUsdValues] = useState({})
  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };

  const columns = [
    {
      id: 0,
      name: "coin",
      selector: (row) => (
        <div className="">
          <img className="simpleearn-table-coin-img" src={row.currencyImage} />
        </div>
      ),
      sortable: false,
      reorder: true,
      width: "70px",
    },
    {
      id: 1,
      name: "Asset",
      selector: (row) => row.currencysymbol,
      sortable: true,
      reorder: true,
      width: "200px",
    },
    {
      id: 2,
      name: "Total Amount",
      selector: (row) => {
        return `${row.investAmount} ${row.currencysymbol}`;
      },
      sortable: true,
      reorder: true,
      width: "200px",
    },
    {
      id: 3,
      name: "Subscription Date",
      selector: (row) => moment(row.startdate).format("YYYY-MM-DD HH:mm"),
      sortable: true,
      reorder: true,
      width: "200px",
    },
    {
      id: 4,
      name: "Est. APR",
      selector: (row) => row.aprPercentage,
      sortable: true,
      reorder: true,
      width: "200px",
    },
    {
      id: 5,
      name: "Locked Period(Days)",
      selector: (row) => earnType === "Fixed" ? row.lockedDays : "∞",
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: 6,
      name: "Cumulative Real-Time APR Rewards",
      selector: (row) => {
        const currentDate = new Date;
        const time1 = currentDate?.getTime();
        const startDate = new Date(row.startdate).getTime();
        const differentedDays = (time1 - startDate) / 86400000;
        const totalReward = (row.aprPercentage / 100) * row.investAmount
        var oneDayReward = totalReward / row.lockedDays;
        const originalDays = parseInt(differentedDays)
        if (differentedDays >= 1) {
          return oneDayReward * originalDays
        } else {
          return 0
        }
      },
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: 7,
      name: "Redemption Date",
      selector: (row) => earnType === "Fixed" ? moment(row.expirationDate).format("YYYY-MM-DD HH:mm") : "∞",
      sortable: true,
      reorder: true,
      width: "300px",
    },
    {
      id: 8,
      name: "USD Value",
      selector: (row) => usdValues[row.currencyId]?.USDvalue * (row.investAmount) || '',
      sortable: true,
      reorder: true,
      width: "200px",
    },
    {
      id: 9,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="bg-transparent border-0 simpleearn-redeem"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => redeemfunction(row)}
          >
            Redeem
          </button>
        </div>
      ),
      sortable: true,
      reorder: true,
      width: "200px",
    },
  ];

  const redeemfunction = async (rewardDatas) => {
    setTableRowData(rewardDatas);
    setredeemearlyimage(rewardDatas.currencyImage);
    setredeemamtuser(rewardDatas.investAmount);
    setredeemearlycurrency(rewardDatas.currencysymbol);
    const currentDate = new Date;
    const time1 = currentDate?.getTime();
    const startDate = new Date(rewardDatas.startdate).getTime();
    const differentedDays = (time1 - startDate) / 86400000;
    const currectDays = parseInt(differentedDays)
    const lockedDays = rewardDatas.lockedDays;
    const remainingDays = lockedDays - differentedDays;
    if (earnType === "Fixed") {
      if (currectDays >= lockedDays) {
        const investAmount = rewardDatas.investAmount;
        const reward = (rewardDatas.aprPercentage / 100) * investAmount;
        const totalReward = investAmount + reward;
        setFixedTotalReward(totalReward);
      } else {
        setFixedTotalReward(rewardDatas.investAmount)
        toast({ type: "success", message: `You Can Claim You'r Reward After ${parseInt(remainingDays)} Day!` });
      }
    } else {
      if (differentedDays >= 1) {
        const currectDays = parseInt(differentedDays);
        const investAmount = rewardDatas.investAmount;
        const reward = (rewardDatas.aprPercentage / 100) * investAmount;
        const totalReward = currectDays * reward;
        setFlexibleTotalReward(rewardDatas.investAmount + totalReward)
      } else {
        setFlexibleTotalReward(rewardDatas.investAmount);
        toast({ type: "success", message: "You Can Claim You'r Reward After One Day!" });
      }
    }
  };

  const redeemtotalamtclick = async () => {
    try {
      const redeemData = {
        stackId: tableRowData._id,
        userId: tableRowData.userId,
        currencyId: tableRowData.currencyId,
        amount: earnType === "Fixed" ? fixedTotalReward : flexibleTotalReward
      };
      const params = {
        url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/redeemStackReward`,
        method: "post",
        data: redeemData
      }
      const res = await makeRequest(params);
      console.log("res", res);
      if (res.status) {
        toast({ type: "success", message: res.message });
        setTimeout(() => window.location.href = "/account-earn", 3000)
      } else {
        toast({ type: "error", message: "Something Went Wroung!" })
      }
    } catch (error) {
      toast({ type: "error", message: "Something Went Wroung!" })
    }
  };

  const cancelredeemclick = async () => {
    navigate("/account-earn");
    window.location.reload();
  };

  const getmyhistory = async () => {
    const userids = myProfile?._id;
    const userdetails = {
      userId: userids,
      type: earnType
    };
    const getuserbal = await axios.post(
      `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getuserstakehistory`,
      userdetails
    );
    const getuserstake = getuserbal.data?.data;

    let activeTable = await getuserstake?.filter(
      (activeData) => activeData.simpleEarnstatus == 0);
    if (activeTable?.length > 0) {
      setuserstakehistory(activeTable);
    }
  };
  const getUsdValues = async () => {
    try {
      const params = {
        url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getWalletCurrency`,
        method: "post",
      }
      const res = await makeRequest(params);
      if (res.status) {
        const rowData = res.data;
        if (Array.isArray(rowData)) {
          rowData.forEach((row) => {
            setUsdValues((prev) => ({
              ...prev,
              [row.currencyId]: row
            }));
          });
        }
      } else {
        return setUsdValues({})
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
  useEffect(() => {
    getmyhistory();
    getUsdValues()
  }, [myProfile, earnType]);

  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="accountearn-top-banner-section">
        <div className="accountearn-top-value-section">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div class="d-flex flex-lg-row flex-column">
                  <div>
                    <p className="accountearn-text-1 mb-0">Earn</p>
                  </div>
                  <div class="ms-auto">
                    <button
                      type="button"
                      className="border-0 accountearn-all-assest"
                      onClick={() => navigate("/simple-earn-history")}
                    >
                      History
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accountearn-top-value-section-2 border-bottom">
          <div className="container-fluid col-lg-10 mx-auto ">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <p className="accountearn-text-2 mb-2">
                  Est. Total Value{" "}
                  <button className="bg-transparent border-0">
                    {balShow ? (
                      <AiFillEye onClick={() => balShowHideCall()} />
                    ) : (
                      <AiFillEyeInvisible onClick={() => balShowHideCall()} />
                    )}
                  </button>
                </p>
                {balShow ? (
                  <span className="accountearn-text-3 mb-0">
                    {(Number(userenterearn) +
                      Number(totalearnprofit) +
                      Number(userdailyprofit)).toFixed(8)}
                    usd{" "}
                  </span>
                ) : (
                  <p>***Balance hidden***</p>
                )}

                <a className="accountearn-all-assest ms-3" href="/my/wallet">View All Assest</a>
              </div>
            </div>
          </div>
        </div>

        <div className="accountearn-top-value-section-3">
          <div className="container-fluid col-lg-10 mx-auto">
            <div className="row align-items-center">
              <div className="col-lg-12 accountearn-staking-tabs-section">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <Link to="/simple-earn" className="nav-link active" id="pills-earn-staking-1-tab" role="tab" aria-controls="pills-earn-staking-1" aria-selected="true">Simple Earn</Link>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-earn-staking-1"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-1-tab"
                    tabindex="0"
                  >
                    <div className="row py-3">
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Estimated Positions</p>
                        <p className="mb-0 accountearn-text-4">
                          {userenterearn} USD
                        </p>
                      </div>
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Total Profit</p>
                        <p className="mb-0 accountearn-text-4">
                          {totalearnprofit} USD
                        </p>
                      </div>
                      <div className="col-lg-3 col-6">
                        <p className="mb-1 mt-3 fs-12">Last Day Profit</p>
                        <p className="mb-0 accountearn-text-4">
                          {userdailyprofit} USD
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-2"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-2-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-3"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-3-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-4"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-4-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-5"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-5-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-6"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-6-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-earn-staking-7"
                    role="tabpanel"
                    aria-labelledby="pills-earn-staking-7-tab"
                    tabindex="0"
                  >
                    ...
                  </div>
                </div>
              </div>

              <div className="col-lg-12 accountearn-staking-table-tabs-section mt-3">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-earn-staking-table-1-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-earn-staking-table-2"
                      type="button"
                      role="tab"
                      aria-controls="pills-earn-staking-table-2"
                      aria-selected="false"
                      onClick={() => {
                        setuserstakehistory([]);
                        getmyhistory();
                        setEarnType("Fixed");
                      }}
                    >
                      Fixed
                    </button>
                  </li>&nbsp;&nbsp;&nbsp;
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" onClick={() => {
                      setuserstakehistory([]);
                      getmyhistory();
                      setEarnType("Flexible");
                    }}>
                      Flexible
                    </button>
                  </li>
                </ul>

                <div className="my-4">
                  <DataTable
                    keyField="id"
                    columns={columns}
                    data={userstakehistory}
                    sortIcon={<BiCaretDown />}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Redeem {redeemearlycurrency}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex flex-row align-items-end">
                <p className="mb-1 simpleearn-text-11">Redemption Amount</p>
                <p className="fs-10 mb-1 ms-auto">
                  Available for Redemption :
                  <span className="text-muted">
                    {earnType === "Fixed" ? (
                      <>
                        {redeemamtuser} {redeemearlycurrency}
                      </>
                    ) : (
                      <>
                        {flexibleTotalReward?.toFixed(2)} {redeemearlycurrency}
                      </>
                    )}
                  </span>
                </p>
              </div>

              <div class="simpleearn-subscription-amount input-group mb-0">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Amount"
                  aria-label="Enter Amount"
                  aria-describedby="basic-addon2"
                  // onChange={(e) => enterreddemamt(e)}
                  value={earnType === "Fixed" ? fixedTotalReward : flexibleTotalReward}
                  disabled
                />
                <span
                  class="input-group-text subscription-amount-group-2"
                  id="basic-addon2"
                >
                  <img
                    className="simpleearn-subscribe-img"
                    src={redeemearlyimage}
                  />
                  <span className="ms-1 fs-14">{redeemearlycurrency}</span>
                </span>
              </div>

              <p className="fs-12 my-3">
                A maximum of 20000000 {redeemearlycurrency} can be redeemed
                daily. Once redeemed, you will receive the proceeds on the same
                day.
              </p>
              <p className="fs-10 text-muted">
                * Holdings that are currently accruing rewards will be redeemed
                first.
              </p>

              <div class="form-check mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label fs-12" for="flexCheckDefault">
                  I have read and agreed to{" "}
                  <span style={{ color: "#00dbae" }}>
                    Binance Simple Earn Service Agreement
                  </span>
                </label>
              </div>

              <div className="row g-3 mt-2 mb-3">
                <div className="col-lg-6">
                  <div class="d-grid">
                    <button
                      class="accountearn-redeem-1"
                      type="button"
                      onClick={() => cancelredeemclick()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="d-grid">
                    <button
                      class="accountearn-redeem-1"
                      type="button"
                      onClick={() => redeemtotalamtclick()}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

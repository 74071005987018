import React, { useState, useEffect } from "react";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import "../assets/style.css";
import ima from '../assets/images/s.png'
import { FaTelegram } from "react-icons/fa";
import { RiLinkedinBoxFill } from "react-icons/ri";
import { MdMessage } from "react-icons/md";
import Avatar from '../assets/images/Avatar-1.png'
import Config from '../core/config/index';
import { makeRequest } from "../core/services/v1/request";


export default function AuthenticityCheck(props) {

  const [socialMediaLinks, setSocialMediaLinks] = useState({});

  useEffect(() => {
    getSiteSettings();
  }, [])

  async function getSiteSettings() {
    const params = {
      url: `${Config.V1_API_URL}common/siteSettings`,
      method: 'GET',
    }
    const response = (await makeRequest(params));
    if (response.status) {
      setSocialMediaLinks(response.message);
    } else {
      setSocialMediaLinks({});
    }
  }

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="custom-autenticator">
          <div className="container">
            <div className="row min-vh-100">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  <h2 className="text-center fw-bold text-white" style={{ letterSpacing: "1px" }}>Authenticity Check & Authorized Payment Personnel</h2>
                  <div className="col-lg-10 authenticator-1">
                    <div className="">
                      <div className="inside-left-authenticator">
                        <h2 className="exp-getx-ctn">The Expert Team Behind Getx</h2>
                        <p>The Getx Exchange Team comprises well-trained professionals with extensive experience in the crypto industry. Our primary objective is to offer investors a superior platform, token listing services, and substantial user benefits. We believe in the power of partnerships to bring success within the crypto community.
                        </p>
                        <p className="reach-sub-ctn mb-auto">Reach out to us for :</p>
                        <p className="reach-sub-ctn mb-auto">Token Listing, Business Enquiries, Partnerships</p>
                        <div>
                          <button className="authenticity-button">Authenticity Check</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-body-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="custom-body-inside">
                      <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="nav-link active ggggggggg" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Token Listing</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link ggggggggg" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Business Enquiries</button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="nav-link ggggggggg" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Partnerships</button>
                        </li>
                      </ul>
                      <div className="disclaimer-1">

                        <div className="pb-0 d-flex flex-row">

                          <div>
                            <img src={ima} alt="Disclaimer" className="d-lg-block d-none"></img>
                          </div>
                          <div>
                            <span><img src={ima} alt="Disclaimer" className="d-lg-none"></img>Disclaimer:</span> Kindly avoid contacting unofficial or unverified sources or disclosing your account information. All payments to Getx for services must undergo supervision by our payment authorization team. For inquiries, reach out to Support.
                          </div>
                        </div>
                      </div>
                      <p className="global-logo">Global</p>

                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                          <div className="custom-inside-nav-1">
                            <div className="row justify-content-center row-cols-2 row-cols-md-2 row-cols-lg-4">
                              <div className="col">
                                <div className="custom-inside-box card">
                                  <img src={Avatar} alt="Avatar"></img>
                                  <h4>GetX Representative</h4>
                                  {/* <h6>ASSOCIATE DIRECTOR</h6> */}
                                  <div className="custom-icons-1">
                                    <a href={Config.GETX_REPRESENTATIVE_TELEGRAM_PROFILE} target="blank" rel="noopener noreferrer"><FaTelegram className="social-icon-1" /></a>
                                    <a href={Config.GETX_REPRESENTATIVE_LINKEDIN_PROFILE} target="blank" rel="noopener noreferrer"><RiLinkedinBoxFill className="social-icon-1" /></a>
                                    <a href={socialMediaLinks?.mediumLink} target="blank" rel="noopener noreferrer"><MdMessage className="social-icon-1" /></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                          <div className="custom-inside-nav-1">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
                              <div className="col">
                                <div className="custom-inside-box card">
                                  <img src={Avatar} alt="Avatar"></img>
                                  <h4>GetX Representative</h4>
                                  <div className="custom-icons-1">
                                    <a href={Config.GETX_REPRESENTATIVE_TELEGRAM_PROFILE} target="blank" rel="noopener noreferrer"><FaTelegram className="social-icon-1" /></a>
                                    <a href={Config.GETX_REPRESENTATIVE_LINKEDIN_PROFILE} target="blank" rel="noopener noreferrer"><RiLinkedinBoxFill className="social-icon-1" /></a>
                                    <a href={socialMediaLinks?.mediumLink} target="blank" rel="noopener noreferrer"><MdMessage className="social-icon-1" /></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                          <div className="custom-inside-nav-1">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
                              <div className="col">
                                <div className="custom-inside-box card">
                                  <img src={Avatar} alt="Avatar"></img>
                                  <h4>GetX Representative</h4>
                                  <div className="custom-icons-1">
                                    <a href={Config.GETX_REPRESENTATIVE_TELEGRAM_PROFILE} target="blank" rel="noopener noreferrer"><FaTelegram className="social-icon-1" /></a>
                                    <a href={Config.GETX_REPRESENTATIVE_LINKEDIN_PROFILE} target="blank" rel="noopener noreferrer"><RiLinkedinBoxFill className="social-icon-1" /></a>
                                    <a href={socialMediaLinks?.mediumLink} target="blank" rel="noopener noreferrer"><MdMessage className="social-icon-1" /></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import notFound from "../../assets/images/file.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config/";
import { dateFormat, shortenString, reasonString } from '../../core/helper/date-format';
import $ from "jquery";
import Pagination from 'react-responsive-pagination';
import '../../pagination.css';
import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

export default function AdminTransfer(props) {
    const [transactionList, setTransactionList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalOrders, setTotalOrders] = useState(0);
    const [isOpen, setIsOpen] = useState(false)
    const [reasonContent, setReasonContent] = useState("")
    useEffect(() => {
        $(".status_change .dropdown-item").click(function () {
            const generateStatusClass = `${$(this).attr("data-class")}-status`;
            $(this)
                .closest(".status_dropdown")
                .attr("data-color", `${generateStatusClass}`);
        });
    }, []);

    useEffect(() => {
        getHistory();
    }, [currentPage, rowsPerPage]);

    async function getHistory() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getAdminTransferAmount`,
                method: 'POST',
                data: {
                    limit: rowsPerPage,
                    page: currentPage
                }
            };
            const response = await makeRequest(params);
            if (response.status) {
                setTransactionList(response.data);
                setTotalOrders(response.total);
            }
        } catch (err) {
            console.log("Something Wrong!");
        }
    }

    const columns = [
        {
            name: "Sl.No",
            selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
            width: "70px",
        },
        {
            name: "Date",
            selector: row => dateFormat(row.dateTime),
            sortable: true,
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
        },
        {
            name: "Asset",
            selector: row => row.currencyDet.currencySymbol,
            sortable: true,
        },
        {
            name: "Currency Type",
            selector: row => row.currencyDet.currencySymbol == "INR" ? "Fiat" : row.currencyDet.currencySymbol == "EUR" ? "Fiat" : "Crypto",
            sortable: true,
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: "From",
            selector: row => "Admin Wallet",
            sortable: true,
        },
        {
            name: "To",
            selector: row => row.walletType,
            sortable: true,
        },
        {
            name: "Reason",
            selector: row => <span className="cursor-poniter" onClick={() => {
                setIsOpen(true);
                setReasonContent(row.reason)

            }}>{reasonString(row.reason, 20)}</span>,
            sortable: true,
        }
    ];

    return (
        <div className="container deposit-fourth-section">
            <Modal show={isOpen} onHide={() => {
                setIsOpen(false);
                setReasonContent("");

            }} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Admin Transfer Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="disply-flex">
                        <div className="justify-content-center">
                            {reasonContent}
                        </div>
                    </div>
                    <div className="mt-3">
                        <button className="popup-modal-button-my-orders capitalizeText py-2 px-0" onClick={() => setIsOpen(false)}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <DataTable
                        columns={columns}
                        data={transactionList}
                        persistTableHead
                        pagination
                        paginationServer
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        paginationTotalRows={totalOrders}
                        onChangeRowsPerPage={newRowsPerPage => {
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(1);
                        }}
                        onChangePage={page => setCurrentPage(page)}
                        noDataComponent={
                            <div className="text-center py-2">
                                <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                                <br />
                                <span className="text-center">No Records Found</span>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

import React from 'react';
// import NavbarOne from "./siteTheme/NavbarOne";
import error from "../assets/images/4041.png";
import "../assets/styledev.css";

export default function Error404Content() {
  return (
    <center className='error-overral-section'>
      <div className="error-section container-fluid">
        <div className="row min-vh-100 full-error-page-clr">
          <div className="col-lg-12 mt-5 pt-2">
            {/* <h1 className='my-2 alt-error-ctn-01'>404</h1> */}
            <img src={error} alt="comming soon" className='img-fluid-css' />

            <p className='text-white alt-error-new-content-01'>Sorry! The page you’re looking for cannot be found.</p>
            <p className='text-white alt-error-new-content-01'>404 is a status code that tells a web user that a requested page is not available.</p>
            <p className='my-4'> <a href="/" className='error-home'>Home</a></p>
           
          </div>
        </div>
      </div>
    </center>
  )
}

import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from 'react-icons/ai';
import NavbarOne from "../../siteTheme/NavbarOne";
import Footer from "../../siteTheme/Footer";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import "../assets/styles/ido-style.css";
import Config from '../../../core/config/index';
import { userLoginChk } from '../../../core/helper/cookie';
import $ from 'jquery';
import { useContextData } from "../../../core/context/index";
import { makeRequest } from "../../../core/services/v1/request";
import { FaTelegram } from "react-icons/fa";
import Shape from "../assets/images/shape.png";
import { FaXTwitter } from "react-icons/fa6";
import NoProject from "../assets/images/no-project-data.png"

function IdoLaunch(props) {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/ido-lanch") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    const [userData, setUserData] = useState([]);
    const [pastData, setPastData] = useState([]);
    const [presentData, setPersentData] = useState([]);
    const [futureData, setFutureData] = useState([]);
    const [userId, setUserId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [currentTime, setCurrentTime] = useState();

    useEffect(() => {
        if (Config.LAUNCHPAD_STATUS == "Enable") {
            if (myProfile && myProfile._id) {
                setUserId(myProfile._id);
            }
        }
    }, [myProfile, userId]);
    useEffect(() => {
        getLaunchPadDetails()
    }, [userId]);

    const getLaunchPadDetails = async (event) => {
        setLoader(true)
        let searchData = event == undefined || event.target.value == '' ? null : event.target.value;
        let user_id = null;
        if (myProfile) user_id = myProfile._id;
        let payload = {
            userId: user_id,
            search: searchData ? searchData : ""
        };
        const params = {
            method: "POST",
            url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/idoProject`,
            body: payload
        };
        const response = await makeRequest(params);
        if (response.success) {
            let currentDate = moment().utc().format("YYYY-MM-DD HH:mm:ss");
            let past = [];
            let present = [];
            let future = [];
            const timeDivide = response.data;
            for (const obj of timeDivide) {
                let startDate = moment(obj.token_listing_from).utc().format("YYYY-MM-DD HH:mm:ss");
                let endDate = moment(obj.token_listing_to).utc().format("YYYY-MM-DD HH:mm:ss");
                if (endDate < currentDate) {
                    past.push({
                        _id: obj._id,
                        total_whitelisted_users: obj.total_whitelisted_users,
                        image: obj.image,
                        projectName: obj.projectName,
                        token_price: obj.token_price,
                        projectInfo: obj.projectInfo,
                        token_symbol: obj.token_symbol,
                        initial_supply: obj.initial_supply,
                        token_supply: obj.token_supply,
                        access_type_level: obj.access_type_level,
                        token_listing_from: obj.token_listing_from,
                        token_listing_to: obj.token_listing_to,
                        projectPic: obj.projectPic,
                        twitterLink: obj.twitterLink,
                        telegramGrpLink: obj.telegramGrpLink
                    })
                    past.sort((a, b) => new Date(b.token_listing_to) - new Date(a.token_listing_to));
                } else if (startDate <= currentDate && endDate >= currentDate) {
                    const params1 = {
                        method: "POST",
                        url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/getPercent`,
                        body: { ids: obj._id }
                    };
                    const response1 = await makeRequest(params1);
                    present.push({
                        _id: obj._id,
                        total_whitelisted_users: obj.total_whitelisted_users,
                        image: obj.image,
                        projectName: obj.projectName,
                        token_price: obj.token_price,
                        projectInfo: obj.projectInfo,
                        token_symbol: obj.token_symbol,
                        initial_supply: obj.initial_supply,
                        token_supply: obj.token_supply,
                        access_type_level: obj.access_type_level,
                        token_listing_from: obj.token_listing_from,
                        token_listing_to: obj.token_listing_to,
                        projectPic: obj.projectPic,
                        twitterLink: obj.twitterLink,
                        telegramGrpLink: obj.telegramGrpLink,
                        CalculatedData: response1.success = true ? response1.data : [],

                    })
                    present.sort((a, b) => new Date(b.token_listing_from) - new Date(a.token_listing_from));
                } else if (startDate > currentDate) {
                    future.push({
                        _id: obj._id,
                        total_whitelisted_users: obj.total_whitelisted_users,
                        image: obj.image,
                        projectName: obj.projectName,
                        token_price: obj.token_price,
                        projectInfo: obj.projectInfo,
                        token_symbol: obj.token_symbol,
                        initial_supply: obj.initial_supply,
                        token_supply: obj.token_supply,
                        access_type_level: obj.access_type_level,
                        token_listing_from: obj.token_listing_from,
                        token_listing_to: obj.token_listing_to,
                        projectPic: obj.projectPic,
                        twitterLink: obj.twitterLink,
                        telegramGrpLink: obj.telegramGrpLink
                    })
                    future.sort((a, b) => new Date(a.token_listing_from) - new Date(b.token_listing_from));
                }
            }
            setLoader(false);
            setUserData(response.data.msg);
            setPastData(past);
            setPersentData(present);
            setFutureData(future);
        }
    }
    const handleClick = (userId, status) => {
        if (userLoginChk() == true) {
            if (userId) {
                navigate("/active-ido", { state: { userDetails: userId, status } })
            }
        } else {
            navigate("/login")
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    return (
        <div className="top-alt-new-ido-section">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <section className='top-alt-new-ido-section-2 py-5 launchpad-active-nav-class'>
                <div className='container'>
                    <span className="alt-new-from-ctn-01 position-relative">LAUNCH</span>
                    <div className='row'>
                        <div className='col'>
                            <div className="d-lg-flex justify-content-between flex-column">
                                <div className="ms-lg-auto">
                                    <div className="input-group alt-new-input-group-10 markets-search-group-section">
                                        <span className="input-group-text border-end-0 bg" id="basic-addon1"><AiOutlineSearch /></span>
                                        <input
                                            type="text"
                                            className="form-control ps-0"
                                            placeholder="Search project name..."
                                            onChange={getLaunchPadDetails}
                                        />
                                    </div>
                                </div>
                                <ul class="nav nav-pills mb-5 new-launch-tabs-mbb" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-finished-tab" data-bs-toggle="pill" data-bs-target="#pills-finished" type="button" role="tab" aria-controls="pills-finished" aria-selected="false">Completed</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Active</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming" aria-selected="false">Upcoming</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-lg-5 mt-5" id="pills-tabContent">
                                <div class="tab-pane fade" id="pills-finished" role="tabpanel" aria-labelledby="pills-finished-tab" tabindex="0">
                                    <div class="row row-cols-1 row-cols-md-3 g-4">
                                        {
                                            pastData && pastData?.length > 0 ? (pastData).map((past) => {
                                                return (
                                                    <div class="col new-launch-margin-cols">
                                                        <div class="card h-100" style={{ cursor: 'pointer' }}>
                                                            <div className='position-relative card-img-top-new-sec-1' style={{ width: "fit-content" }}>
                                                                <img src={past.image} class="card-img-top" alt="ido-image" />
                                                                <div class="shape">
                                                                    <img src={Shape} alt="shape" />
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0 px-4 pb-0">
                                                                <h5 className='text-center mb-1 top-alt-new-ido-text-1 mt-3'>{past.projectName}</h5>
                                                                <div style={{ height: "80px" }}>
                                                                    <p className='text-center mb-4 top-alt-new-ido-text-2 px-2 opacity-75'>{past.projectInfo.slice(0, 100)} ...</p>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Swap rate</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`1 ${past.token_symbol} = ${past.token_price} USDT`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Initial Supply</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${past.initial_supply} ${past.token_symbol}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Total Supply</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${past.token_supply} ${past.token_symbol}`}</p>
                                                                    </div>
                                                                </div>
                                                                <p className=' mb-0 fw-bold text-center mt-3'>Social</p>
                                                                <div className='d-flex flex-row justify-content-center gap-3 mt-1'>
                                                                    <a target='_blank' href={past.twitterLink} className='alt-fa-twitter-01-1'><FaXTwitter /></a>
                                                                    <a target='_blank' href={past.telegramGrpLink} className='alt-fa-twitter-01-1'><FaTelegram /></a>
                                                                </div>

                                                            </div>
                                                            <div>
                                                                <div className='row text-center'>
                                                                    <div className='col-12'>
                                                                        <button type="button" onClick={() => handleClick(past._id, 'past')} class="top-alt-new-ido-button-1">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (
                                                ""
                                            )
                                        }
                                    </div>
                                    {
                                        pastData.length == 0 ?
                                            <div className="col mx-3" style={{ textAlign: 'center' }}>
                                                <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                                <p>No completed projects</p>
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab" tabindex="0">
                                    <div class="row row-cols-1 row-cols-md-3 g-4">
                                        {
                                            presentData && presentData?.length > 0 ? presentData.map((present) => {
                                                let sum = 0;
                                                if (present.CalculatedData.length > 0) {
                                                    for (let i = 0; i < present.CalculatedData.length; i++) {
                                                        if (present.CalculatedData[i].launchPadId == present._id) {
                                                            sum += Number(present.CalculatedData[i].amountDeducted);
                                                        }
                                                    }
                                                }
                                                let usdtValue = present.initial_supply * present.token_price;
                                                let percent = sum / usdtValue * 100;
                                                let showDate;
                                                let newDate = new Date();
                                                let toDate = present.token_listing_to;
                                                let toDateTimer = moment(present.token_listing_to);
                                                let timer = moment.duration(toDateTimer.diff(currentTime))
                                                if (moment(toDate).diff(newDate, "days") == 0) {
                                                    if (moment(toDate).diff(newDate, "hours") == 0) {
                                                        if (moment(toDate).diff(newDate, "minutes") >= 0) {
                                                            showDate = `${moment(toDate).diff(newDate, "minutes")} MINUTES LEFT`;
                                                        }
                                                    } else {
                                                        showDate = `${moment(toDate).diff(newDate, "hours")} HOURS LEFT`;
                                                    }
                                                } else {
                                                    showDate = `${moment(toDate).diff(newDate, "days")} DAYS LEFT`;
                                                }
                                                return (
                                                    <div class="col new-launch-margin-cols">
                                                        <div class="card h-100" style={{ cursor: 'pointer' }}>
                                                            <div className='position-relative card-img-top-new-sec-1' style={{ width: "fit-content" }}>
                                                                <img src={present.image} class="card-img-top" alt="ido-image" />
                                                                <div class="shape">
                                                                    <img src={Shape} alt="shape" />
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0 px-4 pb-0">
                                                                <h5 className='text-center mb-1 top-alt-new-ido-text-1 mt-3'>{present.projectName}</h5>
                                                                <div style={{ height: "80px" }}>
                                                                    <p className='text-center mb-4 top-alt-new-ido-text-2 px-2 opacity-75'>{present.projectInfo.slice(0, 100)}...</p>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Swap rate</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`1 ${present.token_symbol} = ${present.token_price} USDT`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Initial Supply</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${present.initial_supply}  ${present.token_symbol}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Time Left</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${timer.days()} D ${timer.hours()} H ${timer.minutes() > 0 ? timer.minutes() : 0} M ${timer.seconds() > 0 ? timer.seconds() : 0} S`}</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row mt-4'>
                                                                    <div className='col'>
                                                                        <p className='top-alt-new-ido-text-5 mb-0'>Raised Amount</p>
                                                                        <div className="d-flex mb-2">
                                                                            <div>
                                                                                <p className='top-alt-new-ido-text-3 fw-bold mb-0'><span style={{ color: "var(--btnClr)" }}>{sum.toFixed(2)} / </span>{usdtValue.toFixed(2)} USDT</p>
                                                                            </div>
                                                                            <div className='ms-auto text-end'>
                                                                                <p className='top-alt-new-ido-text-2 mb-0'>{percent.toFixed(2)}%</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="progress" style={{ height: "10px" }}>
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <p className=' mb-0 fw-bold text-center mt-3'>Social</p>
                                                                <div className='d-flex flex-row justify-content-center gap-3 mt-1'>
                                                                    <a target='_blank' href={present.twitterLink} className='alt-fa-twitter-01-1'><FaXTwitter /></a>
                                                                    <a target='_blank' href={present.telegramGrpLink} className='alt-fa-twitter-01-1'><FaTelegram /></a>
                                                                </div>

                                                            </div>
                                                            <div className="card-footer">
                                                                <div className='row text-center'>
                                                                    <div className='col-12'>
                                                                        <button type="button" onClick={() => handleClick(present._id, 'present')} class="top-alt-new-ido-button-1">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (
                                                ""
                                            )
                                        }
                                    </div>
                                    {
                                        presentData.length == 0 ?
                                            <div className="col mx-3" style={{ textAlign: 'center' }}>
                                                <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                                <p>No active projects</p>
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                                <div class="tab-pane fade" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabindex="0">
                                    <div class="row row-cols-1 row-cols-md-3 g-4">
                                        {
                                            futureData && futureData?.length > 0 ? (futureData).map((future) => {
                                                let showDate;
                                                let newDate = new Date();
                                                let fromDate = new Date(future.token_listing_from);
                                                let fromDateTimer = moment(future.token_listing_from);
                                                let timer = moment.duration(fromDateTimer.diff(currentTime))
                                                if (moment(fromDate).diff(newDate, "days") == 0) {
                                                    if (moment(fromDate).diff(newDate, "hours") == 0) {
                                                        if (moment(fromDate).diff(newDate, "minutes") >= 0) {
                                                            if (moment(fromDate).diff(newDate, "seconds") >= 0) {
                                                                showDate = `${moment(fromDate).diff(newDate, "seconds")} SECONDS LEFT`;
                                                            }
                                                        } else {
                                                            showDate = `${moment(fromDate).diff(newDate, "minutes")} MINUTES LEFT`;
                                                        }
                                                    } else {
                                                        showDate = `${moment(fromDate).diff(newDate, "hours")} HOURS LEFT`;
                                                    }
                                                } else {
                                                    showDate = `${moment(fromDate).diff(newDate, "days")} DAYS LEFT`;
                                                }
                                                return (
                                                    <div class="col new-launch-margin-cols">
                                                        <div class="card h-100" style={{ cursor: 'pointer' }}>
                                                            <div className='position-relative card-img-top-new-sec-1' style={{ width: "fit-content" }}>
                                                                <img src={future.image} class="card-img-top" alt="ido-image" />
                                                                <div class="shape">
                                                                    <img src={Shape} alt="shape" />
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0 px-4 pb-0">
                                                                <h5 className='text-center mb-1 top-alt-new-ido-text-1 mt-3'>{future.projectName}</h5>
                                                                <div style={{ height: "80px" }}>
                                                                    <p className='text-center mb-4 top-alt-new-ido-text-2 px-2 opacity-75'>{future.projectInfo.slice(0, 100)} ...</p>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Swap rate</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`1 ${future.token_symbol} = ${future.token_price} USDT`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Initial Supply</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${future.initial_supply}  ${future.token_symbol}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Time to start</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${timer.days()} D ${timer.hours()} H ${timer.minutes() > 0 ? timer.minutes() : 0} M ${timer.seconds() > 0 ? timer.seconds() : 0} S`}</p>
                                                                    </div>
                                                                </div>
                                                                <p className=' mb-0 fw-bold text-center mt-3'>Social</p>
                                                                <div className='d-flex flex-row justify-content-center gap-3 mt-1'>
                                                                    <a target='_blank' href={future.twitterLink} className='alt-fa-twitter-01-1'><FaXTwitter /></a>
                                                                    <a target='_blank' href={future.telegramGrpLink} className='alt-fa-twitter-01-1'><FaTelegram /></a>
                                                                </div>

                                                            </div>
                                                            <div className="card-footer">
                                                                <div className='row text-center'>
                                                                    <div className='col-12'>
                                                                        <button type="button" onClick={() => handleClick(future._id, 'future')} class="top-alt-new-ido-button-1">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (
                                                ""
                                            )
                                        }
                                    </div>
                                    {
                                        futureData.length == 0 ?
                                            <div className="col mx-3" style={{ textAlign: 'center' }}>
                                                <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                                <p>No upcoming projects</p>
                                            </div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default IdoLaunch;
import CryptoJS from "crypto-js";
import Config from "../config/index"
export const AESDecrypt = (values) => {
    console.log("working");
    try {
        var bytes = CryptoJS.AES.decrypt(values, Config.DECRYPT_SECRET_KEY);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (error) {
        return false
    };
};
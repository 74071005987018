import {
    BrowserRouter as Router,
    useNavigate,
} from "react-router-dom";
import NavbarOne from "../../siteTheme/NavbarOne";
import Footer from "../../siteTheme/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import { useContextData } from "../../../core/context";
import Config from '../../../core/config/index';
import $ from 'jquery'
import 'react-toastify/dist/ReactToastify.css';
import "../assets/styles/ido-style.css";
import { Modal } from "react-bootstrap";
import { FaTelegram } from "react-icons/fa";
import Shape from "../assets/images/shape.png";
import { FaXTwitter } from "react-icons/fa6";
import NoProject from "../assets/images/no-project-data.png"

function IdoProject(props) {
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    const currentDate = moment(new Date);
    const maxProjectInfo = 80; //** get the project information that is shorter than desired length */
    const [userData, setUserData] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showReason, setShowReason] = useState(false);
    const [indexNo, setIndexNo] = useState();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/ido-project") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    useEffect(() => {
        if (myProfile && myProfile._id) {
            axios({
                method: "GET",
                url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/project/${myProfile._id}`
            }).then((data) => {
                setUserData(data.data.data.msg);
            }).catch((err) => console.log(err))
        }
    }, [myProfile]);
    const handleClick = (userId, status, symbol, index, supply) => {
        if (status == "WAITING FOR ADMIN APPROVAL" || status == "UPCOMING") {
            setIndexNo(index);
            setShowMore(true);
        } else if (status == "REJECTED BY ADMIN") {
            setIndexNo(index);
            setShowReason(true);
        } else {
            if (userId) axios({
                method: "GET",
                url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/launchTokenHistory/${userId}`
            }).then((res) => {
                const { data } = res;
                console.log("data", data);
                if (data) navigate("/ido-project-info", { state: { userDetails: data.data, status, symbol, supply } })
            }).catch((err) => console.log(err))
        }
    };
    return (
        <div className="top-alt-new-ido-section">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="launchpad-active-nav-class">
                <span className="alt-new-from-ctn-001 position-relative">PROJECT</span>
            </div>
            {/*   */}
            <section className='top-alt-new-ido-section-2 py-5'>
                {/* */}
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <div class="row row-cols-1 row-cols-md-3 g-4 mt-5">
                                {
                                    userData && userData?.length > 0 ? (userData).map((past, index) => {
                                        let showDate;
                                        if (past.active_status == 3) {
                                            showDate = 'REJECTED BY ADMIN'
                                        } else if (past.active_status == 4) {
                                            showDate = 'COMPLETED'
                                        } else {
                                            if (past.token_listing_to != "" && past.token_listing_from != null) {
                                                let new_date = new Date();
                                                if (moment(past.token_listing_from).diff(moment(new_date), "days") <= 0) {
                                                    if (moment(past.token_listing_from).diff(moment(new_date), "hours") <= 0) {
                                                        if (moment(past.token_listing_from).diff(moment(new_date), "minutes") <= 0) {
                                                            if (moment(past.token_listing_to).diff(moment(new_date), "days") <= 0) {
                                                                if (moment(past.token_listing_to).diff(moment(new_date), "hours") <= 0) {
                                                                    if (moment(past.token_listing_to).diff(moment(new_date), "minutes") < 0) {
                                                                        showDate = `END`;
                                                                    } else {
                                                                        showDate = `${moment(past.token_listing_to).diff(moment(new Date), "minutes")} MINUTES LEFT`;
                                                                    }
                                                                } else {
                                                                    showDate = `${moment(past.token_listing_to).diff(moment(new Date), "hours")} HOURS LEFT`;
                                                                }
                                                            } else {
                                                                showDate = `${moment(past.token_listing_to).diff(moment(new Date), "days")} DAYS LEFT`;
                                                            }
                                                        } else {
                                                            showDate = `UPCOMING`
                                                        }
                                                    } else {
                                                        showDate = `UPCOMING`
                                                    }
                                                } else {
                                                    showDate = `UPCOMING`
                                                }
                                            } else {
                                                showDate = `WAITING FOR ADMIN APPROVAL`
                                            }
                                        }
                                        return (
                                            <>
                                                <div class="col new-launch-margin-cols mt-5">
                                                    <div class="card h-100" style={{ cursor: 'pointer' }}>
                                                        <div className='position-relative card-img-top-new-sec-1' style={{ width: "fit-content" }}>
                                                            <img src={past.image} class="card-img-top" alt="ido-image" />
                                                            <div class="shape">
                                                                <img src={Shape} alt="shape" />
                                                            </div>
                                                        </div>
                                                        <div class="card-body pt-0 px-4 pb-0">
                                                            <h5 className='text-center mb-1 top-alt-new-ido-text-1 mt-3'>{past.projectName}</h5>
                                                           <div style={{height:"80px"}}>
                                                           <p className='text-center mb-4 top-alt-new-ido-text-2 px-2 opacity-75'>{past.projectInfo.slice(0, 100)} ...</p>
                                                           </div>
                                                            <div className="d-flex flex-row justify-content-center gap-3 mt-2 mb-2">
                                                                <div className="active-ido-section-2">
                                                                    <span className=" fs-14">{showDate}</span>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                    <p className='top-alt-new-ido-text-3 mb-0'>Swap rate</p>
                                                                </div>
                                                                <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                    <p className='top-alt-new-ido-text-3-1 mb-0'>{`1 ${past.token_symbol} = ${past.token_price} USDT`}</p>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                    <p className='top-alt-new-ido-text-3 mb-0'>Initial Supply</p>
                                                                </div>
                                                                <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                    <p className='top-alt-new-ido-text-3-1 mb-0'>{`${past.initial_supply} ${past.token_symbol}`}</p>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                    <p className='top-alt-new-ido-text-3 mb-0'>Total Supply</p>
                                                                </div>
                                                                <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                    <p className='top-alt-new-ido-text-3-1 mb-0'>{`${past.token_supply} ${past.token_symbol}`}</p>
                                                                </div>
                                                            </div>
                                                            <p className=' mb-0 fw-bold text-center mt-3'>Social</p>
                                                            <div className='d-flex flex-row justify-content-center gap-3 mt-1'>
                                                                <a target='_blank' href={past.twitterLink} className='alt-fa-twitter-01-1'><FaXTwitter /></a>
                                                                <a target='_blank' href={past.telegramGrpLink} className='alt-fa-twitter-01-1'><FaTelegram /></a>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <div className='row text-center'>
                                                                <div className='col-12'>
                                                                    <button type="button" onClick={() => handleClick(past._id, showDate, past.token_symbol, index, past.initial_supply)} class="top-alt-new-ido-button-1">View Details</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }) : (
                                        <div className="justify-content-center col-12 col-lg-12" style={{ textAlign: 'center' }}>
                                            <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                            <p>No Projects Available</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {
                showMore == true ?
                    <Modal
                        className="ido-active-buy-section-modal"
                        size="md"
                        centered
                        aria-labelledby="example-modal-sizes-title-lg"
                        show={showMore}
                        onHide={() => { setShowMore(!showMore) }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="alt-more-info-ctn">More Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Project Name</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="projectName"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="projectName"
                                    value={userData[indexNo].projectName}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Project Info</p>
                                <textarea className="form-control" name="projectInfo" rows={5} value={userData[indexNo].projectInfo}></textarea>
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Blockchain</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="blockChainSelect"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="blockChainSelect"
                                    value={userData[indexNo].blockChainSelect}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Token Name</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="token_name"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="token_name"
                                    value={userData[indexNo].token_name}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Token Symbol</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="token_name"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="token_name"
                                    value={userData[indexNo].token_symbol}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Token Supply</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="token_supply"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="token_supply"
                                    value={userData[indexNo].token_supply}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Initial Supply</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="initial_supply"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="initial_supply"
                                    value={userData[indexNo].initial_supply}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Hardcapvalue</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="hard_cap_value"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="hard_cap_value"
                                    value={userData[indexNo].hard_cap_value}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Softcapvalue</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="hard_cap_value"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="hard_cap_value"
                                    value={userData[indexNo].soft_cap_value}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">ContractAddress</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contract_address"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="contract_address"
                                    value={userData[indexNo].contact_address}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Token Decimal</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contract_address"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="decimal"
                                    value={userData[indexNo].token_contract_decimal}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">Start Date</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contract_address"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="contract_address"
                                    value={moment(userData[indexNo].start_date).utc().format("DD/MM/YYYY hh:mm a")}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">End Date</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contract_address"
                                    autoComplete="off"
                                    aria-label="Sizing example input"
                                    aria-describedby="emailHelp"
                                    name="contract_address"
                                    value={moment(userData[indexNo].end_date).utc().format("DD/MM/YYYY hh:mm a")}
                                    disabled
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    ''
            }
            {
                showReason == true ?
                    <Modal
                        className="ido-active-buy-section-modal"
                        size="md"
                        centered
                        aria-labelledby="example-modal-sizes-title-lg"
                        show={showReason}
                        onHide={() => { setShowReason(!showReason) }}
                    >
                        <Modal.Header>
                            <Modal.Title className="alt-more-info-ctn">Reason</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-4">
                                <p className="ido-active-text-2 mb-1">{userData[indexNo].reject_reason}</p>
                            </div>
                            <div className="mt-3">
                                <button className="popup-modal-button-my-orders capitalizeText py-2 px-0" onClick={() => setShowReason(false)}>Close</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    :
                    ''
            }
        </div>
    )
}

export default IdoProject
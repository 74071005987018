import React, { useEffect, useRef, useState } from "react";
import {
    BrowserRouter as useParams, useNavigate
} from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Form, FormGroup, Label, Input } from "reactstrap"

import { GoChevronLeft } from 'react-icons/go';
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import $ from "jquery";
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { toast } from "../core/lib/toastAlert";
import { useContextData } from '../core/context/index'
import { TbUpload } from 'react-icons/tb';
import { useFileUpload } from "use-file-upload";
import * as yup from 'yup';
import { useFormik } from 'formik';
import DefaultUpload from "../assets/images/cloud+upload+file.png";
import { pageAllowCheck, clickNavigate } from "../core/helper/common";
import { FaExternalLinkAlt } from "react-icons/fa";
import "../assets/style.css";
import axios from "axios";
import QrCode from 'react-qr-code';
import { dateFormat, shortenString } from "../core/helper/date-format";
import ReactTooltip from "react-tooltip";
import notFound from "../assets/images/file.png";

const validationSchema = yup.object({
    amount: yup.number().typeError('Please enter the valid amount').required('Amount is required'),
    transactionNumber: yup.string().typeError('Please enter the valid Tnx Hash number').required('Tnx Hash is required'),
    myfile1: yup.mixed().required('Attachment is required')
});
export default function DepositPi(props) {
    const defaultSrc = DefaultUpload;
    const navigate = useNavigate();
    const { myProfile, ipAddress } = useContextData();
    const [isLoading, setisLoading] = useState(false);
    const [currencyList, setCurrencyList] = useState({});
    const [userDepositHistory, setUserDepositHistory] = useState([]);
    const [adminAddress, setAdminAddress] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalorders, settotalorders] = useState(0);
    const fileRef = useRef();
    const [imageUrl, setImageUrl] = useState(null);
    const [isAttached, setIsAttached] = useState(false);
    const [depositText, setDepositText] = useState("CLAIM DEPOSIT");
    const [loader, setLoader] = useState(false);
    // const [filterText, setFilterText] = useState('');

    useEffect(() => {
        const getAdminAddress = async () => {
            try {
                const params = {
                    url: `${Config.V1_API_URL}wallet/getAdminPiAddress`,
                    method: 'GET',
                }
                const result = (await makeRequest(params));
                if (result.status) {
                    const address = result.data?.address;
                    // const addressUrl = `https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=${address}&choe=UTF-8&chld=L`
                    setAdminAddress(address);
                } else {
                    setAdminAddress(null);
                }
            } catch (error) {
                console.log("Something Went Wrong!");
            }
        };
        getAdminAddress()
    }, []);


    useEffect(() => {
        getHistory();
    }, [currentPage, rowsPerPage]);

    const column = [
        {
            id: 0,
            name: "Sl.No",
            selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
            width: "70px",
        },
        {
            id: 1,
            name: "Time",
            selector: (row) => dateFormat(row.createdDate),
            width: "200px",
        },
        {
            id: 2,
            name: "Type",
            selector: (row) => row.type
        },
        {
            id: 3,
            name: "Asset",
            selector: (row) => (<>
                <img src={row.currencyId.image} style={{ height: '23px', width: '23px' }} />&nbsp;<span>PI</span>
            </>)
        },
        {
            id: 4,
            name: "Amount",
            selector: (row) => row.amount
        },
        {
            id: 5,
            name: "Tnx Hash",
            selector: (row) => (
                <>
                    <span data-tip data-for={`tooltip-${row.txnId}`}>
                        {shortenString(row.txnId, 8)}
                    </span>&nbsp;
                    <span><FaExternalLinkAlt style={{ cursor: "pointer" }} onClick={() => window.open(Config.PI_EXPLORER_URL + row.txnId, "_blank")} /></span>
                    <ReactTooltip
                        id={`tooltip-${row.txnId}`}
                        place="top"
                        effect="solid"
                        className="tooltip-text-Theme text-center"
                    >
                        <span className="tooltip-text-1">{row.txnId}</span>
                    </ReactTooltip>

                </>
            )
        },
        {
            id: 6,
            name: "Status",
            selector: (row) => row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing'
        },
    ]

    useEffect(() => {
        $(".crypto-workflow-section-close-button").click(function () {
            $(".deposit-second-section").hide();
        });
    }, []);
    const formik = useFormik({
        initialValues: {
            amount: '',
            transactionNumber: '',
            myfile1: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!isAttached) {
                toast({ type: 'error', message: "Your File Size Is Too Large!" });
                return
            }
            let data = {
                currencySymbol: currencyList.currencySymbol,
                amount: values.amount,
                transactionNumber: values.transactionNumber,
                attachment: imageUrl,
                depositType: "Wallet Balance",
            }
            data.ipDetails = ipAddress;
            const params = {
                url: `${Config.V1_API_URL}wallet/depositPi`,
                method: 'POST',
                body: data
            }
            const result = (await makeRequest(params));
            let type = 'error';
            if (result.status) {
                type = 'success';
                setImageUrl(defaultSrc);
                toast({ type, message: result.message });
                getHistory();
                setisLoading(false);
                formik.resetForm();
            } else {
                setisLoading(false)
                type = 'error';
                const errmsg = result.message.split(' ');
                if (errmsg[0] == 'KYC') {
                    navigate('/my/identification')
                }
                setisLoading(false)
                toast({ type, message: result.message });
            }
            setisLoading(false)

        },
    });
    useEffect(() => {
        const getPiCurrency = async () => {
            try {
                const params = {
                    url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                    method: "POST",
                    body: { currencySymbol: "PI" }
                };
                const response = await makeRequest(params);
                if (response.status && response.data) {
                    setCurrencyList(response.data);
                }
            } catch (err) {
                console.log("error")
            }
        }
        getPiCurrency()
    }, [])
    // useEffect(() => {
    //     if (myProfile) {
    //         const pageAllowCheckResp = pageAllowCheck(myProfile, "final");
    //         if (pageAllowCheckResp.type == "error") {
    //             toast({ type: pageAllowCheckResp.type, message: pageAllowCheckResp.message });
    //             navigate(pageAllowCheckResp.navigate);
    //         }
    //         else {
    //             const data = { type: "deposit", row: { currencySymbol: "PI" } };
    //             clickNavigate(data, myProfile);
    //         }
    //     }
    // }, [myProfile]);
    const getHistory = async () => {
        try {
            setLoader(true)
            setDepositText('Loading...')
            const value = {
                type: "Deposit",
                currencySymbol: "PI",
                network: "Coin",
                limit: rowsPerPage,
                offset: rowsPerPage * (currentPage - 1)
            }
            const params = {
                url: `${Config.V1_API_URL}wallet/getHistory`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setUserDepositHistory(response.data);
                settotalorders(response.total);
                setLoader(false)
                setDepositText('CLAIM DEPOSIT')
            }
        } catch (err) {
            console.log("something wrong!");
        }
    }
    const imageUploader = async (value) => {
        try {
            const formData = new FormData();
            let sizeFile = 1;
            let fileToUpload = value;
            let fileName = 'attachment';
            let fileExtension = fileToUpload.name.split('?')[0].split('.').pop();
            formData.append('images[]', fileToUpload, fileName + '.' + fileExtension);
            console.log('attachment.size', value.size)
            if (value && value.size > 1000000) {
                setImageUrl(defaultSrc);
                toast({ type: "error", message: "You'r File Is Too Large!" });
                return
            };
            setIsAttached(true)
            if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg") {
                setImageUrl(defaultSrc);
                toast({ type: "error", message: "You'r File Is Unsupported Format!" });
                return
            };
            formik.setFieldValue('myfile1', value);
            setisLoading(true);
            const params = {
                url: `${Config.V1_API_URL}admin/fileUpload?sizeFile=${sizeFile}&&type="attachment"`,
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setImageUrl(response.message[0].location);
                setisLoading(false);
            }
        } catch (error) {
            console.log("image error");
        };
    };
    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="deposit-page-top-banner">
                <div className="deposit-hero-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6" onClick={() => navigate("/my/dashboard")}>
                                <GoChevronLeft className="deposit-back-button-icon" />
                                <span className="deposit-text-1">Deposit Pi</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="d-flex  flex-column">
                                <div className="deposit-third-left-section">
                                    <span className="deposit-text-3 color-blue password-text-44">Selected coin</span>
                                </div>

                                <div className="deposit-third-right-section">
                                    <div className="d-flex flex-row align-items-center deposit-select-coin-section" >
                                        <div className="">
                                            <img
                                                src={currencyList ? currencyList.image : ""}
                                                className="deposit-logo-image"
                                                alt="logo"
                                            />
                                            <span key={'i'} className="deposit-text-4"> PI </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="col-lg-12">
                                    <div className=" mt-5">
                                        <div className="">
                                            <div className="withdraw-nav-tabs">
                                                <div className="mb-4">
                                                    <span className="deposit-text-33 color-blue password-text-44 ">Amount</span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Amount"
                                                        id="amount"
                                                        name='amount'
                                                        autoComplete='off'
                                                        value={formik.values.amount}
                                                        onChange={(e) => {
                                                            const regex = /^[0-9.]*$/;
                                                            const amount = e.target.value;
                                                            const isTrue = regex.test(amount);
                                                            const hasMultipleDots = (amount.match(/\./g) || []).length > 1;
                                                            if (isTrue && !hasMultipleDots) {
                                                                formik.handleChange(e)
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                        helperText={formik.touched.amount && formik.errors.amount}
                                                    />
                                                    {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                                                </div>
                                                <div className="mb-4">
                                                    <span className="deposit-text-33 color-blue password-text-44">Tnx Hash</span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Tnx Hash"
                                                        id="transactionNumber"
                                                        name='transactionNumber'
                                                        autoComplete='off'
                                                        value={formik.values.transactionNumber}
                                                        onChange={(e) => {
                                                            const allowedRegex = /^[a-zA-Z0-9]*$/;
                                                            if (allowedRegex.test(e.target.value)) {
                                                                formik.handleChange(e)
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.transactionNumber && Boolean(formik.errors.transactionNumber)}
                                                        helperText={formik.touched.transactionNumber && formik.errors.transactionNumber}
                                                    />
                                                    {formik.errors.transactionNumber ? <small className="invalid-transactionNumber error">{formik.errors.transactionNumber}</small> : null}
                                                </div>
                                                <div>
                                                    <input type="file" hidden ref={fileRef} onChange={(e) => {
                                                        formik.setFieldValue('myfile1', "");
                                                        imageUploader(e.target.files[0]);
                                                    }} />
                                                    <img className='payment-qrcode-optional-image' src={imageUrl != null ? imageUrl : defaultSrc} alt="preview" /><br />
                                                    <button type="button" className='payment-qrcode-optional-button mt-3 alter-paymeny-btn-01' name="myfile1"
                                                        onClick={() => {
                                                            if (fileRef.current) {
                                                                fileRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        <TbUpload />Upload
                                                    </button>
                                                    <div className="mt-2">
                                                        <span className="ms-2 password-text-44  color-blue">Note:</span>
                                                        <span className="ms-2 password-text-33 ">File Size Should Be Upload Below 1mb</span>
                                                    </div>
                                                    {
                                                        currencyList && currencyList.minCurDeposit > 0 ?
                                                            <li>
                                                                <span className="deposit-text-3">
                                                                    Minimum deposit for all coins and tokens is <span className="deposit-text-9">{currencyList.minCurDeposit} {currencyList.currencySymbol}</span>.  Deposits below this amount cannot be recovered.
                                                                </span>
                                                            </li> : ""
                                                    }
                                                </div>
                                                {formik.errors.myfile1 ? <small className="invalid-myfile1 error">{formik.errors.myfile1}</small> : null}
                                                <button type="submit" disabled={isLoading} className="btn btn-col w-100 mt-4 alt-submit-txt-btn">
                                                    Submit Deposit Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-7 mt-1">
                            <Grid item xs={12} sm={6} className="text-left-move">
                                <div>
                                    <p className='color-blue password-text-44'>Send {currencyList.currencySymbol} to this address
                                    </p>
                                    <div className='mt-2'>
                                        {adminAddress}
                                    </div>

                                </div>
                                <div>
                                    <p className='password-text-33 mt-2'>
                                        The amount will be added to the user's wallet after we have verified and received the user's (Pi) transaction hash.
                                    </p>
                                    <div className="mt-2">
                                        <p className="color-blue password-text-44">Note:</p>
                                        <ul className='mt-12'>
                                            <li className='password-text-33'>{currencyList.depositNotes}</li>
                                        </ul>
                                    </div>
                                    <div className="mt-2">
                                        {adminAddress && (
                                            <div className="">
                                                <QrCode
                                                    className="qr-bg bg-white p-3"
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                                                    value={adminAddress}
                                                    viewBox={"0 0 256 256"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </div>

                <div className="container deposit-fourth-section mb-4">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h1 className="deposit-text-11 color-blue">Recent Deposits</h1>

                            <p className='mt-12'>
                                If your previous deposit is not reflecting, you can claim your deposit below &nbsp;
                            </p>
                            <button className='fc-b f-15 m-top-20 cursor color-hover-res hover-hand-csss new-calim-csxas mb-5' onClick={() => getHistory()}>
                                {depositText} {" "}
                                {
                                    loader ?
                                        <div class="spinner-border text-primary spinner-border-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        :
                                        ''
                                }
                            </button>
                            {/* <div className="col-lg-5 mt-3">
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleSelect">Search</Label>
                                        <Input
                                            id="search"
                                            type="text"
                                            placeholder="Filter By Transaction id"
                                            aria-label="Search Input"
                                            value={filterText}
                                            onChange={(e) => setFilterText(e.target.value)}
                                        />
                                    </FormGroup>
                                </Form>
                            </div> */}
                            <DataTable
                                columns={column}
                                data={userDepositHistory}
                                persistTableHead
                                pagination
                                paginationServer
                                paginationPerPage={rowsPerPage}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationTotalRows={totalorders}
                                onChangeRowsPerPage={newRowsPerPage => {
                                    setRowsPerPage(newRowsPerPage);
                                    setCurrentPage(1);
                                }}
                                onChangePage={page => setCurrentPage(page)}
                                noDataComponent={
                                    <div className="text-center mt-3">
                                        <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                                        <br />
                                        <span className="text-center">No Records Found</span>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

import NavbarOne from "../../siteTheme/NavbarOne";
import Footer from "../../siteTheme/Footer";
import React, { useState, useEffect } from "react";
import "../assets/styles/ido-style.css";
import Config from '../../../core/config/index';
import axios from "axios";
import moment from "moment/moment";
import { userLoginChk } from '../../../core/helper/cookie';
import { useNavigate, Link } from "react-router-dom";
import { makeRequest } from '../../../core/services/v1/request';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { FaTelegram } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Galaxy from "../assets/images/galaxy.png";
import Galaxy2 from "../assets/images/galaxy.2.png";
import Group1 from "../assets/images/float-img/1.png";
import Group2 from "../assets/images/float-img/2.png";
import Group3 from "../assets/images/float-img/3.png";
import Group4 from "../assets/images/float-img/4.png";
import Shape from "../assets/images/shape.png";
import { FaCheckCircle } from "react-icons/fa";
import { CiTimer } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { useContextData } from "../../../core/context/index";
import NoProject from "../assets/images/no-project-data.png"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Home(props) {
    const [presentData, setPersentData] = useState([]);
    const [futureData, setFutureData] = useState([]);
    const [bannerImages, setBannerImages] = useState([]);
    const [currentTime, setCurrentTime] = useState();
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    useEffect(() => {
        if (Config.LAUNCHPAD_STATUS == "Enable") {
            if (userLoginChk() == true) {
                if (myProfile && myProfile._id) {
                    setUserId(myProfile._id);
                    activeIdoList()
                }
            }
        }
    }, [myProfile, userId]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        banner()
        if (userLoginChk() == false) {
            activeIdoList()
        }
    }, []);

    const activeIdoList = async () => {
        let user_id = null;
        if (myProfile) user_id = myProfile._id;
        let payload = {
            userId: user_id,
        }
        let params = {
            method: "post",
            url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/showActiveIdo`,
            body: payload
        }
        const response = await makeRequest(params);
        const present = response.data?.present
        let activeData = [];
        let upcomingData = [];
        if (present.status == true) {
            for (const obj of present.msg) {
                let currentDate = new Date();
                let startDate = new Date(obj.token_listing_from);
                let endDate = new Date(obj.token_listing_to);
                if (startDate <= currentDate && endDate >= currentDate) {
                    const params1 = {
                        method: "POST",
                        url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/getPercent`,
                        body: { ids: obj._id }
                    };
                    const response = await makeRequest(params1);
                    activeData.push({
                        _id: obj._id,
                        total_whitelisted_users: obj.total_whitelisted_users,
                        total_user_participated: obj.total_user_participated,
                        image: obj.image,
                        projectName: obj.projectName,
                        token_price: obj.token_price,
                        projectInfo: obj.projectInfo,
                        token_symbol: obj.token_symbol,
                        initial_supply: obj.initial_supply,
                        access_type_level: obj.access_type_level,
                        token_listing_from: obj.token_listing_from,
                        token_listing_to: obj.token_listing_to,
                        twitterLink: obj.twitterLink,
                        telegramGrpLink: obj.telegramGrpLink,
                        CalculatedData: response.success = true ? response.data : [],
                    })
                    activeData.sort((a, b) => new Date(b.token_listing_from) - new Date(a.token_listing_from));
                } else if (startDate >= currentDate) {
                    upcomingData.push({
                        _id: obj._id,
                        total_whitelisted_users: obj.total_whitelisted_users,
                        image: obj.image,
                        projectName: obj.projectName,
                        token_price: obj.token_price,
                        projectInfo: obj.projectInfo,
                        token_symbol: obj.token_symbol,
                        token_supply: obj.token_supply,
                        initial_supply: obj.initial_supply,
                        access_type_level: obj.access_type_level,
                        token_listing_from: obj.token_listing_from,
                        token_listing_to: obj.token_listing_to,
                        twitterLink: obj.twitterLink,
                        telegramGrpLink: obj.telegramGrpLink,
                        projectPic: obj.projectPic
                    })
                    upcomingData.sort((a, b) => new Date(a.token_listing_from) - new Date(b.token_listing_from));
                }
            }
        }
        setPersentData(activeData.slice(0, 3));
        setFutureData(upcomingData.slice(0, 3));

    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    const handleClick = (userId, status) => {
        if (userLoginChk() == true) {
            if (userId) {
                navigate("/active-ido", { state: { userDetails: userId, status } })
            }
        } else {
            navigate("/login")
        }
    };
    const banner = () => {
        axios({
            method: "GET",
            url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/getBannerImages`
        }).then((response) => {
            setBannerImages(response.data.data.message);
        }).catch((err) => console.log(err))
    }
    return (
        <div >
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="launchpad-active-nav-class">
                <img src={Galaxy} className='new-launchpad-floting-img-1 light-new-hide' alt='Galaxy' />
                <img src={Galaxy2} className='new-launchpad-floting-img-1 dark-new-hide' alt='Galaxy' />
                <div className='new-launchpad-floting-img-2'></div>
                <div className='top-alt-new-ido-section position-relative' style={{ zIndex: "1" }}>
                    <section className='top-alt-new-ido-section-1'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col p-0'>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={0}
                                        navigation={true}
                                        loop
                                        pagination={{
                                            clickable: true,
                                        }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay, Pagination]}
                                    >
                                        {
                                            bannerImages.map((data) => {
                                                return (
                                                    <SwiperSlide>
                                                        <img src={data.image} alt="launchpad" />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className='top-alt-new-ido-section-3 py-5'>
                        <div className='container'>
                            <h1 className='top-alt-new-ido-text-1-1 mb-0'>Active GetX'S</h1>
                            <div className='row'>
                                {
                                    presentData.length > 0 ?
                                        <div className='col'>

                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={0}
                                                loop
                                                modules={[Autoplay, Navigation]}
                                                navigation={{
                                                    prevEl: '.prev',
                                                    nextEl: '.next',
                                                }}
                                            >
                                                {
                                                    presentData && presentData.length > 0 && presentData.slice(0, 3) ?
                                                        (presentData).map((data) => {
                                                            let tokenSum = 0;
                                                            let sum = 0;
                                                            if (data.CalculatedData.length > 0) {
                                                                for (let i = 0; i < data.CalculatedData.length; i++) {
                                                                    if (data.CalculatedData[i].launchPadId == data._id) {
                                                                        tokenSum += Number(data.CalculatedData[i].numberOfToken)
                                                                        sum += Number(data.CalculatedData[i].amountDeducted);
                                                                    }
                                                                }
                                                            }
                                                            let usdtValue = data.initial_supply * data.token_price;
                                                            let percent = sum / usdtValue * 100;
                                                            let image = data.image == null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9qCKz9O8kcSbhK2f2kghHy5zEuWAyDVjUWQ&usqp=CAU" : data.image;
                                                            let toDate = moment(data.token_listing_to);
                                                            let timeBetween = moment.duration(toDate.diff(currentTime));
                                                            return (
                                                                <SwiperSlide>
                                                                    <div className='top-alt-new-ido-section-3-card-1'>
                                                                        <div className='row g-4'>
                                                                            <div className='col-lg-5'>
                                                                                <img src={image} class="top-alt-new-ido-profile-2" alt="ido-image" />
                                                                            </div>
                                                                            <div className='col-lg-7 px-lg-5'>
                                                                                <div className='d-flex flex-row new-launch-right-1'>
                                                                                    <div>
                                                                                        <p className='top-alt-new-ido-text-4 mb-1'>{data.projectName}</p>
                                                                                        <p className='top-alt-new-ido-text-2 mb-0 opacity-75'>Release {moment(data.token_listing_from).format("DD MMMM, YYYY")}</p>
                                                                                    </div>
                                                                                    <div className='ms-auto active-status-bar-new-launch'>
                                                                                        <span><FaCheckCircle className='me-1 fs-20' />Active</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='d-flex flex-row my-3 new-launch-right-2'>
                                                                                    <div>
                                                                                        <p className='top-alt-new-ido-text-2 mb-1'>Total Raised</p>
                                                                                        <p className='top-alt-new-ido-text-4 mb-0'>${sum.toFixed(2)} / ${usdtValue.toFixed(2)} </p>
                                                                                    </div>
                                                                                    <div className='ms-auto text-end'>
                                                                                        <p className='top-alt-new-ido-text-2 mb-1'>TIme Left</p>
                                                                                        <p className='top-alt-new-ido-text-4 mb-0'>
                                                                                            {timeBetween.days()}<span className='top-alt-new-ido-text-2 me-2'>D</span>
                                                                                            {timeBetween.hours()}<span className='top-alt-new-ido-text-2 me-2'>H</span>
                                                                                            {timeBetween.minutes()}<span className='top-alt-new-ido-text-2 me-2'>M</span>
                                                                                            {timeBetween.seconds()}<span className='top-alt-new-ido-text-2'>S</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className=' my-3 new-launch-right-3'>
                                                                                    <div className='d-flex mb-2'>
                                                                                        <div>
                                                                                            <p className='top-alt-new-ido-text-1 mb-0'>Current Sale</p>
                                                                                        </div>
                                                                                        <div className='ms-auto text-end'>
                                                                                            <p className='top-alt-new-ido-text-2 mb-0'>{percent.toFixed(2)}%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="progress" style={{ height: "16px" }}>
                                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                    </div>
                                                                                    <div className='d-flex flex-row justify-content-between mt-4'>
                                                                                        <div className=''>
                                                                                            <p className='top-alt-new-ido-text-3 mb-0'>Participants</p>
                                                                                            <p className='top-alt-new-ido-text-2 mb-0'>{data.total_user_participated != null ? data.total_user_participated : 0}</p>
                                                                                        </div>
                                                                                        <div className='text-center'>
                                                                                            <p className='top-alt-new-ido-text-3 mb-0'>Start Date</p>
                                                                                            <p className='top-alt-new-ido-text-2 mb-0'>{moment(data.token_listing_from).format("DD.MM.YYYY")}</p>
                                                                                        </div>
                                                                                        <div className='text-end'>
                                                                                            <p className='top-alt-new-ido-text-3 mb-0'>Token Price</p>
                                                                                            <p className='top-alt-new-ido-text-2 mb-0'>{data.token_price} USDT</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='d-flex mb-2 justify-content-between'>
                                                                                    <div className='d-flex gap-1'>
                                                                                        <div>
                                                                                            <p className='top-alt-new-ido-text-3 mb-0'><CiTimer /></p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className='top-alt-new-ido-text-1 mb-0'>Token Sold</p>
                                                                                            <p className='top-alt-new-ido-text-2 mb-0'>{tokenSum}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='d-flex gap-2'>
                                                                                        <a target='_blank' href={data.twitterLink}><FaXTwitter className='alt-fa-twitter-01' /></a>
                                                                                        <a target='_blank' href={data.telegramGrpLink}><FaTelegram className='alt-fa-twitter-02' /></a>
                                                                                    </div>
                                                                                    <div className=' text-end'>
                                                                                        <button type="button" onClick={() => handleClick(data._id, 'present')} class="top-alt-new-ido-button-1 top-0">View Details</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            )
                                                        })
                                                        :
                                                        ''
                                                }
                                            </Swiper>
                                            <div className='d-flex gap-2 justify-content-end'>
                                                <div className='top-alt-new-ido-section-3-arrow prev'><MdKeyboardArrowLeft /></div>
                                                <div className='top-alt-new-ido-section-3-arrow next'><MdKeyboardArrowRight /></div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col mx-3" style={{ textAlign: 'center' }}>
                                            <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                            <p>No Active projects</p>
                                        </div>

                                }
                            </div>
                        </div>
                    </section>

                    <section className='top-alt-new-ido-section-2 py-5'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col'>
                                    <div class="d-flex align-items-center mb-5 pe-lg-5" >
                                        <div>
                                            <h1 className='top-alt-new-ido-text-1-1'>Upcoming IDO</h1>
                                        </div>
                                        <div className='ms-auto'>
                                            <a href='/ido-launch'>View All <MdKeyboardArrowRight /></a>
                                        </div>
                                    </div>

                                    <div class="row row-cols-1 row-cols-md-3 g-4 mt-4">
                                        {
                                            futureData && futureData?.length > 0 ? (futureData).map((future) => {
                                                let showDate;
                                                let newDate = new Date();
                                                let fromDate = new Date(future.token_listing_from);
                                                let fromDateTimer = moment(future.token_listing_from);
                                                let timer = moment.duration(fromDateTimer.diff(currentTime))
                                                if (moment(fromDate).diff(newDate, "days") == 0) {
                                                    if (moment(fromDate).diff(newDate, "hours") == 0) {
                                                        if (moment(fromDate).diff(newDate, "minutes") >= 0) {
                                                            showDate = `${moment(fromDate).diff(newDate, "minutes")} MINUTES LEFT`;
                                                        }
                                                    } else {
                                                        showDate = `${moment(fromDate).diff(newDate, "hours")} HOURS LEFT`;
                                                    }
                                                } else {
                                                    showDate = `${moment(fromDate).diff(newDate, "days")} DAYS LEFT`;
                                                }
                                                return (
                                                    <div class="col new-launch-margin-cols">
                                                        <div class="card h-100">
                                                            <div className='position-relative card-img-top-new-sec-1' style={{ width: "fit-content" }}>
                                                                <img src={future.image} class="card-img-top" alt="ido-image" />
                                                                <div class="shape">
                                                                    <img src={Shape} alt="shape" />
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0 px-4 pb-0">
                                                                <h5 className='text-center mb-1 top-alt-new-ido-text-1 mt-3'>{future.projectName}</h5>
                                                                <div style={{ height: "80px" }}>
                                                                    <p className='text-center mb-4 top-alt-new-ido-text-2 px-2 opacity-75'>{future.projectInfo.slice(0, 100)}...</p>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Swap rate</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`1 ${future.token_symbol} = ${future.token_price} USDT`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Total Token</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${future.initial_supply}  ${future.token_symbol}`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-2'>
                                                                    <div className='col-5 pe-0 new-launch-caqrd-data-right'>
                                                                        <p className='top-alt-new-ido-text-3 mb-0'>Time to start</p>
                                                                    </div>
                                                                    <div className='col-7 ps-0 pe-1 new-launch-caqrd-data-left'>
                                                                        <p className='top-alt-new-ido-text-3-1 mb-0'>{`${timer.days()} D ${timer.hours()} H ${timer.minutes() > 0 ? timer.minutes() : 0} M ${timer.seconds() > 0 ? timer.seconds() : 0} S`}</p>
                                                                    </div>
                                                                </div>

                                                                <p className=' mb-0 fw-bold text-center mt-3'>Social</p>
                                                                <div className='d-flex flex-row justify-content-center gap-3 mt-1'>
                                                                    <a target='_blank' href={future.twitterLink} className='alt-fa-twitter-01-1'><FaXTwitter /></a>
                                                                    <a target='_blank' href={future.telegramGrpLink} className='alt-fa-twitter-01-1'><FaTelegram /></a>
                                                                </div>

                                                            </div>
                                                            <div className='card-footer'>
                                                                <div className='row text-center'>
                                                                    <div className='col'>
                                                                        <button type="button" onClick={() => handleClick(future._id, 'future')} class="top-alt-new-ido-button-1">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                futureData.length == 0 ?
                                    <div className="col mx-3" style={{ textAlign: 'center' }}>
                                        <img className="no-record-image" style={{ width: "70px", height: "auto" }} src={NoProject} />
                                        <p>No Upcoming projects</p>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </section>

                    <div className='container-fluid d-lg-block d-none'>
                        <div className='row'>
                            <div className='col-6 text-start'>
                                <img src={Group3} alt='Group3' className='floating-coin-img-3' />

                            </div>
                            <div className='col-6 text-end'>
                                <img src={Group4} alt='Group4' className='floating-coin-img-4' />
                            </div>
                        </div>
                    </div>

                    <div className='container '>
                        <div className='row text-center'>
                            <div className='col-6'>
                                <img src={Group2} alt='Group2' className='floating-coin-img-1' />

                            </div>
                            <div className='col-6'>
                                <img src={Group1} alt='Group1' className='floating-coin-img-2' />
                            </div>
                        </div>
                    </div>

                    <section className="top-alt-new-ido-section-2-4 py-5">
                        <div className="container-fluid ps-0">
                            <div className="container">
                                <div className="row align-items-center g-4">
                                    <div className='top-alt-new-ido-section-3-card-1 text-center py-5'>
                                        <div className='row '>
                                            <div className='col'>
                                                <h1 className="fw-bold mt-0 mb-3" style={{ letterSpacing: "1px" }}>APPLY FOR PROJECT INCUBATION</h1>
                                                <p className="ido-text-2 mt-0 mb-0">If you want to launch IDO, it will be your perfect choice</p>
                                                <div className='mt-4'>
                                                    <a href='/ido-form'><button className='top-alt-new-ido-button-1' style={{ letterSpacing: "1px" }}>APPLY TO LAUNCH</button></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;

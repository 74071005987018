import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { GoChevronLeft } from 'react-icons/go';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { RiArrowDownSFill } from 'react-icons/ri';
// import { BsLink45Deg, BsFillArrowRightCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import {
  Grid,
  Card,
  CardContent,
} from '@mui/material';
// import { IoClose } from "react-icons/io5";
import $ from "jquery";

import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";

import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";

import { toast } from "../core/lib/toastAlert";
import notFound from "../assets/images/file.png"
// import Depositimage from "../assets/images/deposit/Group.png";
import noreplay from "../assets/images/deposit/no-re.png";

// import ReactTooltip from "react-tooltip";
import { dateFormat, shortAdrress } from '../core/helper/date-format';
import { useContextData } from '../core/context/index'
// import { TbUpload } from 'react-icons/tb';
// import { useFileUpload } from "use-file-upload";
import * as yup from 'yup';
import { useFormik } from 'formik';
import DefaultUpload from "../assets/images/cloud+upload+file.png";
import { pageAllowCheck, clickNavigate } from "../core/helper/common";
import axios from 'axios';
import "../assets/style.css";
import Accorplus from '../assets/images/accor-plus.png';
import Accormin from '../assets/images/accor-min.png';
import Collapse from 'react-bootstrap/Collapse';
import { Form, FormGroup, Label, Input } from "reactstrap";
import DataTable from 'react-data-table-component';



const validationSchema = yup.object({
  amount: yup
    .string()
    .typeError('Please enter the valid amount')
    .required('Amount is required')
    .test(
      "maxDigitsAfterDecimal",
      "Please enter the valid amount",
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    ),
});

export default function WithdrawFiat(props) {
  const [openbank, setOpenbank] = useState(true);
  const defaultSrc = DefaultUpload;
  const navigate = useNavigate();
  const { myProfile, siteSettings, ipAddress } = useContextData();
  const [withdrawshow, setWithdrawshow] = useState(0);
  const [fee, setFeevalue] = useState(0);
  const [feeDetail, setFeeDetail] = useState({});
  const [userbalance, setUserBalance] = useState(0);
  const [withdrawfiatList, setWithdrawFiatList] = useState([]);
  const [particularCurrencyList, setparticularCurrencyList] = useState({});
  const [fiat, setFiat] = useState('');
  const [currencies, setCurrencies] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [bankpaymentsList, setbankpaymentsList] = useState([]);
  const [bankDetails, setbankDetails] = useState({});
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [searchval, setSearchval] = useState("");
  const [fillteredData, setFillteredData] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [timer_resendOtp, setTimer_resendOtp] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loader, setLoader] = useState(false);

  const [levelBasedLimit, setLevelBasedLimit] = useState({});
  const [withdrawLevDet, setWithdrawLevDet] = useState({});
  const [currencyId, setCurrencyId] = useState(null);
  const [particularcurrency, set_particularCurrency] = useState([]);
  const [withdrawDet, setWithdrawDet] = useState({});
  const [fiatNetwork, setFiatNetwork] = useState('');
  const [enteredFee, setEnterdFee] = useState(0);
  const [showCalculateFee, setShowCalculateFee] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalorders, settotalorders] = useState(0);


  useEffect(() => {
    timer_resendOtp > 0 && setTimeout(() => setTimer_resendOtp(timer_resendOtp - 1), 1000);
  }, [timer_resendOtp]);

  useEffect(() => {
    $(".crypto-workflow-section-close-button").click(function () {
      $(".deposit-second-section").hide();
    });
  }, []);

  useEffect(() => {
    getHistory();
  }, [selectedCurrency, currentPage, rowsPerPage]);

  useEffect(() => {
    getFiatCurrency();
    getWalletCurrency_func();
    getInrCurrency();
  }, [selectedCurrency]);

  useEffect(() => {
    getInrCurrency();
    getparticularCurrency_func();
    getHistory();
    initCall()
    getBankPaymentsDet();
  }, []);

  useEffect(() => {
    if (myProfile) {
      const pageAllowCheckResp = pageAllowCheck(myProfile, "final");
      if (pageAllowCheckResp.type == "error") {
        toast({ type: pageAllowCheckResp.type, message: pageAllowCheckResp.message });
        navigate(pageAllowCheckResp.navigate);
      }
      else {
        const data = { type: "withdraw", row: { currencySymbol: selectedCurrency } };
        clickNavigate(data, myProfile);
      }
    }
    getInrCurrency();
  }, [myProfile]);

  let columns = [];
  let filteredItems = [];

  filteredItems = withdrawfiatList.filter(item => {
    if (filterText) {
      return item.txnId && item.txnId.toLowerCase().includes(filterText.toLowerCase());
    } else {
      return item.txnId && item.txnId.toLowerCase().includes(filterText.toLowerCase()) || !item.txnId;
    }
  });

  columns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: 'Time (UTC)',
      width: '190px',
      selector: row => dateFormat(row?.createdDate)
    },
    {
      name: 'Type',
      width: '190px',
      selector: row => row?.type
    },
    {
      name: 'Assets',
      selector: row => (<>{row.currencyId.image ? <img src={row?.currencyId.image} alt={Config.SITENAME} width="20px" height="20px" className="img-fluid img-res" style={{ height: '20%', width: '20%' }} /> : ""} {""} {row?.currencyId.currencySymbol}</>),
      sortable: false
    },
    {
      name: 'Amount',
      selector: row => row?.amount,
      sortable: false
    },
    {
      name: 'Fees',
      selector: row => row?.fees,
      sortable: false
    },
    {
      name: 'UTR Number',
      width: '290px',
      selector: row => (
        <>
          {shortAdrress(row?.txnId !== "" ? row?.txnId : "-")}
        </>
      )
    },
    {
      name: 'Status',
      selector: row => row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing',
      sortable: false
    },

  ]



  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      }
    });
  };

  async function initCall() {
    try {
      const params = {
        url: `${Config.V2_API_URL}transaction/withdrawLevelDetail`,
        method: 'GET'
      }
      const response = (await makeRequest(params));
      if (response.status && response.data) {
        setWithdrawLevDet(response.data);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  async function getInrCurrency() {
    try {
      if (myProfile !== undefined && myProfile !== null && myProfile !== "") {
        const value = { currencySymbol: selectedCurrency }
        const params = {
          url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
          method: 'POST',
          body: value
        }
        const response = (await makeRequest(params));
        if (response.status) {
          setFiatNetwork(response.data?._id);
          setCurrencies(response.data);
          getFiatBalance(response?.data?.currencyId);
          setCurrencyId(response?.data?.currencyId);
          set_particularCurrency(response.data);
          getHistory();
          if (typeof response.data.withdrawLevel == 'object' && typeof response.data.withdrawLevel["level" + (myProfile && myProfile?.level)] == 'object') {
            const data = response.data.withdrawLevel["level" + (myProfile && myProfile.level)];
            setWithdrawDet(data);
          }
        }
      }
    } catch (err) {
      console.log("Something Wrong!", err);
    }
  }


  async function getFiatCurrency() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: { currencySymbol: selectedCurrency }
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setSelectedFiatCurrency(response.data);
      }
    } catch (err) {
      console.log("error");
    }
  }


  useEffect(() => {
    const filterCurrencyList = async () => {
      const searchString = searchval ? searchval.toString().toLowerCase() : "";
      if (!searchString) {
        return currencyList.filter(currency => currency.currencyType !== "Crypto" && currency.currencyType !== "Pi-Network");
      } else {
        return currencyList.filter(currency =>
          currency.currencySymbol.toLowerCase().includes(searchString) && currency.currencyType !== "Crypto" && currency.currencyType !== "Pi-Network"
        );
      }
    };

    if (currencyList.length > 0) {
      filterCurrencyList().then(filteredData => {
        // console.log("filteredData---",filteredData);
        const filteredINRData = filteredData.filter(currency => currency.currencySymbol);
        // console.log("filteredINRData---",filteredINRData);
        setFillteredData(filteredINRData);
      });
    } else {
      getWalletCurrency_func();
    }
  }, [searchval, currencyList]);


  async function getparticularCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: { currencySymbol: "BNB" }
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setparticularCurrencyList(response.data);
      }
    } catch (err) { }
  }

  async function getHistory() {
    try {
      const value = {
        type: "Withdraw",
        currencyType: "Fiat",
        currencySymbol: selectedCurrency,
        limit: rowsPerPage,
        offset: rowsPerPage * (currentPage - 1)
      }
      const params = {
        url: `${Config.V1_API_URL}wallet/getHistory`,
        method: 'POST',
        body: value
      }
      const response = (await makeRequest(params));
      if (response.status) {
        setWithdrawFiatList(response.data);
        settotalorders(response.total);
      } else {
        setWithdrawFiatList(0);
      }
    } catch (err) {
      console.log("something wrong!");
    }
  }

  async function handleCurrencyChange(currencySymbol) {
    window.history.pushState({}, '', window.origin + "/withdraw/fiat/" + currencySymbol);
    setSelectedCurrency(currencySymbol);
  }

  async function getWalletCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        const data = response.data;
        let currencyArray = [];
        if (Array.isArray(data)) {
          const orgData = data.filter((datas, i) => datas.curnType === "Fiat");
          data.map((datas, i) => {
            currencyArray.push({
              value: datas._id,
              currencySymbol: datas.currencySymbol,
              image: datas.image,
              currencyName: datas.currencyName,
              currencyType: datas.curnType
            });
          });
          setCurrencyList(currencyArray);
          setNetworkList(orgData)
        }
      }
    } catch (error) {
      console.log("something wrong!");
    }
  }

  async function submitWithdraw(values = {}, target = "submit") {
    let type = 'error';
    if (bankpaymentsList && bankpaymentsList.length == 0) {
      toast({ type, message: 'Have to Add the Bank details first' });
      navigate('/my/payment')
      return false;
    }
    if (bankDetails == "" || bankDetails && bankDetails._id == undefined) {
      toast({ type, message: 'Please select the account number' });
      return false;
    }
    if (!selectedFiatCurrency || !selectedFiatCurrency.currencySymbol) {
      toast({ type, message: "Please select a coin" });
      return;
    }
    console.log("bankDetails", bankDetails);
    if (values.amount <= userbalance) {
      let data = {
        currencyId: fiatNetwork, //fiat,
        amount: values.amount,
        paymentId: bankDetails?._id,
        accountNo: bankDetails?.accountNo,
        userId: myProfile?._id
      }
      if (withdrawshow != 0 && target !== "resendOTP") {
        data.withdrawOTP = values.withdrawOTP ? values.withdrawOTP : "-";
        data.tfaCode = values.tfaCode;
      }
      data.ipDetails = ipAddress;
      const params = {
        url: `${Config.V1_API_URL}wallet/submitWithdraw`,
        method: 'POST',
        body: data
      }
      console.log("params", params);
      setisLoading(true);
      const response = (await makeRequest(params));
      console.log("response", response);
      setisLoading(false);
      if (response.status) {
        type = 'success';
        setWithdrawshow(response.type)
        if (response.type == 3) {
          formik.resetForm();
          setShowCalculateFee(false)
          setWithdrawshow(0)
          getHistory();
          initCall();
          getInrCurrency();
        }
        if (withdrawshow == 0 || target === "resendOTP") {
          setTimer_resendOtp(Config.timer.resendOtp);
        }
      } else {
        const errmsg = response.message.split(' ');
        console.log("errmsg", errmsg);
        console.log("response.type", response.type);
        if (response.type == 4 && (errmsg[2] == 'TFA' || errmsg[2] == '2FA')) {
          navigate('/google-authenticator')
        } else if (response.type == 4 && errmsg[2] == 'KYC') {
          navigate('/my/identification')
        } else if (response.type == 4 && errmsg[1] == 'BANK') {
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }
      console.log("out");
      toast({ type, message: response.message });
    }
    else {
      toast({ type, message: 'Insufficient Balance on ' + currencies.currencySymbol });
    }
  }

  const formik = useFormik({
    initialValues: {
      amount: '',
      withdrawOTP: '',
      tfaCode: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submitWithdraw(values);
    },
  });

  async function getBankPaymentsDet() {
    try {
      const params = {
        url: `${Config.V2_API_URL}customer/getBankPayments`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response) {
        const accData = response.data;
        if (accData.length > 0) {
          const isActiveAcc = accData.some(bnkAcc => bnkAcc.status == 1);
          const isBankDisable = accData.some(bnkAcc => bnkAcc.status == 5);
          const isBankPending = accData.some(bnkAcc => bnkAcc.status == 0);
          const isBankRejected = accData.some(bnkAcc => bnkAcc.status == 2);
          if (!isActiveAcc) {
            if (isBankDisable) {
              toast({ message: 'Your Bank Account Currently Disabled. Please Enable Your Bank Account' });
              setTimeout(() => navigate("/my/payment"), 2000);
              return
            };
            if (isBankPending) {
              toast({ message: 'Your Documents are Waiting for Approval...!' });
              setTimeout(() => navigate("/my/payment"), 2000);
              return
            };
            if(isBankRejected) {
              toast({ message: 'Your Bank Account Currently Rejected. Please Enable Your Bank Account' });
              setTimeout(() => navigate("/my/payment"), 2000);
              return
            }
          } else {
            const actBank = accData.filter((datas) => datas.status == 1);
            setbankDetails(actBank[0]);  // Set the first bank detail as bankDetails
            setbankpaymentsList(actBank);
          };
        } else {
          let type = 'error';
          toast({ type, message: 'Have to Add the Bank details first' });
          setTimeout(() => navigate("/my/payment"), 2000);
        };
      };
    } catch (err) {
      console.error("Error fetching bank payments:", err);
    };
  };

  async function handleBankChange(type) {
    setbankDetails(type);
  };

  async function getFiatBalance(currencyId) {
    try {
      const value = { currencyId: currencyId }
      const params = {
        url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
        method: 'POST',
        body: value
      }
      const result = (await makeRequest(params));
      if (result.status) {
        var amount = result.data.amount;
        setUserBalance(amount)
      }
    } catch (error) {
      console.log("something Went Wrong!");
    }
  }

  useEffect(() => {
    let levelBasedLimit_copy = Object.assign({}, levelBasedLimit);
    if (myProfile && myProfile.level) {
      levelBasedLimit_copy.level = myProfile.level;
      if (withdrawLevDet && withdrawLevDet.totalDailyVolume != undefined) {
        levelBasedLimit_copy.totalDailyVolume = withdrawLevDet.totalDailyVolume;
        levelBasedLimit_copy.totalMonthlyVolume = withdrawLevDet.totalMonthlyVolume;
      }
      console.log('levelBasedLimit_copy', levelBasedLimit_copy)
      if (siteSettings && siteSettings.withdrawLevel) {
        levelBasedLimit_copy.limit = siteSettings.withdrawLevel['level' + myProfile.level];
      }
      setLevelBasedLimit(levelBasedLimit_copy);
    }
  }, [myProfile, siteSettings, withdrawLevDet]);

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
  }


  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6" onClick={() => navigate("/my/dashboard")}>
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Withdraw Fiat</span>
              </div>
              <div className="col-6 text-end" onClick={() => navigate("/withdraw/crypto/" + particularCurrencyList?.currencySymbol)}>
                <button type="button" className="deposit-fiat-button">
                  Withdraw Crypto <HiArrowNarrowRight />
                </button>
              </div>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              {withdrawshow == 0 ?
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-lg-12">
                    <div className=" mt-5">
                      <div className="">
                        <div className="withdraw-nav-tabs">
                          {/* <div className="deposit-third-right-section mb-4">
                            <span className="deposit-text-33">Bank Name</span>
                            <input
                              type="text"
                              className="form-control"
                              id="bankname"
                              name='bankname'
                              autoComplete='off'
                              value={myProfile?.bankdetails?.['Bank Name']}
                              disabled={true}
                            />
                          </div> */}
                          <div className="d-flex flex-lg-row flex-column mb-3">
                            <div className="deposit-third-right-section">
                              <span className="deposit-text-33 password-text-44  color-blue mb-2">Coin</span>
                              <div
                                className="d-flex flex-row align-items-center deposit-select-coin-section"
                                data-bs-toggle="modal"
                                data-bs-target="#selectcoinModal1"
                              >
                                <div className="">
                                  {selectedFiatCurrency && selectedFiatCurrency.image ?
                                    <img
                                      src={selectedFiatCurrency && selectedFiatCurrency.image}
                                      className="deposit-logo-image"
                                      alt="logo"
                                    /> : "Select Coin"}
                                  <span key={'i'} className="deposit-text-4"> {selectedFiatCurrency && selectedFiatCurrency.currencySymbol} </span>
                                </div>
                                <div className="ms-auto">
                                  <RiArrowDownSFill />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="deposit-third-right-section">
                            <span className="deposit-text-33 password-text-44  color-blue mb-2">
                              Account Number
                            </span>
                            <div
                              className="d-flex flex-row align-items-center deposit-select-coin-section"
                              data-bs-toggle="modal"
                              data-bs-target="#selectcoinModal"
                            >
                              <div className="">
                                <span className="deposit-text-4">{bankDetails.accountNo ? bankDetails.accountNo : "Select Your Account No"}</span>
                              </div>
                              <div className="ms-auto">
                                <RiArrowDownSFill />
                              </div>
                            </div>
                          </div>
                          <div className="deposit-third-right-section my-4">
                            <span className="deposit-text-33 password-text-44  color-blue mb-2">Enter Amount</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Amount"
                              id="amount"
                              name='amount'
                              autoComplete='off'
                              value={formik.values.amount}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                const piFee = withdrawDet?.fees.toFixed(currencies?.siteDecimal);
                                const regex = /^[0-9.]*$/;
                                const amount = e.target.value;
                                const isTrue = regex.test(amount);
                                const hasMultipleDots = (amount.match(/\./g) || []).length > 1;
                                if (isTrue && !hasMultipleDots) {
                                  formik.handleChange(e);
                                  if (withdrawDet.feetype == 1) {
                                    setShowCalculateFee(true);
                                    const totalFee = (piFee / 100) * amount;
                                    setEnterdFee(totalFee)
                                  }
                                }
                                if (isTrue && amount == 0) {
                                  setShowCalculateFee(false);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              error={formik.touched.amount && Boolean(formik.errors.amount)}
                              helperText={formik.touched.amount && formik.errors.amount}
                            />
                            {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                          </div>

                          <button type="submit" className="btn btn-col w-100 my-4 alt-submit-txt-btn" disabled={isLoading}>
                            Submit Withdraw Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                :
                <form onSubmit={formik.handleSubmit} className="">
                  <div className="col-lg-12">
                    <div className="d-flex flex-lg-row flex-column mt-5">
                      <div className="">
                        {/* <span className="deposit-text-3">Send to</span> */}
                      </div>
                      <div className="">
                        <div className="withdraw-nav-tabs">
                          <div className="deposit-third-right-section mb-4">
                            <div className="row">
                              <span className="deposit-text-33">Enter E-mail OTP</span>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="withdrawOTP"
                                  name='withdrawOTP'
                                  autoComplete='off'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.withdrawOTP}
                                  error={formik.touched.withdrawOTP && Boolean(formik.errors.withdrawOTP)}
                                  helperText={formik.touched.withdrawOTP && formik.errors.withdrawOTP}
                                  required
                                />
                                <button
                                  id="button-addon2"
                                  className="btn btn-phone-number-verification-code color-blue"
                                  type="button"
                                  disabled={(isLoading || timer_resendOtp > 0) ? true : false}
                                  onClick={() => {
                                    submitWithdraw(formik.values, "resendOTP");
                                  }}
                                >
                                  Resend OTP{timer_resendOtp > 0 ? <span className="timeLeft">({timer_resendOtp}s)</span> : ""}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="deposit-third-right-section mb-4">
                            <span className="deposit-text-33">Enter 2FA Code</span>
                            <input
                              type="text"
                              className="form-control"
                              id="tfaCode"
                              name='tfaCode'
                              autoComplete='off'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.tfaCode}
                              error={formik.touched.tfaCode && Boolean(formik.errors.tfaCode)}
                              helperText={formik.touched.tfaCode && formik.errors.tfaCode}
                              required
                            />
                          </div>
                          <button type="submit" disabled={isLoading} className="btn btn-col w-100 mt-4 alt-submit-txt-btn">
                            Withdraw amount
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              }


              <div className="row mt-4">
                <div className="col-6">
                  <span className="deposit-text-5">{currencies && currencies.currencySymbol} balance</span>
                  <br />
                  <span className="deposit-text-66">{userbalance?.toFixed(currencies?.siteDecimal)} {currencies?.currencySymbol}</span>
                </div>
                <div className="col-6">
                  <span className="deposit-text-5">
                    Minimum withdrawal
                  </span>
                  <br />
                  <span className="deposit-text-66">{withdrawDet.minwithamt ? withdrawDet.minwithamt?.toFixed(currencies.siteDecimal) : "--"} {currencies && currencies.currencySymbol}</span>
                </div>
              </div>

              <div className="row mt-3">
                {
                  levelBasedLimit && levelBasedLimit.totalDailyVolume != undefined && levelBasedLimit.limit && levelBasedLimit.limit.dailyVolume != undefined ?
                    <div className="col-6">
                      <span className="deposit-text-5">
                        24h remaining limit
                      </span>
                      <br />
                      <span className="deposit-text-66">
                        {
                          levelBasedLimit.limit.dailyVolume == 0
                            ?
                            "No limit"
                            :
                            (levelBasedLimit.limit.dailyVolume - levelBasedLimit.totalDailyVolume)?.toFixed(2) + " USD/" + levelBasedLimit.limit.dailyVolume + " USD"
                        }
                      </span>
                    </div>
                    : ""
                }
                {
                  levelBasedLimit && levelBasedLimit.totalMonthlyVolume != undefined && levelBasedLimit.limit && levelBasedLimit.limit.monthlyVolume != undefined
                    ?
                    <div className="col-6">
                      <span className="deposit-text-5">
                        Monthly remaining limit
                      </span>
                      <br />
                      <span className="deposit-text-66">
                        {
                          levelBasedLimit.limit.monthlyVolume == 0
                            ?
                            "No limit"
                            :
                            (levelBasedLimit.limit.monthlyVolume - levelBasedLimit.totalMonthlyVolume)?.toFixed(2) + " USD/" + levelBasedLimit.limit.monthlyVolume + " USD"
                        }
                      </span>
                    </div> : ""
                }
              </div>

              <div className="row mt-3">
                <div className="col-6">
                  <span className="deposit-text-5">Withdraw fee</span>
                  <br />
                  <span className="deposit-text-66">
                    {!showCalculateFee ?
                      (withdrawDet && withdrawDet.fees) ?
                        withdrawDet.feetype === "0" ?
                          parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                          :
                          parseFloat(withdrawDet.fees?.toFixed(currencies?.siteDecimal))
                        :
                        0
                      : enteredFee?.toFixed(currencies?.siteDecimal) + " INR"
                    }
                    {!showCalculateFee ?
                      withdrawDet.feetype === "0" ?
                        " INR"
                        :
                        withdrawDet.feetype === "1" ?
                          " %" : ""
                      : null
                    }
                  </span>
                </div>
              </div>
              {
                particularcurrency.withdrawNotes && particularcurrency.withdrawNotes != "NA" ?
                  <div className="row mt-3">
                    <div className="col-12">
                      <span className="deposit-text-5">
                        Notes
                      </span>
                      <br />
                      <span className="deposit-text-66">
                        {particularcurrency.withdrawNotes}
                      </span>
                    </div>
                  </div>
                  : ""
              }
            </div>

            <div className="col-lg-7 pt-lg-5">
              <Grid item xs={12} sm={6} className="text-left-move">
                <div>
                  <p className=' password-text-44 color-blue'>The amount would be transferred to
                  </p>
                  <p className='password-text-33'>
                    Your FIAT withdrawal will be sent to the selected account. Please double-check that the provided account details are correct.
                  </p>
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  <p className="mb-0">Bank Details</p>
                  <div className="ms-auto">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenbank(!openbank)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openbank}
                    >
                      {openbank ? (
                        <img src={Accormin} alt="Accormin" style={{ width: "20px" }} />
                      ) : (
                        <img src={Accorplus} alt="Accorplus" style={{ width: "20px" }} />
                      )}
                    </a>
                  </div>
                </div>

                <Collapse in={openbank}>
                  <div id="example-collapse-text">
                    {!isObjectEmpty(bankDetails) ? (
                      <div className='ui-card m-top-21 mb-4'>
                        <Card className='bg-white-card '>
                          <CardContent className='color-black'>
                            {bankDetails?.holderName && <p className="password-text-33"><b>Name: </b>{bankDetails?.holderName}</p>}
                            {bankDetails.accountNo && <p className="password-text-33"><b>Account Number: </b>{bankDetails.accountNo}</p>}
                            {bankDetails.bankName && <p className="password-text-33"><b>Bank Name: </b>{bankDetails.bankName}</p>}
                            {bankDetails.ifscCode && <p className="password-text-33"><b>IFSC Code: </b>{bankDetails.ifscCode}</p>}
                            {bankDetails.accountType && <p className="password-text-33"><b>Account Type: </b>{bankDetails.accountType}</p>}
                          </CardContent>
                        </Card>
                      </div>
                    ) : (
                      <div className="justy-content-center align-items-center">
                        <img src={notFound} alt="order-notfound" style={{ width: 70, height: 70 }} />
                        <p>No Active Bank Account Found!</p>
                      </div>
                    )
                    }
                  </div>
                </Collapse>


                <div>
                  <div className='mt-4'>
                    <ul className='mt-12'>
                      <li className='password-text-33'>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this account</li>
                      <li className='password-text-33'> UPI Apps like gPay, PhonePe, Paytm are not available.</li>
                      <li className='password-text-33'> {selectedCurrency == "INR" ? "INR" : "EUR"} Deposit and Withdrawal take upto 24 hours to Process</li>
                    </ul>
                    <div>
                    </div>
                  </div>
                </div>
              </Grid>

            </div>



            <div
              className="modal fade withdraw-crypto-modal"
              id="selectcoinModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              data-bs-backdrop="static"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header ">
                    <h4 className="modal-title d-flex gap-1" id="exampleModalLabel">
                      <p class="border-line-styling mb-0 mt-1" style={{ height: "20px" }}></p>
                      Select Account number
                    </h4>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body modal-2-overflow-scroll">
                    <ul className="withdraw-crypto-modal-list">
                      {
                        bankpaymentsList.length > 0 ? bankpaymentsList.map((row, i) => {
                          return (
                            <li key={i} onClick={() => handleBankChange(row)}>
                              <a data-bs-dismiss="modal">
                                <div class="d-flex px-3 py-2 mb-3">
                                  <div class="">
                                    <span class="text-muted scrollspy-tops-heading-paragraph">
                                      Name</span>
                                    <p class="mb-0 scrollspy-tops-heading-paragraph">
                                      {
                                        row.holderName ?
                                          row.holderName
                                          : ""
                                      }
                                    </p>
                                  </div>
                                  <div class="ms-auto text-end">
                                    <span class="text-muted scrollspy-tops-heading-paragraph">Account Number</span>
                                    <p class="mb-0 scrollspy-tops-heading-paragraph">
                                      {
                                        row.accountNo ?
                                          " - " + row.accountNo
                                          : ""
                                      }
                                    </p>
                                  </div>
                                  <div class="ms-auto text-end">
                                    <span class="text-muted scrollspy-tops-heading-paragraph">IFSC Code</span>
                                    <p class="mb-0 scrollspy-tops-heading-paragraph">
                                      {
                                        row.ifscCode ?
                                          " - " + row.ifscCode
                                          : ""
                                      }
                                    </p>
                                  </div>

                                </div>
                              </a>
                            </li>
                          )
                        }) : (<>
                          <div className="justy-content-center align-items-center d-flex flex-column gap-2 mt-5">
                            <img src={notFound} alt="order-notfound" style={{ width: 70, height: 70 }} />
                            <p>No Active Bank Account Found!</p>
                          </div>
                        </>)
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container deposit-fourth-section mb-5">
          <div className="row align-items-center">
            <div className="col-lg-12 mt-3">
              <h1 className="deposit-text-11 color-blue">Recent Withdraw</h1>
              <div className="col-lg-5 mt-3">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Search</Label>
                    <Input
                      id="search"
                      type="text"
                      placeholder="Filter By Transaction id"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                persistTableHead
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalorders}
                paginationRowsPerPageOptions={[5, 10, 15]}
                onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                onChangePage={(page) => setCurrentPage(page)}
                noDataComponent={
                  <div className="text-center">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                    <br />
                    <span className="text-center">No Records Found</span>
                  </div>
                }
              />
            </div>
          </div>
        </div>

      </div>

      <div
        className="modal fade deposit-crypto-modal"
        id="selectcoinModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header ">
              <h4 className="modal-title" id="exampleModalLabel">
                Select coin to withdraw
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-2-overflow-scroll">
              <input
                id="myInput2"
                type="text"
                placeholder="Search here..."
                autoComplete="off"
                name="search"
                onChange={(e) => setSearchval(e.target.value)}
              />
              <ul id="myUL2" >
                {fillteredData.length > 0 ? (<>
                  {fillteredData.map((currency, i) => {
                    if (currency.curnType != 'Crypto') {
                      return (
                        <li onClick={() => handleCurrencyChange(currency.currencySymbol)} key={i + 1}>
                          <a
                            data-bs-dismiss="modal"
                            className="deposit-modal-list-sizing"
                          >
                            <div className="row deposit-modal-row-sizing">
                              <div className="col-1">
                                <img
                                  src={currency.image}
                                  alt="logo"
                                  className="me-1 deposit-modal-coin-images"
                                />
                              </div>
                              <div className="col">
                                <div className="row ps-2">
                                  <span className="deposit-modal-coin-heading">
                                    {currency.currencySymbol}
                                  </span>
                                  <span className="deposit-modal-coin-muted-text ">
                                    {currency.currencyName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      )
                    }
                  })}
                </>
                ) : (
                  <>
                    <p className="text-center">No Coin Found</p>
                  </>
                )
                }
              </ul>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSessionLogout } from './helper/common';
import { deleteCookie } from './helper/cookie';

const AutoLogout = () => {
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(logoutUser, 30 * 60 * 1000); 
  };

  const logoutUser = async() => {
    await userSessionLogout();
    deleteCookie("userToken");
    navigate('/login'); 
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown','keypress','keyup', 'click', 'scroll'];
    events.forEach((event) => window.addEventListener(event, resetTimer));
    resetTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return null;
};

export default AutoLogout;

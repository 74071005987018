// import package
import React, { useEffect, useState } from 'react';

// import lib
import { unrealizedPnLPerc } from '../../../core/helper/bybit/usdtPerpetual';

const UnrealizedPnlCross = (props) => {
    const { positionDoc, entryPrice, pairDetails, pair, pairDetailsArr } = props;

    // state
    const [pnl, setPnl] = useState(0);
    const [pairData, setPairData] = useState([]);
    // function
    useEffect(() => {
        let findDoc = pairDetailsArr.find((pair) => pair.pair == positionDoc.pairName);
        if (findDoc) setPairData(findDoc);
    }, [pairDetailsArr]);
    useEffect(() => {
        
        setPnl(unrealizedPnLPerc(
            entryPrice,
            positionDoc.totalAmount,
            pairData.marketPrice,
            positionDoc.leverage,
            pairData.takerFee,
            positionDoc.type,
            positionDoc.filled,
        ));
    }, [positionDoc, entryPrice, pairData]);

    return (
        <small className={(pnl >=0) ? "positiveVal" : "negativeVal"}>
            ({props.decimalValue(pnl, pair.decimalValue)}%)
        </small>
    )
}

export default UnrealizedPnlCross
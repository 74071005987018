import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { deleteCookie, getCookie } from "./core/helper/cookie";
import { makeRequest } from "./core/services/v1/request";
import Config from "./core/config/index";
import { AESDecrypt } from "./core/helper/AESDecrypt";
import { useContextData } from './core/context';
import { userSessionLogout } from "./core/helper/common";
import SiteLoader from "./Pages/SiteLoader";

const ProtectedRoute = (props) => {
  const { authChk } = props;
  const navigate = useNavigate();
  const [isAccess, setIsAccess] = useState(null);
  const { setUserProfile } = useContextData();

  const fetchToken = async () => {
    try {
      const params = {
        url: `${Config.V1_API_URL}user/get-access`,
        method: "POST",
      };
      const response = await makeRequest(params);
      if (authChk == "afterLoginOnly") {
        if (response.status) {
          setTimeout(() => setIsAccess(true), 2000);
        } else {
          await userSessionLogout();
          deleteCookie('userToken');
          setUserProfile();
          setTimeout(() => setIsAccess(false), 2000);
          navigate("/login")
        };
      } else {
        if (response.status) {
          setTimeout(() => setIsAccess(true), 2000);
        } else {
          await userSessionLogout();
          deleteCookie('userToken');
          setUserProfile();
          setTimeout(() => setIsAccess(true), 2000);
        };
      };
    } catch (error) {
      console.log("error:", error.message);
    };
  };

  useEffect(() => {
    fetchToken();
  }, [props]);

  if (isAccess === null) {
    return <SiteLoader />
  };

  return <React.Fragment>
    {
      isAccess ? props.children : null
    }
  </React.Fragment>
};

export default ProtectedRoute;
import React, { useEffect, useState } from "react";
import "../../assets/style.css";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import $ from "jquery";
import { BsArrowRight } from "react-icons/bs";
import { HiSpeakerphone } from "react-icons/hi";
import {
  AiFillEye,
  AiOutlineSearch,
  AiFillCaretDown,
  AiFillInfoCircle,
  AiFillEyeInvisible,
} from "react-icons/ai";
import { BiCaretDown } from "react-icons/bi";
import { BsXCircleFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import Multiselect from "multiselect-react-dropdown";
import Config from "../../core/config/index";
import { useContextData } from "../../core/context/index";
import axios from "axios";
import moment from "moment";
import { format } from 'date-fns';
import { toast } from "../../core/lib/toastAlert";
import { makeRequest } from "../../core/services/v1/request";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { useNavigate } from "react-router-dom";
// import { useTable, useSortBy } from 'react-table';	
function refreshPage() {
  window.location.reload();
}

export default function Simpleearn(props) {
  const navigate = useNavigate();
  const [columns2, setcolumns2] = useState([]);
  const [earnType, setEarnType] = useState("Fixed")
  const [currencymodal, setcurrencymodal] = useState([{ currencyimage: "" }]);
  const [rewardsapr, setrewardsapr] = useState([]);
  const { myProfile } = useContextData();
  const [userbalance, setuserbalance] = useState(0);
  const [stakeearnamt, setstakeearnamt] = useState("");
  const [daysapr, setdaysapr] = useState("");
  const [numberofdaysapr, setnumberofdaysapr] = useState("");
  const [inputbox, setinputbox] = useState("FALSE");
  const [Subscriptionenddate, setSubscriptionenddate] = useState("");
  const [estimatedValue, setEstimatedValue] = useState({});
  const [estinterest, setestinterest] = useState('0');
  const [totalearnprofit, settotalearnprofit] = useState('0')
  const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
  const [message, setMessage] = useState('');
  const [currentAPR, setCurrentAPR] = useState(0);
  const [CurrentAPR_id, setCurrentAPR_id] = useState(0);
  const [currentAprArr, setCurrentAprArr] = useState([]);
  const [CurrentAPRdays, setCurrentAPRdays] = useState(1);
  const [sortField, setSortField] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');
  const [minAmt, setMinAmt] = useState(null);
  const [maxAmt, setMaxAmt] = useState(null);
  const [aprPercentage, setAprPercentage] = useState(0);
  const [selectedAPR, setSelectedAPR] = useState({});
  const currentDate = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        estimateINR = 0,
        estimateUSD = 0,
        estimateSimpleEarnUSD = 0,
      } = response;
      setEstimatedValue({
        estimateINR,
        estimateUSD,
        estimateSimpleEarnUSD,
      });
    }
  }

  const columns = [
    {
      id: 0,
      name: "symbol",
      selector: (row) => <img className="simpleearn-table-coin-img" src={row.currencyimage} />,
      reorder: true,
      width: "10%",
    },
    {
      id: 1,
      name: "Coin",
      selector: (row) => row.currencysymbol,
      sortable: true,
      reorder: true,
      width: "15%",
    },
    earnType === "Fixed" ?
      {
        id: 2,
        name: "APR",
        selector: (row) => selectedAPR[row._id]?.apr || "",
        sortable: false,
        reorder: true,
        width: "20%",
      } : {
        id: 2,
        name: "Earn Type",
        selector: (row) => "Flexible",
        sortable: true,
        reorder: true,
        width: "15%",
      },
    earnType === "Fixed" ?
      {
        id: 3,
        name: "Duration(Days)",
        selector: (row) => {
          const rewardspr = row.rewardsapr;
          return (
            <ul
              class="simpleearn-table-tabs-duration nav nav-pills gap-2"
              id="pills-tab"
              role="tablist"
            >
              {rewardspr.map((ids, index) => (
                <li className="nav-item" role="presentation" key={index}>
                  <button
                    className={index === 0 ? "nav-link active" : "nav-link"}
                    id={`pills-duration${index + 1}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-duration${index + 1}`}
                    type="button"
                    role="tab"
                    aria-controls={`pills-duration${index + 1}`}
                    aria-selected="false"
                    onClick={() => {
                      aprselect(ids.days, ids.apr, ids._id, row.currencyId);
                      setSelectedAPR((prev) => ({ ...prev, [row._id]: ids }));
                    }}
                  >
                    {ids.days}
                  </button>
                </li>
              ))}
            </ul>
          );
        },
        sortable: false,
        reorder: true,
        width: "30%",
      } : {
        id: 3,
        name: "APR Percentage",
        selector: (row) => {
          return (
            row.aprPercentage
          )
        },
        sortable: false,
        reorder: true,
        width: "30%",
      },
    {
      id: 4,
      name: "",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn-simpleearn-filter-table"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => subscribeearn(row)}
          >
            Subscribe
          </button>
        </div>
      ),
      sortable: false,
      reorder: true,
      width: "15%",
    },
  ];


  const handleSort = (fieldId) => {

    if (sortField === fieldId) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(fieldId);
      setSortDirection('asc');
    }
    // Perform sorting based on the selected column and direction	

    const sortedData = [...columns2].sort((a, b) => {

      const column = columns;
      console.log('Sorted column:', column[0]);
      if (!column[0]) return 0; // Handle invalid column ID gracefully	
      const aValue = column[0].selector(a);
      const bValue = column[0].selector(b);
      const aValueString = aValue.toString();
      const bValueString = bValue.toString();
      if (aValueString < bValueString) return sortDirection === 'asc' ? -1 : 1;
      if (aValueString > bValueString) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setcolumns2(sortedData);
  };
  const aprselect = async (rowId, days, apr, _id, currencyId) => {
    columns2.map((row) => {
      let data = [];
      if (row.currencyId === currencyId) {

        const updatedRewards = row.rewardsapr.map((item, index) => {

          if (item.days === days) {
            setCurrentAPR(apr);
            setCurrentAPR_id(index);
            const filters = currentAprArr.filter((datas) => datas.aprId == row.currencyId);
            if (filters.length == 0) {
              data.push({
                aprId: row.currencyId,
                apr: apr
              })
              setCurrentAprArr(prevArray => [...prevArray, ...data])
              return { ...item, apr };
            } else {

              let index = currentAprArr.findIndex(obj => obj.aprId == row.currencyId);
              currentAprArr[index].apr = apr;
            }
          }
          return item;
        });

        return { ...row, rewardsapr: updatedRewards, setCurrentAPR: apr };

      }
      return row;

    });

  };
  const subscribeearn = async (subsc) => {
    console.log("subsc", subsc);
    setcurrencymodal(subsc);
    setMinAmt(subsc.minAmount);
    setMaxAmt(subsc.maxAmount);

    const userids = myProfile?._id;
    if (earnType === "Fixed") {
      const cur = new Date;
      const days = subsc.rewardsapr[0].days;
      const expDate = cur.setDate(cur.getDate() + days);
      setSubscriptionenddate(expDate);
      setrewardsapr(subsc.rewardsapr);
      setdaysapr(subsc.rewardsapr[0].apr);
      setnumberofdaysapr(subsc.rewardsapr[0].days);
    } else {
      setAprPercentage(subsc.aprPercentage);
      setestinterest("flex")
    }
    const userdetails = {
      userId: userids,
      currId: subsc.currencyId,
    };
    const getuserbal = await axios.post(
      `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getuserbal`,
      userdetails
    );
    const usramount = (getuserbal.data.data?.simpleEarnAmount)?.toFixed(8);
    setuserbalance(usramount);
  };

  const userbalancemax = async () => {
    setstakeearnamt(userbalance);
  };

  const aprselectchangemodal = async (event, days) => {
    const cur = new Date
    const expireDate = cur.setDate(cur.getDate() + days)
    setdaysapr(event);
    setnumberofdaysapr(days);
    setCurrentAPRdays(0);
    setSubscriptionenddate(expireDate);
    const stakettlrewards = ((((stakeearnamt * event) / 100) / 365) * days);
    setestinterest((stakettlrewards).toFixed(8))
  };
  const setinputamount = async (e) => {
    e.preventDefault();
    setstakeearnamt(e.target.value);
    const stakettlrewards = ((((e.target.value * daysapr) / 100) / 365) * numberofdaysapr);
    setestinterest((stakettlrewards).toFixed(8))
  };

  const clickinputbox = async () => {
    if (inputbox == "FALSE") {
      setinputbox("TRUE");
    } else {
      setinputbox("FALSE");
    }
  };



  const BuyNowClick = (event) => {
    var url = `${Config.FRONEND_URL}/spot/` + event + "_USDT";
    window.open(url, "_blank");
  };

  const searchbar = async (e) => {
    setMessage(e.target.value);
    if (e.target.value == "") {
      const requestData = {
        type: earnType
      }
      const axiosget = await axios.post(
        `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getfrontcurrency`, requestData
      );
      setcolumns2(axiosget.data.data);

    } else {
      const vals = e.target.value.toUpperCase();
      const searchedCurrency = columns2.filter((currency) =>
        currency.currencysymbol.includes(vals)
      );
      setcolumns2(searchedCurrency);
    }
  };
  const handleClick = () => {
    setMessage('');
    window.location.reload();
  };
  const gettotalearnprofit = async () => {
    const userearnid = myProfile?._id;
    const usergetearn = {
      userid: userearnid
    }
    const getuserearnbal = await axios.post(`${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getWalletCurrency`, usergetearn);
    const usdsval = getuserearnbal.data;
    if (usdsval.estimatep2pUSD > 0) {
      settotalearnprofit((usdsval.estimatep2pUSD).toFixed(2))
    }
  }

  const confirmstake = async () => {
    if (stakeearnamt == "") {
      let type = "error";
      toast({ type, message: "Enter amount" });
    } else if (inputbox == "FALSE") {
      let type = "error";
      toast({ type, message: "Agree to terms" });
    } else if (stakeearnamt < minAmt) {
      let type = "error";
      toast({ type, message: `Minimum amount should be ${minAmt}` });
    } else if (stakeearnamt > maxAmt) {
      let type = "error";
      toast({ type, message: `Maximum amount should be ${maxAmt}` });
    } else {
      const userids = myProfile?._id;
      let userdetails = {
        userId: userids,
        currId: currencymodal.currencyId,
        currencyImage: currencymodal.currencyimage,
        investAmount: stakeearnamt,
        currencysymbol: currencymodal.currencysymbol,
        currencyId: currencymodal.currencyId
      };
      if (earnType === "Fixed") {
        userdetails["aprPercentage"] = daysapr;
        userdetails["days"] = new Date(Number(Subscriptionenddate));
        userdetails["lockedDays"] = numberofdaysapr;
        userdetails["type"] = "Fixed";
      } else {
        userdetails["type"] = "Flexible";
        userdetails["aprPercentage"] = aprPercentage;
      }
      if (userdetails) {
        const getearnsucess = await axios.post(
          `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/userenterearn`,
          userdetails
        );
        if (getearnsucess.data.status === "True") {
          let type = "Success";
          toast({ type, message: getearnsucess.data.message });
          window.location.href = "/account-earn"
        } else {
          let type = "Error";
          toast({ type, message: getearnsucess.data.message });
        }
      }
    }
  };
  useEffect(() => {
    const requestData = {
      type: earnType
    }
    axios.post(
      `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getfrontcurrency`, requestData
    )
      .then((response) => {
        setcolumns2(response.data.data);
        const tableData = response.data.data;
        if (Array.isArray(tableData)) {
          tableData.forEach((row) => {
            if (row.rewardsapr && row.rewardsapr.length > 0) {
              setSelectedAPR((prev) => ({
                ...prev,
                [row._id]: row.rewardsapr[0]
              }));
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, [earnType]);

  useEffect(() => {
    gettotalearnprofit();
    getWalletCurrency();
  }, [myProfile, earnType]);
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="simpleearn-top-banner-section finance-active-nav-class">
        <div className="simpleearn-top-value-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div class="d-flex flex-lg-row flex-column align-items-center">
                  <div>
                    <p className="simpleearn-text-1">Simple Earn</p>
                    <p className="simpleearn-text-2">
                      The simple way to Deposit & Earn
                    </p>
                  </div>
                  <div class="ms-auto">
                    <button class="btn-simpleearn-filter-table border-0" onClick={() => navigate("/account-earn")}>History</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="card">
                  <div className="d-flex flex-row">
                    <div className="ms-auto">
                      {balShow ? (
                        <AiFillEye onClick={() => balShowHideCall()} />
                      ) : (
                        <AiFillEyeInvisible onClick={() => balShowHideCall()} />
                      )}
                    </div>

                  </div>
                  <div>
                    {balShow ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '20px' }}>
                          <p className="simpleearn-text-5 mb-1">Total Balance</p>
                          <b className="border-dot password-text-44">
                            {estimatedValue.estimateSimpleEarnUSD
                              ? estimatedValue.estimateSimpleEarnUSD.toFixed(2)
                              : 0}{" "}
                            $
                          </b>
                        </span>
                        <span>
                          <p className="simpleearn-text-5 mb-1">Cumulative Profit</p>
                          <b className="password-text-44">
                            ≈ $
                            {totalearnprofit}
                          </b>
                        </span>
                      </div>
                    ) : (
                      <p>***Balance hidden***</p>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-5 simpleearn-top-filter-section">
          <div className="row g-2">
            <div className="col-lg-4 col-12">
              <div class="input-group">
                <span
                  class="input-group-text input-group-text-1"
                  id="basic-addon1"
                >
                  <AiOutlineSearch />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => searchbar(e)}
                  value={message}
                />

                <span
                  class="input-group-text input-group-text-2"
                  id="basic-addon1"
                  onClick={handleClick}
                >
                  <BsXCircleFill style={{ cursor: "pointer" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div>
            <button class="fixedOrFlex" onClick={() => {
              setcolumns2([]);
              setEarnType("Fixed");
            }}>Fixed</button>&nbsp;&nbsp;&nbsp;
            <button class="fixedOrFlex" onClick={() => {
              setcolumns2([]);
              setEarnType("Flexible");
            }}>Flexible</button>
          </div>
        </div>
        <div className="container pb-5">
          <div className="d-flex flex-lg-row flex-column align-items-lg-end gap-2 mb-4">
            <span className="simpleearn-text-7">Protected Products</span>
            <span className="simpleearn-text-8">
              Get stable earnings by depositing assets
            </span>
          </div>
          <div className="simpleearn-staking-subscribe-table">
            <DataTable
              columns={columns}
              data={columns2}
              defaultSortFieldId={1}
              sortIcon={<BiCaretDown />}
              onSort={handleSort}
              pagination
            />
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" >
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Subscribe{" "}
                  <img
                    className="simpleearn-subscribe-img"
                    src={currencymodal.currencyimage}
                  />
                  <span className="ms-2 fs-14">
                    {currencymodal.currencysymbol}
                  </span>
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                ></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    {earnType === "Fixed" && (<>
                      <p className="mb-1 simpleearn-text-11">Duration (Days)</p>
                      <ul
                        class="simpleearn-table-tabs-duration nav nav-pills gap-2 fs-12"
                        id="pills-tab"
                        role="tablist"
                      >
                        {
                          rewardsapr.map((aprs, index) => (
                            <li key={aprs._id} class="nav-item" role="presentation">
                              <button
                                className={
                                  index === CurrentAPR_id ? "nav-link active" : "nav-link"
                                }
                                id="pills-duration2-2-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-duration2-2"
                                type="button"
                                role="tab"
                                aria-controls="pills-duration2-3"
                                aria-selected="false"
                                onClick={() =>
                                  aprselectchangemodal(aprs.apr, aprs.days)
                                }
                              >
                                {aprs.days}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </>)}
                    <p className="mb-1 simpleearn-text-11 mt-3">
                      Subscription Amount
                    </p>
                    <div class="simpleearn-subscription-amount input-group mb-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Amount"
                        aria-label="Enter Amount"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setinputamount(e)}
                        value={stakeearnamt}
                      />
                      <span
                        class="input-group-text subscription-amount-group-1"
                        id="basic-addon2"
                      >
                        <button
                          className="simpleearn-max-button"
                          onClick={() => userbalancemax()}
                        >
                          Max
                        </button>
                      </span>
                      <span
                        class="input-group-text subscription-amount-group-2"
                        id="basic-addon2"
                      >
                        <img
                          className="simpleearn-subscribe-img"
                          src={currencymodal.currencyimage}
                        />
                        <span className="ms-1 fs-14">
                          {currencymodal.currencysymbol}
                        </span>
                      </span>
                    </div>
                    <div className="d-flex flex-row">
                      <div>
                        <p className="fs-10 mb-0">
                          {userbalance} {currencymodal.currencysymbol}{" "}
                          <span className="text-muted">Available</span>
                        </p>
                      </div>
                      <div className="ms-auto">
                        <p className="fs-10 mb-0">
                          <a
                            className="simpleearn-text-12"
                            onClick={() =>
                              BuyNowClick(currencymodal.currencysymbol)
                            }
                          >
                            Buy Now
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-1 simpleearn-enough-crypto text-dark">
                      <div>
                        <AiFillInfoCircle className="simpleearn-text-13" />
                      </div>
                      <div>
                        <span className="fs-12">Don’t have enough crypto?</span>
                      </div>
                      <div className="ms-auto fs-12">
                        <a
                          className="simpleearn-text-14"
                          onClick={() =>
                            BuyNowClick(currencymodal.currencysymbol)
                          }
                        >
                          {" "}
                          convert <BsArrowRight />
                        </a>
                      </div>
                    </div>
                    <p className="mb-0 mt-3 fs-12">Amount Limits</p>
                    <p className="mb-0 simpleearn-text-15">
                      Minimum:{" "}
                      <span className="text-muted">
                        {currencymodal.minAmount} {currencymodal.currencysymbol}
                      </span>
                    </p>
                    <p className="mb-0 simpleearn-text-15">
                      Available Quota:{" "}
                      <span className="text-muted">
                        {currencymodal.maxAmount} {currencymodal.currencysymbol}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <div class="container-timeline">

                      <div class="timeline-block timeline-block-right">
                        <div class="marker active">
                          <i class="fa fa-check active" aria-hidden="true"></i>
                        </div>
                        <div class="timeline-content d-flex align-items-center">
                          <h3 className="simpleearn-text-17">
                            Subscription Date
                          </h3>
                          <span className="ms-auto simpleearn-text-16">
                            {`${currentDate.toLocaleDateString('en-US', options)}  ${currentDate.toLocaleTimeString()}`}
                          </span>
                        </div>
                      </div>

                      {earnType === "Fixed" && (<>
                        <div class="timeline-block timeline-block-right">
                          <div class="marker active">
                            <i class="fa fa-check active" aria-hidden="true"></i>
                          </div>
                          <div class="timeline-content d-flex align-items-center">
                            <h3 className="simpleearn-text-17">
                              Interest End Date
                            </h3>
                            <span className="ms-auto simpleearn-text-16">
                              {`${new Date(Subscriptionenddate).toLocaleDateString('en-US', options)}  ${new Date(Subscriptionenddate).toLocaleTimeString().toString("h:mm:ss a")}`}
                            </span>
                          </div>
                        </div>

                        <div class="timeline-block timeline-block-left">
                          <div class="marker active">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </div>
                          <div class="timeline-content d-flex align-items-center">
                            <h3 className="simpleearn-text-17">
                              Next Subscription Date
                            </h3>
                            <span className="ms-auto simpleearn-text-16">
                              {`${new Date(Subscriptionenddate).toLocaleDateString('en-US', options)}  ${new Date(Subscriptionenddate).toLocaleTimeString()}`}
                            </span>
                          </div>
                        </div>
                      </>)}
                    </div>

                    <div className="d-flex flex-row mt-3">
                      <div>
                        <p className="mb-0 simpleearn-text-15">Est. APR </p>
                      </div>
                      <div className="ms-auto">
                        {earnType === "Fixed" ? (
                          <span className="simpleearn-text-15 text-muted">
                            {CurrentAPR_id == 0 && currentAPR == 0 ? (
                              <span className="">{daysapr}%</span>) : (
                              <span className=""></span>
                            )
                            }

                            {CurrentAPR_id > 0 && CurrentAPRdays == 1 ? (
                              <span className="">{currentAPR}%</span>) : (
                              <span className=""></span>
                            )
                            }
                            {daysapr != '' && currentAPR != 0 && CurrentAPRdays == 0 ? (
                              <span className="">{daysapr}%</span>) : (
                              <span className=""></span>
                            )
                            }
                          </span>
                        ) : (
                          <>
                            {aprPercentage}
                          </>
                        )}

                      </div>
                    </div>

                    <div className="d-flex flex-row">
                      <div>
                        <p className="mb-0 simpleearn-text-15">Est. Interest</p>
                      </div>
                      <div className="ms-auto">
                        <span className="simpleearn-text-15 text-muted">
                          {estinterest}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                      <div>
                        <AiFillInfoCircle />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          The APR is subject to change on a daily basis. Please
                          refer to the details on the page.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe py-1">
                      <div>
                        <AiFillInfoCircle />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          APR does not mean the actual or predicted returns in
                          fiat currency.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe py-1">
                      <div>
                        <HiSpeakerphone />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          Upon expiry, your digital assets will be automatically
                          subscribed to Flexible Products. If you choose to
                          redeem early, your digital assets will be returned to
                          the spot wallet.
                        </p>
                      </div>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={() => clickinputbox()}
                      />
                      <label
                        class="form-check-label fs-12"
                        for="flexCheckDefault"
                      >
                        I have read and agreed to{" "}
                        <span style={{ color: "#00dbae" }}>
                          getX Simple Earn Service Agreement
                        </span>
                      </label>
                    </div>

                    <div class="d-grid mt-3">
                      <button
                        class="simpleearn-subscribe-button"
                        type="button"
                        onClick={() => confirmstake()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
import {
    BrowserRouter as Router,
    useLocation,
    useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { MdOutlineContentCopy, MdUpcoming } from 'react-icons/md';
import $ from "jquery";
import NavbarOne from "../../siteTheme/NavbarOne";
import Footer from "../../siteTheme/Footer";
import moment from "moment";
import Config from '../../../core/config/index';
import "../assets/styles/ido-style.css"
import { Modal } from "react-bootstrap";
import { useContextData } from "../../../core/context";
import { toast } from "../../../core/lib/toastAlert";
import { makeRequest } from "../../../core/services/v1/request";
import axios from "axios";
import launchpadHelpers from "../utils/launchpad.helpers";
import { FaAngleRight } from "react-icons/fa6";
import { FaStopCircle, FaUsers } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { FaCheckCircle } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosTimer } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";

function Home(props) {
    const { state } = useLocation();
    const { siteSettings, myProfile, setUserProfile } = useContextData();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [cardStatus, setCardStatus] = useState('');
    const [copy, setCopy] = useState("Copy");
    const [blockNetwork, setBlockNetwork] = useState([]);
    const [showBuy, setShowBuy] = useState(false);
    const [btnLoad, setBtnLoad] = useState(false);
    const [usdt, setUsdt] = useState([]);
    const [tokenCount, setTokenCount] = useState(0);
    const [history, setHistory] = useState([]);
    const [buyData, setBuyData] = useState({
        buyAmount: "",
        numberOfToken: "",
        perCoinPrice: 0,
        totalUSD: ""
    });
    const [balanceErr, setBalanceErr] = useState('');
    const [butttonDisable, setButtonDisable] = useState(true);
    const [image, setImage] = useState();
    const [usdtValue, setUsdtValue] = useState('');
    const [percent, setPercent] = useState(0);
    const [sum, setSum] = useState(0);
    const [totalToken, setTotalToken] = useState(0);
    const [currentTime, setCurrentTime] = useState(moment.utc());
    useEffect(() => {
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (window.location.pathname === "/active-ido") {
            $("#classy-navbar-mobile").css("background-color", "transparent");
            $(".theme-mode-dropdown").hide();
        }
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        $("#ido-buy-enable-disable").addClass("ido-enable-disable");
    }, []);
    useEffect(() => {
        if (myProfile && myProfile._id) {
            History();
        }
    }, [myProfile])
    const projectData = async () => {
        if (data) axios({
            method: "GET",
            url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/singleProject/${state.userDetails}`
        }).then(async (res) => {
            setData(res.data.data.msg)
            const params1 = {
                method: "POST",
                url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/getPercent`,
                body: { ids: res.data.data.msg._id }
            };
            const response1 = await makeRequest(params1);
            let sumAdd = 0;
            let tokenSum = 0;
            if (response1.data.length > 0) {
                for (let i = 0; i < response1.data.length; i++) {
                    tokenSum += Number(response1.data[i].numberOfToken);
                    sumAdd += Number(response1.data[i].amountDeducted);
                }
            }
            setTotalToken(tokenSum);
            let usdtValues = res.data.data.msg.initial_supply * res.data.data.msg.token_price;
            setUsdtValue(usdtValues);
            let percents = sumAdd / usdtValues * 100;
            setSum(sumAdd);
            setPercent(percents);
            let v = res.data.data.msg.blockChainSelect[0];
            res.data.data.msg.image == '' ? setImage("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9qCKz9O8kcSbhK2f2kghHy5zEuWAyDVjUWQ&usqp=CAU") : setImage(res.data.data.msg.image)
            let vr = Object.keys(v).filter((key) => v[key] === true);
            setBlockNetwork(vr);
        }).catch((err) => console.log(err))

    }
    useEffect(() => {
        if (Config.LAUNCHPAD_STATUS == "Enable") {
            getUsdtBalance();
            projectData();
        }
    }, [state]);
    const projectTimeInfo = async () => {
        let showDate;
        let new_date = moment.utc()
        let fromDate = moment.utc(data.token_listing_from);
        let toDate = moment.utc(data.token_listing_to);
        if (moment(fromDate).diff(new_date, "days") <= 0) {
            if (moment(fromDate).diff(new_date, "hours") <= 0) {
                if (moment(fromDate).diff(new_date, "minutes") <= 0) {
                    if (moment(fromDate).diff(new_date, "seconds") <= 0) {
                        if (moment(toDate).diff(new_date, "days") <= 0) {
                            if (moment(toDate).diff(new_date, "hours") <= 0) {
                                if (moment(toDate).diff(new_date, "minutes") <= 0) {
                                    if (moment(toDate).diff(new_date, "seconds") <= 0) {
                                        showDate = `END`;
                                    } else {
                                        showDate = `${moment(toDate).diff(new_date, "seconds")} SECONDS LEFT`;
                                    }
                                } else {
                                    showDate = `${moment(toDate).diff(new_date, "minutes")} MINUTES LEFT`;
                                }
                            } else {
                                showDate = `${moment(toDate).diff(new_date, "hours")} HOURS LEFT`;
                            }
                        } else {
                            showDate = `${moment(toDate).diff(new_date, "days")} DAYS LEFT`;
                        }
                    } else {
                        showDate = `UPCOMING`
                    }
                } else {
                    showDate = `UPCOMING`
                }
            } else {
                showDate = `UPCOMING`
            }
        } else {
            showDate = `UPCOMING`
        }
        if (showDate == "END") {
            state.status = "past";
        } else if (showDate == "UPCOMING") {
            state.status = "future";
        } else {
            state.status = "present";
        }
        switch (state.status) {
            case 'past':
                return setCardStatus('END')
            case 'present':
                return setCardStatus('ACTIVE')
            case 'future':
                return setCardStatus('UPCOMING')
            default:
                break;
        }
    }
    useEffect(() => {
        projectTimeInfo()
    }, [data, currentTime])
    const History = async () => {
        let Count = [];
        const params = {
            method: "POST",
            url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/history`,
            data: { userId: myProfile._id, padId: state.userDetails }
        }
        const response = await makeRequest(params);
        setHistory(response.data);
        let totalCount = response.data;
        for (let i = 0; i < totalCount.length; i++) {
            let cnt = totalCount[i].numberOfToken;
            Count.push(Number(cnt));
        }
        let total = sumArray(Count);
        setTokenCount(total);
    }
    function sumArray(array) {
        let sum = 0;

        /*loop over array and add each item to sum
         */
        array.forEach(item => {
            sum += item;
        });
        // return the result
        return sum;
    }
    const getUsdtBalance = async () => {
        let userBalance = await launchpadHelpers.getUsdtBalance();
        setUsdt(userBalance);
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [state.status]);

    const handleCopyClipboard = (text) => {
        let cpy = ""
        const ta = document.createElement("textarea");
        ta.innerText = text;
        document.body.appendChild(ta);
        ta.select();
        cpy = document.execCommand("copy");
        setCopy(cpy ? "Copied" : "Copy")
        toast({ type: 'success', message: 'Token Address Copied' })
        ta.remove();
    };
    const handleBuyChange = (event) => {
        let amount = event.target.value;
        if (amount == '') {
            setButtonDisable(true);
        } else {
            setButtonDisable(false);
        }
        setBuyData({ buyAmount: event.target.value })
        // setBuyData((prevProp) => ({ ...prevProp, ["buyAmount"]: event.target.value }));
        if (event.target.name == "buyAmount") {
            let val = event.target.value;
            if (val <= data.available_token) {
                let coinPrice = val * data.token_price;
                setBuyData({
                    numberOfToken: val,
                    // perCoinPrice: data.token_price,
                    totalUSD: coinPrice,
                });
                if (coinPrice < usdt.amount) {
                    setBalanceErr('');
                    return
                } else {
                    if (amount == '') {
                        setBalanceErr('');
                    } else {
                        setBalanceErr("insufficient Balance");
                    }
                }
            } else {
                setBalanceErr("insufficient Token");
            }
        }
    };

    const handleBuyClick = async (event) => {
        event.preventDefault();
        setBtnLoad(true);
        try {
            var userId = "";
            if (myProfile && myProfile._id) {
                userId = myProfile._id;
            }
            const payload = {
                userId: userId,
                launchPadId: data._id,
                numberOfToken: buyData.numberOfToken,
                amountDeducted: buyData.totalUSD,
                purchaseDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            };
            const params = {
                method: "POST",
                url: `${Config.LAUNCHPAD_V1_API_URL}launchPad/ido-form/tokenBuy`,
                data: payload
            }
            const payload1 = {
                currencyId: usdt.currencyId,
                userId: myProfile._id,
                amount: buyData.totalUSD,
                token: buyData.numberOfToken,
                launchPadId: state.userDetails,
                tokenSymbol: data.token_symbol
            }
            const params1 = {
                method: "POST",
                url: `${Config.LAUNCHPAD_V2_API_URL}launchPad/currency/deductAmount`,
                data: payload1
            }
            const response = await makeRequest(params);
            const response1 = await makeRequest(params1);
            if (response.status == 'error') {
                toast({ type: response.status, message: response.message })
                getUsdtBalance()
                History()
                projectData()
                setButtonDisable(true);
                setBtnLoad(false);
            }
            else {
                toast({ type: "success", message: "Your token purchased successfully!" });
                response.code == 200 && setShowBuy(!showBuy); setBuyData({ totalUSD: "", numberOfToken: "" });
                getUsdtBalance()
                History()
                projectData()
                setButtonDisable(true);
                setBtnLoad(false);
                window.location.reload()
            }
        } catch (error) {
            console.log(error);
            toast({ type: "error", message: "Something went wrong" });
        }
    };
    //** invalid character blocked this function */
    const blockInvalidChar = (event) => {
        return ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault();
    };
    const restriction = () => {
        if (cardStatus == "ACTIVE") {
            setShowBuy(true)
        } else if (cardStatus == "UPCOMING") {
            toast({ type: "error", message: `${data && data.projectName} token launch at ${moment(data.token_listing_from).format("DD/MM/YYYY hh:mm a")}` });
        } else {
            toast({ type: "error", message: `${data && data.projectName} token finished` });
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, [])
    let toDate = moment(data.token_listing_to);
    let fromDate = moment(data.token_listing_from);
    let timeToBetween = moment.duration(toDate.diff(currentTime));
    let timeFromBetween = moment.duration(fromDate.diff(currentTime));
    return (
        <div className="top-alt-new-ido-section">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />

            <div className="new-ido-details-casdd-1">
                <div className="container">
                    <div className="d-flex flex-row align-items-center">
                        <div>
                            <a href="/launch-pad" className="ido-active-text-8">Home</a>
                            <span className="ido-active-text-8"><FaAngleRight /></span>
                            <a className="ido-active-text-8">Subscription Details</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-ido-details-casdd-2 mb-5">

                <div className="container">
                    <div className="row g-4">
                        <div className="ms-auto opacity-75 d-flex gap-2">
                            <span className="ido-active-text-5">Subscription Details</span>
                        </div>
                        <div className="col-lg-8">
                            <div className="new-ido-details-casdd-left-1 mt-1 d-flex flex-lg-row flex-column gap-3">
                                <img className="new-ido-details-casdd-logo-1" src={data.image} alt="ido-logo" />
                                <div className="">
                                    <p className="new-casdd-text-2">
                                        {data.projectInfo}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-4 new-ido-details-casdd-left-1">
                                <p className="ido-active-text-3 mb-0">Subscription Details</p>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <p className="ido-active-text-4">Registration Start</p>
                                        <p className="ido-active-text-4">Registration End</p>
                                        <p className="ido-active-text-4">Swap Rate</p>
                                        <p className="ido-active-text-4">Hard Cap</p>
                                        <p className="ido-active-text-4">Total Users Participated</p>
                                        <p className="ido-active-text-4">Total Funds Swapped</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="ido-active-text-4">: {moment(data && data.token_listing_from).utc().format("MM-DD-YYYY hh:mm A")} UTC</p>
                                        <p className="ido-active-text-4">: {moment(data && data.token_listing_to).utc().format("MM-DD-YYYY hh:mm A")} UTC</p>
                                        <p className="ido-active-text-4">: {`1 ${data && data.token_symbol} = ${data.token_price} USDT`}</p>
                                        <p className="ido-active-text-4">: {data && Number(data.hard_cap_value).toFixed(2)} USDT</p>
                                        <p className="ido-active-text-4">: {`${data && data.total_user_participated == null ? 0 : data.total_user_participated}`}</p>
                                        <p className="ido-active-text-4">: {`${data && data.total_funds_swapped == '' ? 0 : Number(data.total_funds_swapped).toFixed(2)} USDT`}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 new-ido-details-casdd-left-1">
                                <p className="ido-active-text-3 mb-0">Token information</p>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <p className="ido-active-text-4">Token Name</p>
                                        <p className="ido-active-text-4">Token Symbol</p>
                                        <p className="ido-active-text-4">Network</p>
                                        <p className="ido-active-text-4">Token Listing From</p>
                                        <p className="ido-active-text-4">Token Listing To</p>
                                        <p className="ido-active-text-4">Contract Address (don't send money)</p>
                                        <p className="ido-active-text-4">Total Supply</p>
                                        <p className="ido-active-text-4">Initial Supply</p>
                                        <p className="ido-active-text-4">Available Token</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="ido-active-text-4">: {data && data.token_name}</p>
                                        <p className="ido-active-text-4">: {data && data.token_symbol}</p>
                                        <p className="ido-active-text-4">: {data && data.blockChainSelect == "Eth" ? "ETH" : data.blockChainSelect}</p>
                                        <p className="ido-active-text-4">: {`${moment(data && data.token_listing_from).utc().format('Do MMMM YYYY hh:mm A')}`} UTC</p>
                                        <p className="ido-active-text-4">: {`${moment(data && data.token_listing_to).utc().format('Do MMMM YYYY hh:mm A')}`} UTC</p>
                                        <p className="ido-active-text-4 alt-new-contact-add">: {data && data.contact_address != null ? data.contact_address.slice(0, 10) : ''}
                                            <MdOutlineContentCopy style={{ cursor: 'pointer' }} onClick={() => handleCopyClipboard(data && data.contact_address)} className="me-2"
                                                values={data && data.contact_address}
                                                onCopy={handleCopyClipboard}
                                            />
                                        </p>
                                        <p className="ido-active-text-4">: {`${data && data.token_supply}`}</p>
                                        <p className="ido-active-text-4">: {data && data.initial_supply}</p>
                                        <p className="ido-active-text-4">: {data && data.available_token < 0 ? 0 : data && data.available_token}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 new-ido-details-casdd-left-1">
                                <p className="ido-active-text-3 mb-0">Distribution</p>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <p className="ido-active-text-4">Distribution</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="ido-active-text-4">: Getx Launchpad</p>
                                    </div>
                                </div>
                                <small><b>*Note: The token will be distributed by admin after once the project is completed</b></small>
                            </div>

                            <div className="mt-4 new-ido-details-casdd-left-1">
                                <p className="ido-active-text-3 mb-0">Terms And Conditions</p>
                                <p className="ido-active-text-4" dangerouslySetInnerHTML={{ __html: data.termsConditions }}></p>
                                {/* <div className="row mt-4">
                                    <div className="col-6">
                                        <p className="ido-active-text-4">Distribution</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="ido-active-text-4">: Getx Launchpad</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="alt-ido-text-1 mb-0">{data.projectName}</p>
                            <small className="opacity-75">{data.token_symbol} / USDT</small>
                            {
                                cardStatus == "ACTIVE" ?
                                    <div className='active-status-bar-new-launch mt-2' style={{ width: "fit-content" }}>
                                        <span><FaCheckCircle className='me-1 fs-20' />Active</span>
                                    </div>
                                    :
                                    cardStatus == "UPCOMING" ?
                                        <div className='active-status-bar-new-launch-upcoming mt-2' style={{ width: "fit-content" }}>
                                            <span><MdUpcoming className='me-1 fs-20' />Upcoming</span>
                                        </div>
                                        :
                                        cardStatus == "END" ?
                                            <div className='active-status-bar-new-launch-completed mt-2' style={{ width: "fit-content" }}>
                                                <span><FaStopCircle className='me-1 fs-20' />Completed</span>
                                            </div>
                                            :
                                            <div className='active-status-bar-new-launch-loading mt-2' style={{ width: "fit-content" }}>
                                                <span><div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div> {""}Indetifying ...</span>
                                            </div>
                            }
                            <div className="new-ido-details-casdd-left-1 mt-3">
                                {/* <p className="mb-1" style={{ letterSpacing: "0px" }}>1 BUSD = 33.2999 RETH</p> */}
                                <p className="mb-1" style={{ letterSpacing: "0px" }}>1 {data.token_symbol} = {data.token_price} USDT</p>
                                <div className='row mt-3'>
                                    <div className='col'>
                                        <div className="d-flex flex-row mb-2">
                                            <div>
                                                {
                                                    cardStatus == "ACTIVE" ?
                                                        <small className="opacity-75">registration closes in {timeToBetween.days()} days, {timeToBetween.hours()} hours, {timeToBetween.minutes()} minutes {timeToBetween.seconds()} seconds</small>
                                                        :
                                                        cardStatus == "UPCOMING" ?
                                                            <small className="opacity-75">registration opens in {timeFromBetween.days()} days, {timeFromBetween.hours()} hours, {timeFromBetween.minutes()} minutes {timeFromBetween.seconds()} seconds</small>
                                                            :
                                                            cardStatus == "END" ?
                                                                <small className="opacity-75">registration closed</small>
                                                                :
                                                                <small className="opacity-75">registration calculating....</small>
                                                }

                                            </div>
                                            <div className="ms-auto">
                                                <small>{percent.toFixed(2)}%</small>
                                            </div>
                                        </div>
                                        <div class="progress" style={{ height: "10px" }}>
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div className="d-flex flex-row mt-3 mb-5">
                                            <div>
                                                <small>{sum.toFixed(2)} USDT</small>
                                            </div>
                                            <div className="ms-auto">
                                                <small>{totalToken} / {data.initial_supply} {data.token_symbol}</small>
                                            </div>
                                        </div>
                                        <p className="new-casdd-text-1 d-flex align-items-center"><FaCalendarAlt className="me-1" />Start date - <span className="ps-1" style={{ fontSize: "14px" }}>{moment(data && data.token_listing_from).utc().format("MM-DD-YYYY hh:mm A")} UTC</span></p>
                                        <div className="new-ido-details-divider"></div>
                                        <p className="new-casdd-text-1 d-flex align-items-center"><IoIosTimer className="me-1" />End date - <span className="ps-1" style={{ fontSize: "14px" }}>{moment(data && data.token_listing_to).utc().format("MM-DD-YYYY hh:mm A")} UTC</span></p>
                                        <div className="new-ido-details-divider"></div>
                                        <p className="new-casdd-text-1 d-flex align-items-center"><FaUsers className="me-1" />Project Team - <span className="ps-1" style={{ fontSize: "14px" }}>{data && data.userTeam}</span></p>
                                        <div class="d-grid mt-4">
                                            {
                                                cardStatus == "ACTIVE" ?
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary new-casdd-btn-left-1"
                                                        onClick={() => restriction(true)}
                                                    >BUY NOW</button>
                                                    :
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary new-casdd-btn-left-1 mb-2"
                                                        onClick={() => restriction(true)}
                                                        disabled
                                                    >BUY NOW</button>
                                            }
                                            {/* <button class="btn btn-primary new-casdd-btn-left-1" type="button">Buy Now</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="new-ido-details-casdd-left-1 mt-3">
                                <h5 className="new-casdd-text-3 mt-3">Social Media</h5>
                                <div className="d-flex flex-row gap-2 new-ido-social-casdd-1">
                                    <a target="_blank" href={data.twitterLink}><FaXTwitter /></a>
                                    <a target="_blank" href={data.telegramGrpLink}><FaTelegram /></a>
                                </div>
                                <div className="new-ido-details-divider mt-4"></div>
                                <h5 className="new-casdd-text-3 mt-3">Website Link</h5>
                                <div className="d-flex flex-row gap-2 new-ido-social-casdd-2">
                                    <a target="_blank" href={data.websiteLink}>{data.websiteLink?.slice(0, 35)} <TbWorld className="fa-n-icon" /></a>
                                </div>
                                <div className="new-ido-details-divider mt-4"></div>
                                <h5 className="new-casdd-text-3 mt-3">Whitepaper Link</h5>
                                <div className="d-flex flex-row gap-2 new-ido-social-casdd-2 mb-3">
                                    <a target="_blank" href={data.paper_link}>{data.paper_link?.slice(0, 35)} <TbWorld className="fa-n-icon" /></a>
                                </div>
                            </div>
                            <div className="new-ido-details-casdd-left-1 mt-3" style={{ 'overflowY': 'scroll', "minHeight": "100px", "maxHeight": "400px", 'overflowX': 'hidden' }}>
                                <h5 className="ido-active-text-3 mt-3">Transactions</h5>
                                <div className="row mt-4">
                                    {
                                        history.length != 0 ?
                                            history.map((data) => {
                                                return (
                                                    <>
                                                        <div className="col-md-6 col-6">
                                                            <p className="ido-active-text-4">Tokens Bought</p>
                                                            <p className="ido-active-text-4">Amount (USDT)</p>
                                                            <p className="ido-active-text-4">Date</p>
                                                        </div>
                                                        <div className="col-md-6 col-6">
                                                            <p className="ido-active-text-4">: {data.numberOfToken}</p>
                                                            <p className="ido-active-text-4">: {Number(data.amountDeducted).toFixed(2)}</p>
                                                            <p className="ido-active-text-4">: {moment(data.purchaseDate).format("DD/MM/YYYY")}</p>
                                                        </div>
                                                        <hr></hr>
                                                    </>
                                                )
                                            })
                                            :
                                            <p className="text-center">No Data Available</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal
                className="ido-active-buy-section-modal"
                size="md"
                centered
                aria-labelledby="example-modal-sizes-title-lg"
                show={showBuy}
                onHide={() => { setShowBuy(!showBuy); setBuyData({ buyAmount: "" }); }}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="alt-buy-now-btn">BUY NOW</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group input-group-sm">
                        <div className="row mx-auto">
                            <div className='mt-3'>
                                <p className="">USDT Balance: {usdt && usdt.amount > 0 ? parseFloat(usdt.amount).toFixed(5) : 0}</p>
                                <p className="">Number of Token ({data.token_symbol})</p>
                                <div className='d-flex justify-content-between  p-2'>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control form-control-bg-css p-0 border-0 outline: none alt-sec-input-05"
                                        placeholder=""
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        name='buyAmount'
                                        value={buyData.buyAmount}
                                        onKeyDown={blockInvalidChar}
                                        onChange={handleBuyChange}
                                    />
                                    {/* <div className='loan-line-bar'>|</div> */}
                                </div>
                                <small className="text-danger">{balanceErr.length != 0 ? balanceErr : ''}</small>
                                <div className="error">
                                    {/* {repay_data_err.repay_amount_err} */}
                                </div>
                            </div>
                            {/* <div className="row mx-auto"> */}
                            <div className="rate-list mt-4">
                                <div className='d-flex justify-content-between flex-column flex-lg-row align-items-center mb-1'>
                                    <span className=''>
                                        Per Token Price
                                    </span>
                                    <span className=''>
                                        {`$ ${(data && data.token_price === undefined || data && data.token_price == 0 ? 0 : data.token_price)}`}
                                    </span>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between flex-column flex-lg-row align-items-center mb-1'>
                                <span className=''>
                                    Total USDT
                                </span>
                                <span className=''>
                                    {`$ ${parseFloat(buyData.totalUSD ? buyData.totalUSD : 0).toFixed(2)}`}
                                </span>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="row mt-3">
                        {
                            butttonDisable == true || balanceErr.length != 0 || usdt.amount < 0 ?
                                <div className="col text-center">
                                    <button
                                        className='btn get-start-1'
                                        disabled
                                    >
                                        BUY
                                    </button>
                                </div>
                                :
                                data.available_token == 0 ?
                                    <div className="col text-center">
                                        <button
                                            className='btn get-start-1'
                                            onClick={(e) => {
                                                setBtnLoad(true)
                                                handleBuyClick(e)
                                            }}
                                            disabled={btnLoad}
                                        >
                                            BUY
                                        </button>
                                    </div>
                                    :
                                    <div className="col text-center">
                                        <button
                                            className='btn get-start-1'
                                            onClick={(e) => {
                                                setBtnLoad(true)
                                                handleBuyClick(e)
                                            }}
                                            disabled={btnLoad}
                                        >
                                            BUY
                                            {
                                                btnLoad == true ?
                                                    <div class="spinner-border spinner-border-sm ms-2" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </button>
                                    </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Home;

import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';

function DepthChart(props) {
    const { bids, asks, binanceDepthOrders, isBinancePair } = props;
    const [bidsData, setBidsData] = useState([]);
    const [asksData, setAsksData] = useState([]);
    const [binanceBids, setBinanceBid] = useState([]);
    const [binanceAsks, setBinanceAsks] = useState([]);

    const date = new Date();
    useEffect(() => {
        if (Array.isArray(bids)) {
            const newData = bids.map(data => [data._id]).sort((a, b) => a[0] > b[0] ? -1 : 1);
            const newData2 = bids.map(data => [data.amount]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const combinedArray = newData.map((_, index) => {
                const firstArray = newData[index];
                const secondArray = newData2[index];
                return [...firstArray, ...secondArray]
            });
            setBidsData(combinedArray)
        }

        if (Array.isArray(asks)) {
            const newData = asks.map(data => [data._id]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const newData2 = asks.map(data => [data.amount]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const combinedArray = newData.map((_, index) => {
                const firstArray = newData[index];
                const secondArray = newData2[index];
                return [...firstArray, ...secondArray]
            });
            setAsksData(combinedArray)
        }
    }, [bids, asks]);

    useEffect(() => {
        const bids = binanceDepthOrders.bids;
        const asks = binanceDepthOrders.asks;
        if (Array.isArray(bids)) {
            const newData = bids.map(data => [data._id]).sort((a, b) => a[0] > b[0] ? -1 : 1);
            const newData2 = bids.map(data => [data.amount]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const combinedArray = newData.map((_, index) => {
                const firstArray = newData[index];
                const secondArray = newData2[index];
                return [...firstArray, ...secondArray]
            });
            setBinanceBid(combinedArray)
        }

        if (Array.isArray(asks)) {
            const newData = asks.map(data => [data._id]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const newData2 = asks.map(data => [data.amount]).sort((a, b) => a[0] > b[0] ? 1 : -1);
            const combinedArray = newData.map((_, index) => {
                const firstArray = newData[index];
                const secondArray = newData2[index];
                return [...firstArray, ...secondArray]
            });
            setBinanceAsks(combinedArray)
        }
    }, [binanceDepthOrders]);

    var chartData = {
        tickerId: props.symbol,
        timestamp: date.getTime(),
        bids: isBinancePair ? binanceBids : bidsData,
        asks: isBinancePair ? binanceAsks : asksData,
    };
    useEffect(() => {
        Highcharts.chart('container', {
            chart: {
                type: "area",
                zoomType: "xy",
                backgroundColor: "#1c1b2b",
                height: 500,
                animation: false,
            },
            title: {
                text: "Market Depth",
                style: {
                    color: "#fff",
                },
            },
            xAxis: {
                minPadding: 0,
                maxPadding: 0,
                plotLines: [
                    {
                        color: "#2f2952",
                        value: 0.1523,
                        width: 1,
                        label: {
                            text: "Actual price",
                            rotation: 90,
                            style: {
                                color: "#4F6C89",
                            },
                        },
                    },
                ],
                lineWidth: 0.1,
                tickColor: "#1c1b2b",
                crosshair: {
                    color: "#696777",
                    dashStyle: "dash",
                },
                title: {
                    text: "Price",
                    style: {
                        color: "#4F6C89",
                    },
                },
            },
            yAxis: [
                {
                    gridLineWidth: 1,
                    title: null,
                    tickWidth: 1,
                    tickLength: 5,
                    tickPosition: "inside",
                    labels: {
                        align: "left",
                        x: 8,
                    },
                    crosshair: {
                        dashStyle: "dash",
                        color: "#696777",
                    },
                    gridLineColor: "#201d3a",
                    lineWidth: 0,
                    tickColor: "#2f2952",
                },
                {
                    opposite: true,
                    linkedTo: 0,
                    gridLineWidth: 0,
                    title: null,
                    tickWidth: 1,
                    tickLength: 5,
                    tickPosition: "inside",
                    labels: {
                        align: "right",
                        x: -8,
                    },
                    crosshair: {
                        dashStyle: "dash",
                        color: "#696777",
                    },
                    gridLineColor: "#201d3a",
                    lineWidth: 0,
                    tickColor: "#2f2952",
                },
            ],
            legend: {
                enabled: false,
            },
            plotOptions: {
                area: {
                    fillOpacity: 0.2,
                    lineWidth: 1,
                    step: "center",
                    animation: false,
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    animation: false,
                },
            },
            tooltip: {
                headerFormat:
                    '<span style="font-size=10px;">Price: {point.key}</span><br/>',
                valueDecimals: 2,
            },
            series: [
                {
                    name: "Bids",
                    data: chartData.bids,
                    color: "#4EA64A",
                },
                {
                    name: "Asks",
                    data: chartData.asks,
                    color: "#FB1809",
                },
            ],
            credits: {
                enabled: false,
            },
        });
    }, [bidsData, asksData, binanceBids, binanceAsks]);
    // useEffect(() => {
    //     if (chart) {
    //         chart.series[0].update({ data: chartData.bids }, false, false);
    //         chart.series[1].update({ data: chartData.asks }, false, false);
    //         chart.redraw(false); // Disable the animation for the redraw
    //     }
    // }, [bidsData, asksData, chart]);
    return (
        <div id="container" style={{ height: '500px', minWidth: '310px' }}></div>
    );
}

export default DepthChart;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "../assets/style.css";
import { BsDot } from "react-icons/bs";
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { makeRequest } from "../core/services/v1/request";
import Config from "../core/config/";
import Accordion from 'react-bootstrap/Accordion';

export default function Withdraw(props) {

  const navigate = useNavigate();

  const [faqList, setFaqList] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    getFaq();
  }, []);

  async function getFaq() {
    try {
      const params = {
        url: `${Config.V1_API_URL}faq/getfaq`,
        method: 'POST',
        body: { type: "common" }
      }
      const { status, data, error } = (await makeRequest(params));
      if (status && data) {
        setFaqList(data)
      }
    } catch (err) { }
  }

  async function backButton() {
    try {
      const length = window.history.length;
      if (length > 1) {
        window.history.back();
      } else {
        navigate("/");
      }
    } catch (err) { }
  }

  const handleAccordionSelect = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner faq-active-nav-class">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <GoChevronLeft className="deposit-back-button-icon" onClick={() => backButton()} />
                <span className="deposit-text-1">FAQs on Crypto Trading in India</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-second-section py-5">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col">
              <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
                {faqList.length > 0 && faqList.map((row, i) => (
                  <Accordion.Item eventKey={i.toString()} key={i}>
                    <Accordion.Header>{row.title}</Accordion.Header>
                    <Accordion.Body>
                      {row.description}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
